import { IUserProfile } from 'api/generated/models';
import { hasValue, invalidSsn } from 'utilities/index';

export const processSsnChange = (
    newUserProfile: IUserProfile,
    existingUserProfile: IUserProfile
) => {
    const existingSsn = existingUserProfile.memberVerifiedInfo?.socialSecurityNumber;
    const newSsn = newUserProfile.memberVerifiedInfo?.socialSecurityNumber;
    if (
        invalidSsn(newSsn) &&
        !invalidSsn(existingSsn) &&
        existingUserProfile.user?.userId === newUserProfile?.user?.userId &&
        hasValue(newUserProfile?.memberVerifiedInfo)
    ) {
        newUserProfile.memberVerifiedInfo.socialSecurityNumber = existingSsn;
    }
    return newUserProfile;
};
