import { IAction } from 'actions/commonAction';
import { APPLY_STRATEGY_ACTION } from 'actions/pathwayBlueprint/applyStrategy';
import { IApplyIchraClassStrategyResponseDto } from 'api/generated/models';

const initialState: IApplyIchraClassStrategyResponseDto = ({} as unknown) as IApplyIchraClassStrategyResponseDto;

export const appliedStrategy = (
    state = initialState,
    action: IAction<IApplyIchraClassStrategyResponseDto>
): IApplyIchraClassStrategyResponseDto => {
    if (action.type === APPLY_STRATEGY_ACTION.success) {
        return action.data;
    } else {
        return state;
    }
};
