export const userInfoStatusNames = ['Basic', 'MemberQuoteInfo', 'MemberVerifiedInfo'];
export const QUOTE_INFO_STATUS = 'Quote';
export const VERIFIED_INFO_STATUS = 'Verified';
export const userInfoStatusPrettyNames = ['Basic', QUOTE_INFO_STATUS, VERIFIED_INFO_STATUS];

export const userInfoStatus = {
    Basic: 0,
    MemberQuoteInfo: 1,
    MemberVerifiedInfo: 2,
    ...userInfoStatusNames,
};
