import { TeamBenefitTypes } from 'api/generated/enums';

export const ALLOWED_ANCILLARY_SELECTION_TYPE_VALUES = [
    TeamBenefitTypes.GroupVision,
    TeamBenefitTypes.GroupDental,
    TeamBenefitTypes.LifeInsurance,
    TeamBenefitTypes.LongTermDisability,
    TeamBenefitTypes.ShortTermDisability,
    TeamBenefitTypes.CriticalIllness,
    TeamBenefitTypes.DirectPrimaryCare,
];
