import { patchYearlyUserInfo } from 'actions/user/patchYearlyUserInfo';
import { EnrollmentStatuses } from 'api/generated/enums';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { useCallback } from 'react';

export const useSetUserAdvisementScheduled = () => {
    const dispatch = useThunkDispatch();
    const { isCurrent, userId, userProfile } = useUserProps();
    const year = userProfile.year;

    return useCallback(async () => {
        await dispatch(
            patchYearlyUserInfo(
                userId,
                year,
                {
                    enrollmentStatus: {
                        value: EnrollmentStatuses.AdvisementScheduled,
                    },
                },
                isCurrent
            )
        );
    }, [dispatch, userId, year, isCurrent]);
};
