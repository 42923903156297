import { IAction } from 'actions/commonAction';
import { GET_CALENDLY_LINK_ACTION } from 'actions/user/getCalendlyLink';

const initialState = '';

export const calendlyLink = (state = initialState, action: IAction<string>) => {
    if (action.type === GET_CALENDLY_LINK_ACTION.success) {
        return action.data;
    }
    return state;
};
