import {
    CreateSelectedPlans,
    DeleteSelectedPlans,
    ViewSelectedPlans,
} from 'api/generated/permissions';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import orderBy from 'lodash/orderBy';
import sumBy from 'lodash/sumBy';
import uniqBy from 'lodash/uniqBy';
import without from 'lodash/without';
import { AppStore } from 'reducers/appReducer';
import { createSelector } from 'reselect';
import { hasValue, roundTwoDecimals } from 'utilities';

const marketplacePlansSelector = (state: AppStore) => state.marketplacePlans;

const getActiveContribution = (state: AppStore, isCurrent: boolean) =>
    isCurrent ? state.current.userProfile?.activeWageUp : state.userProfile?.activeWageUp;

export const getTotalTaxRate = (state: AppStore, isCurrent: boolean) => {
    const activeWageUp = getActiveContribution(state, isCurrent);
    if (activeWageUp) {
        return activeWageUp?.totalTaxRate;
    }
    if (!isEmpty(state.userBudget)) {
        const { ficaTaxRate, stateTaxRate, federalTaxRate } = state.userBudget;
        return ficaTaxRate + stateTaxRate + (federalTaxRate ?? 0);
    }
    return 0;
};

/**
 * Will return active Net Wage+ for state.userProfile.memberVerifiedInfo.year or user budget amount if no wage up or 0 if no user budget
 * @param state
 * @returns netAmount
 */
export const getWageUp = (state: AppStore, isCurrent: boolean) =>
    getActiveContribution(state, isCurrent)?.wageUp ??
    state.userBudget?.supplementalWageIncrease ??
    0;

export const getReimbursement = (state: AppStore, isCurrent: boolean) =>
    getActiveContribution(state, isCurrent)?.reimbursement ?? 0;

export const getReimbursementTotalPlanCosts = createSelector(
    (state: AppStore) => state.selectedPlansForReview.selectedPlans,
    (selectedPlans) => sumBy(selectedPlans, (p) => roundTwoDecimals(p.planPremium))
);

export const getRemainingReimbursement = createSelector(
    getReimbursement,
    getReimbursementTotalPlanCosts,
    (reimbursement, totalPlanCosts) => Math.max(reimbursement - totalPlanCosts, 0)
);

export const getCanShopForMember = (state: AppStore) =>
    [CreateSelectedPlans, DeleteSelectedPlans, ViewSelectedPlans].every((p) =>
        state.login.up.includes(p)
    );

const levelOrder = ['bronze', 'silver', 'gold', 'platinum', 'catastrophic'];
const getLevelOrder = (metalLevel: string) =>
    levelOrder.findIndex((x) => metalLevel.toLowerCase().startsWith(x));

export const CSR_LABEL = ' + CSR';
export const uniqueMetalLevelsSelector = createSelector(
    marketplacePlansSelector,
    (marketplacePlans) => {
        const metalLevels: string[] = [];
        marketplacePlans.forEach((plan) => {
            let metalLevel = plan.metalLevel;
            if (plan.isCsrEligible) {
                metalLevel = `${plan.metalLevel}${CSR_LABEL}`;
            }
            if (hasValue(metalLevel) && !metalLevels.includes(metalLevel)) {
                metalLevels.push(metalLevel);
            }
        });
        metalLevels.sort((a, b) => getLevelOrder(a) - getLevelOrder(b));
        return metalLevels;
    }
);

export const uniquePlanTypesSelector = createSelector(
    marketplacePlansSelector,
    (marketplacePlans) =>
        without(
            orderBy(map(uniqBy(marketplacePlans, 'planType'), (plan) => plan.planType)),
            undefined
        )
);
