import { IAction } from 'actions/commonAction';
import { GET_TEAM_BENEFIT_TYPES_ACTION } from 'actions/teamBenefitType/getTeamBenefitTypes';
import { ITeamBenefitType } from 'api/generated/models';

const initialState: ITeamBenefitType[] = [];

export const teamBenefitTypes = (
    state = initialState,
    action: IAction<ITeamBenefitType[]>
): ITeamBenefitType[] => {
    switch (action.type) {
        case GET_TEAM_BENEFIT_TYPES_ACTION.success:
            return [...action.data];
        default:
            return state;
    }
};
