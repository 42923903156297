import {
    ITeamBenefit,
    ITeamBenefitTermDetail,
    ITeamBenefitTermDetailDto,
    ITeamBenefitWithTermDetailsDto,
} from 'api/generated/models';
import { useMemo } from 'react';
import { hasValue } from 'utilities';

export const useTermDetails = (
    teamBenefit: ITeamBenefit | ITeamBenefitWithTermDetailsDto | undefined
) =>
    useMemo(
        (): ITeamBenefitTermDetail[] | ITeamBenefitTermDetailDto[] =>
            hasValue(teamBenefit) && hasValue(teamBenefit.teamBenefitTermDetails)
                ? teamBenefit.teamBenefitTermDetails ??
                  (({} as unknown) as ITeamBenefitTermDetail[])
                : (({} as unknown) as ITeamBenefitTermDetail[]),
        [teamBenefit]
    );
