import { GET_ENROLLMENT_INFO_ACTION } from 'actions/ccmController/getEnrollmentInfo';
import { IAction } from 'actions/commonAction';
import { ICcmEnrollmentInfo } from 'api/generated/models';

const initialState: ICcmEnrollmentInfo[] = [];

export const enrollmentInfo = (
    state = initialState,
    action: IAction<ICcmEnrollmentInfo[]>
): ICcmEnrollmentInfo[] => {
    if (action.type === GET_ENROLLMENT_INFO_ACTION.success) {
        return action.data;
    }
    return state;
};
