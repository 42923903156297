// pages
import { ConnectedRouter, push } from 'connected-react-router';
import { History } from 'history';
import useThunkDispatch from 'hooks/useThunkDispatch';
import React, { useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import RouterContents from 'routers/RouterContents';
import { LOGIN_PATH, MFA_VERIFICATION_PATH, PAGE_DATA } from 'routers/routes';

export type IAppRouterProps = RouteComponentProps & {
    isTeamManagementPage?: boolean;
};

const anonymousRoutePaths = PAGE_DATA.reduce<string[]>((accumulatedAnonymousPaths, current) => {
    if (current.anonymousPaths) {
        accumulatedAnonymousPaths = accumulatedAnonymousPaths.concat(current.anonymousPaths);
    }
    return accumulatedAnonymousPaths;
}, []);

const AppRouter = ({ history }: { history: History }) => {
    const dispatch = useThunkDispatch();
    const { hasMfa, isAuthenticated } = useSelector((state: AppStore) => ({
        hasMfa: state.appRouterReducer.hasMfa,
        isAuthenticated: state.appRouterReducer.isAuthenticated,
    }));

    useEffect(() => {
        const { hash, pathname, search } = history.location;
        const path = pathname + search + hash;
        if (hasMfa) {
            if (!anonymousRoutePaths.includes(pathname)) {
                dispatch(push(MFA_VERIFICATION_PATH));
            } else {
                dispatch(push(`${MFA_VERIFICATION_PATH}?redirect=${encodeURIComponent(path)}`));
            }
        } else {
            if (!isAuthenticated && !anonymousRoutePaths.includes(pathname)) {
                if (path === '/') {
                    dispatch(push(LOGIN_PATH));
                } else {
                    dispatch(push(`${LOGIN_PATH}?redirect=${encodeURIComponent(path)}`));
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, hasMfa, isAuthenticated]);

    return (
        <ConnectedRouter history={history}>
            <RouterContents />
        </ConnectedRouter>
    );
};

export default hot(module)(AppRouter);
