import { CLEAR_COUNTIES } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_COUNTIES_ACTION } from 'actions/marketplacePlan/getCounties';
import { ICounty } from 'api/generated/models';

const initialState: ICounty[] = [];

export const counties = (state = initialState, action: IAction<ICounty[]>): ICounty[] => {
    switch (action.type) {
        case GET_COUNTIES_ACTION.success:
            return [...action.data];
        case CLEAR_COUNTIES:
        case GET_COUNTIES_ACTION.failed:
            return [];
        default:
            return state;
    }
};
