import {
    DOCUMENT_EXTENSIONS,
    IMAGE_EXTENSIONS,
    PRESENTATION_EXTENSIONS,
    SPREADSHEET_EXTENSIONS,
    VIDEO_EXTENSIONS,
} from 'constants/fileExtensions';
import isArray from 'lodash/isArray';

type IFileExtensionIconProperties = { color: string; icon: string };
const fileExtensionIconData: Record<string, IFileExtensionIconProperties> = {
    '.pdf': { color: 'red', icon: 'file-pdf' },
};
PRESENTATION_EXTENSIONS.forEach(
    (x) => (fileExtensionIconData[x] = { color: 'orange', icon: 'file-powerpoint' })
);
DOCUMENT_EXTENSIONS.forEach(
    (x) => (fileExtensionIconData[x] = { color: 'blue', icon: 'file-word' })
);
SPREADSHEET_EXTENSIONS.forEach(
    (x) => (fileExtensionIconData[x] = { color: 'green', icon: 'file-excel' })
);
IMAGE_EXTENSIONS.forEach(
    (x) => (fileExtensionIconData[x] = { color: 'purple', icon: 'file-image' })
);
VIDEO_EXTENSIONS.forEach(
    (x) => (fileExtensionIconData[x] = { color: 'secondary', icon: 'file-video' })
);

export const getFileIconDataForExtension = (fileExtension: string) =>
    fileExtensionIconData[fileExtension.toLowerCase()] ?? {
        color: 'secondary',
        icon: 'file',
    };

const removeFileExtension = (fileName: string) =>
    fileName
        .split('.')
        .slice(0, -1)
        .join('.');

export const convertFilesToFileAndName = (fileData: File | File[] | undefined) => {
    const files = isArray(fileData) ? fileData : [fileData];

    return Array.from(files)
        .filter((x) => x !== undefined)
        .map((file) => ({
            file: file as File,
            name: removeFileExtension(file?.name ?? 'Unknown'),
        }));
};
