import { IAction } from 'actions/commonAction';
import { CLEAR_SUBMITTED_EXPENSE_MODAL_UTILIZATIONS } from 'actions/submittedExpenses/clearSubmittedExpenseModalUtilizations';
import { GET_SUBMITTED_EXPENSE_MODAL_UTILIZATIONS } from 'actions/submittedExpenses/getSubmittedExpenseModalUtilizations';
import { IMemberReimbursementUtilizationDto } from 'api/generated/models';

const initialState: IMemberReimbursementUtilizationDto[] = [];

export const submittedExpenseModalUtilizations = (
    state = initialState,
    action: IAction<IMemberReimbursementUtilizationDto[]>
): IMemberReimbursementUtilizationDto[] => {
    if (action.type === GET_SUBMITTED_EXPENSE_MODAL_UTILIZATIONS.success) {
        return action.data;
    }
    if (action.type === CLEAR_SUBMITTED_EXPENSE_MODAL_UTILIZATIONS) {
        return initialState;
    }
    return state;
};
