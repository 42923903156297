import { IProvider } from 'api/generated/models';
import { getIdeonProviderName } from 'components/creatableAutocomplete/useIdeonProviderAutocomplete';
import { IIdeonProviderWithSource } from 'reducers/ideonProviders';
import { hasValue } from 'utilities/index';

export const convertProviderToIdeonProvider = (
    provider: Partial<IProvider>,
    index: number
): IIdeonProviderWithSource => ({
    ...provider,
    id: hasValue(provider.nationalProviderId) ? Number(provider.nationalProviderId) : index,
    npis: hasValue(provider.nationalProviderId) ? [Number(provider.nationalProviderId)] : [],
    phone: provider.phoneNumber,
    presentationName: provider.name,
    providerSource: provider.source,
    specialty: provider.taxonomy,
});

export const convertIdeonProviderToProvider = (
    provider: IIdeonProviderWithSource
): Partial<IProvider> => ({
    ...provider,
    name: getIdeonProviderName(provider),
    nationalProviderId: hasValue(provider.npis) ? provider?.npis[0]?.toString() : undefined,
    phoneNumber: provider.phone,
    source: provider.providerSource,
    taxonomy: provider.specialty,
});
