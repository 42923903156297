import { IAction } from 'actions/commonAction';
import { GET_TEAM_ROLES_ACTION } from 'actions/role/getTeamRoles';
import { IRole } from 'api/generated/models';

const initialState: IRole[] = [];

export const teamRoles = (state = initialState, action: IAction<IRole[]>): IRole[] => {
    switch (action.type) {
        case GET_TEAM_ROLES_ACTION.success:
            return [...action.data];
        default:
            return state;
    }
};
