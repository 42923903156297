import { CLEAR_HOUSEHOLD_MEMBERS } from 'actions/clear';
import { ADD_HOUSEHOLD_MEMBER_ACTION } from 'actions/householdMember/addHouseholdMember';
import { DELETE_HOUSEHOLD_MEMBER_ADDRESS_ACTION } from 'actions/householdMember/deleteHouseholdMemberAddress';
import { DELETE_HOUSEHOLD_MEMBER_YEAR_DATA_ACTION } from 'actions/householdMember/deleteHouseholdMemberYearData';
import {
    EDIT_HOUSEHOLD_MEMBER_HOME_ADDRESS_ACTION,
    EDIT_HOUSEHOLD_MEMBER_MAILING_ADDRESS_ACTION,
} from 'actions/householdMember/editHouseholdMemberAddress';
import { GET_HOUSEHOLD_MEMBERS_ACTION } from 'actions/householdMember/getHouseholdMembers';
import { PATCH_HOUSEHOLD_MEMBER_ACTION } from 'actions/householdMember/patchHouseholdMember';
import { APPLY_SHOPPING_COVERAGE_CHANGES } from 'actions/householdShoppingCoverage/applyShoppingCoverageChanges';
import { GET_HOUSEHOLD_ACTION } from 'actions/user/getHousehold';
import { AddressTypes } from 'api/generated/enums';
import {
    IAddress,
    IHousehold,
    IHouseholdMember,
    IHouseholdMemberDto,
    IHouseholdMemberPatchRecalculateDto,
} from 'api/generated/models';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import { getDisplayFirstName } from 'utilities/household';
import { hasValue } from 'utilities/index';

export type IHouseholdMemberWithAge = IHouseholdMemberDto & { age: number };
const initialState: IHouseholdMemberWithAge[] = [];

type IHouseholdMembersDataType =
    | IAddress
    | IHousehold
    | IHouseholdMember
    | IHouseholdMember[]
    | IHouseholdMemberPatchRecalculateDto;

export const householdMembers = (
    state = initialState,
    action: {
        addressType?: AddressTypes;
        data: IHouseholdMembersDataType;
        householdMemberId?: string;
        type: string;
    }
): IHouseholdMemberWithAge[] => {
    let members: IHouseholdMemberWithAge[] = [];
    switch (action.type) {
        case EDIT_HOUSEHOLD_MEMBER_HOME_ADDRESS_ACTION.success:
            members = state.map((householdMember) => {
                if (householdMember.householdMemberId !== action.householdMemberId) {
                    return householdMember;
                }
                return {
                    ...householdMember,
                    address: action.data,
                };
            }) as IHouseholdMemberWithAge[];
            break;
        case EDIT_HOUSEHOLD_MEMBER_MAILING_ADDRESS_ACTION.success:
            members = state.map((householdMember) => {
                if (householdMember.householdMemberId !== action.householdMemberId) {
                    return householdMember;
                }
                return {
                    ...householdMember,
                    mailingAddress: action.data,
                };
            }) as IHouseholdMemberWithAge[];
            break;
        case DELETE_HOUSEHOLD_MEMBER_ADDRESS_ACTION.success:
            members = state.map((householdMember) => {
                if (householdMember.householdMemberId !== action.householdMemberId) {
                    return householdMember;
                }
                if (action.addressType === AddressTypes.Mailing) {
                    return {
                        ...householdMember,
                        mailingAddress: null,
                    };
                }
                return {
                    ...householdMember,
                    address: null,
                    addressId: '',
                };
            }) as IHouseholdMemberWithAge[];
            break;
        case GET_HOUSEHOLD_MEMBERS_ACTION.success:
            members = (action.data as IHouseholdMemberWithAge[]).map(
                (member: IHouseholdMemberWithAge) => {
                    member.age = member.dateOfBirth.getAge();
                    return member;
                }
            );
            break;
        case GET_HOUSEHOLD_ACTION.success:
            members = ((action.data as IHousehold).householdMembers?.map(
                (member: IHouseholdMember) => {
                    (member as IHouseholdMemberWithAge).age = member.dateOfBirth.getAge();
                    return member;
                }
            ) ?? []) as IHouseholdMemberWithAge[];
            break;
        case DELETE_HOUSEHOLD_MEMBER_YEAR_DATA_ACTION.success:
            members = filter(
                state,
                (householdMember) => householdMember.householdMemberId != action.householdMemberId
            );
            break;
        case ADD_HOUSEHOLD_MEMBER_ACTION.success:
            members = [
                ...state,
                {
                    ...action.data,
                    age: (action.data as IHouseholdMember).dateOfBirth.getAge(),
                },
            ] as IHouseholdMemberWithAge[];
            break;
        case PATCH_HOUSEHOLD_MEMBER_ACTION.success:
            members = state.map((householdMember) => {
                if (
                    householdMember.householdMemberId !==
                    (action.data as IHouseholdMember).householdMemberId
                ) {
                    return householdMember;
                }
                return {
                    ...action.data,
                };
            }) as IHouseholdMemberWithAge[];
            break;
        case APPLY_SHOPPING_COVERAGE_CHANGES: {
            const { householdMembers: hhms } = action.data as {
                householdMembers: Record<string, IHouseholdMemberDto>;
            };
            members = state.map((householdMember) => {
                const newRecord = hhms[householdMember.householdMemberId];
                if (hasValue(newRecord)) {
                    return {
                        ...newRecord,
                        age: newRecord.dateOfBirth.getAge(),
                    };
                }
                return householdMember;
            });
            break;
        }
        case CLEAR_HOUSEHOLD_MEMBERS:
            return [];
        default:
            return state;
    }
    return orderBy(
        members,
        [
            (x: IHouseholdMember) => new Date(x.dateOfBirth),
            (x: IHouseholdMember) => getDisplayFirstName(x),
        ],
        ['asc', 'asc']
    ) as IHouseholdMemberWithAge[];
};
