import { IAction } from 'actions/commonAction';
import { ADD_USER_NOTE_ACTION } from 'actions/userNote/addUserNote';
import { DELETE_USER_NOTE_ACTION } from 'actions/userNote/deleteUserNote';
import { EDIT_USER_NOTE_ACTION } from 'actions/userNote/editUserNote';
import { GET_USER_NOTES_ACTION } from 'actions/userNote/getUserNotes';
import { TOGGLE_ARCHIVE_USER_NOTE_ACTION } from 'actions/userNote/toggleArchiveUserNote';
import { INote } from 'api/generated/models';

const initialState: INote[] = [];

export const userNotes = (state = initialState, action: IAction<INote | INote[]>): INote[] => {
    switch (action.type) {
        case DELETE_USER_NOTE_ACTION.success:
            return [...state.filter((x) => x.noteId !== (action.data as INote).noteId)];
        case TOGGLE_ARCHIVE_USER_NOTE_ACTION.success:
        case EDIT_USER_NOTE_ACTION.success: {
            const data = action.data as INote;
            const index = state.findIndex((x) => x.noteId === data.noteId);
            state[index] = data;
            return [...state];
        }
        case ADD_USER_NOTE_ACTION.success:
            return [action.data as INote, ...state];
        case GET_USER_NOTES_ACTION.success:
            return [...(action.data as INote[])];
        default:
            return state;
    }
};
