export const ahpOptions = [
    { id: 3000, name: 'Medi-Share $3,000 AHP' },
    { id: 6000, name: 'Medi-Share $6,000 AHP' },
    { id: 9000, name: 'Medi-Share $9,000 AHP' },
    { id: 12000, name: 'Medi-Share $12,000 AHP' },
];
export const legacyAhpOptions = [
    { id: 1750, name: 'Legacy - Medi-Share $1,750 AHP' },
    { id: 4250, name: 'Legacy - Medi-Share $4,250 AHP' },
    { id: 5500, name: 'Legacy - Medi-Share $5,500 AHP' },
    { id: 8000, name: 'Legacy - Medi-Share $8,000 AHP' },
    { id: 10500, name: 'Legacy - Medi-Share $10,500 AHP' },
];
