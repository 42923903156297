import { CLEAR_ENROLLMENT_DATA_ACTION } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_ENROLLMENT_DATA_ACTION } from 'actions/user/getEnrollmentData';
import { IDataForEnrollmentDto } from 'api/generated/models';

const initialState: IDataForEnrollmentDto = ({} as unknown) as IDataForEnrollmentDto;

export const dataForEnrollmentCompletion = (
    state = initialState,
    action: IAction<IDataForEnrollmentDto>
): IDataForEnrollmentDto => {
    if (action.type === GET_ENROLLMENT_DATA_ACTION.success) {
        return action.data;
    }
    if (action.type === CLEAR_ENROLLMENT_DATA_ACTION) {
        return initialState;
    }
    return state;
};
