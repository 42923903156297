import { IAction } from 'actions/commonAction';
import { ADD_TEAM_BENEFIT_RH_FILE_ACTION } from 'actions/rhFile/addTeamBenefitRhFile';
import { DELETE_TEAM_BENEFIT_RH_FILE_ACTION } from 'actions/rhFile/deleteTeamBenefitRhFile';
import { RENAME_TEAM_BENEFIT_RH_FILE_ACTION } from 'actions/rhFile/renameTeamBenefitRhFile';
import { TOGGLE_TEAM_BENEFIT_RH_FILE_SHARED_ACTION } from 'actions/rhFile/toggleTeamBenefitRhFileShared';
import { ADD_OR_EDIT_TEAM_BENEFIT_TERM_DETAIL_ACTION } from 'actions/teamBenefit/addOrEditTeamBenefitTermDetail';
import { ADD_TEAM_BENEFIT_ACTION } from 'actions/teamBenefit/addTeamBenefit';
import { COPY_TEAM_BENEFIT_MANAGE_MEMBER_DATA_ACTION } from 'actions/teamBenefit/copyTeamBenefitManageMemberData';
import { DELETE_TEAM_BENEFIT_ACTION } from 'actions/teamBenefit/deleteTeamBenefit';
import { EDIT_TEAM_BENEFIT_ACTION } from 'actions/teamBenefit/editTeamBenefit';
import { GET_TEAM_BENEFITS_ACTION } from 'actions/teamBenefit/getTeamBenefits';
import {
    IRhFile,
    ITeamBenefit,
    ITeamBenefitWithTermDetailsDto,
    RhFile,
    RhFileDto,
} from 'api/generated/models';
import orderBy from 'lodash/orderBy';

const initialState: (ITeamBenefit | ITeamBenefitWithTermDetailsDto)[] = [];
type ITeamBenefitsAction = IAction<
    IRhFile | ITeamBenefit | ITeamBenefit[] | ITeamBenefitWithTermDetailsDto
>;

const modifyFilesOnTeamBenefit = (
    state: (ITeamBenefit | ITeamBenefitWithTermDetailsDto)[],
    action: ITeamBenefitsAction,
    modifyFiles: (files: RhFile[] | RhFileDto[] | undefined) => RhFile[] | RhFileDto[]
) => {
    const teamBenefit = state.find((x) =>
        x.rhFiles?.some((f) => f.id === (action.data as IRhFile).id)
    );
    if (teamBenefit) {
        return state.map((tb) => {
            if (tb.id === teamBenefit.id) {
                tb.rhFiles = modifyFiles(tb.rhFiles);
            }
            return tb;
        });
    }
    return state;
};

const sortCostsByStartDate = (
    teamBenefitsData: (ITeamBenefit | ITeamBenefitWithTermDetailsDto)[]
) =>
    teamBenefitsData.map((tb) => {
        tb.teamBenefitTermDetails = orderBy(tb.teamBenefitTermDetails, (x) =>
            x.startDate.toMomentDate()
        );
        return tb;
    });

export const teamBenefits = (
    state = initialState,
    action: ITeamBenefitsAction
): (ITeamBenefit | ITeamBenefitWithTermDetailsDto)[] => {
    switch (action.type) {
        case GET_TEAM_BENEFITS_ACTION.success: {
            return sortCostsByStartDate(action.data as ITeamBenefit[]);
        }
        case ADD_TEAM_BENEFIT_ACTION.success:
            return [action.data as ITeamBenefit, ...state];
        case ADD_TEAM_BENEFIT_RH_FILE_ACTION.success:
        case EDIT_TEAM_BENEFIT_ACTION.success: {
            const data = action.data as ITeamBenefit;
            return state.map((teamBenefit) => (teamBenefit.id === data.id ? data : teamBenefit));
        }
        case DELETE_TEAM_BENEFIT_ACTION.success:
            return state.filter((x) => x.id !== (action.data as ITeamBenefit).id);
        case TOGGLE_TEAM_BENEFIT_RH_FILE_SHARED_ACTION.success:
        case RENAME_TEAM_BENEFIT_RH_FILE_ACTION.success: {
            const data = action.data as RhFile;
            return modifyFilesOnTeamBenefit(
                state,
                action,
                (files) => files?.map((f) => (f.id === data.id ? data : f)) ?? []
            );
        }
        case DELETE_TEAM_BENEFIT_RH_FILE_ACTION.success:
            return modifyFilesOnTeamBenefit(
                state,
                action,
                (files) => files?.filter((f) => f.id !== (action.data as IRhFile).id) ?? []
            );
        case ADD_OR_EDIT_TEAM_BENEFIT_TERM_DETAIL_ACTION.success:
        case COPY_TEAM_BENEFIT_MANAGE_MEMBER_DATA_ACTION.success: {
            const data = action.data as ITeamBenefitWithTermDetailsDto;
            const newState = state.map((tb) => (tb.id === data.id ? data : tb));
            return sortCostsByStartDate(newState);
        }
    }

    return state;
};
