import { HealthCareTypeIds, PlanTypeIds } from 'api/generated/enums';
import { IMarketplacePlanDto, IMediSharePlan, ISelectedPlan } from 'api/generated/models';
import { ahpOptions } from 'constants/ahpOptions';
import { stringToInt } from './index';

export const buildSelectedMediSharePlan = (
    plan: Partial<IMediSharePlan> | undefined,
    options = ahpOptions
): Partial<ISelectedPlan> => ({
    healthCareTypeId: HealthCareTypeIds.HealthCareSharing,
    issuerName: 'Medi-Share',
    planId: plan?.ahp,
    planName: options.find((x) => `${x.id}` === plan?.ahp)?.name.replace('Medi-Share ', ''),
    planPremium: plan?.totalAmount,
    planPremiumWithCredits: plan?.totalAmount,
    planTypeId: PlanTypeIds.MediShare,
});

export const buildSelectedMarketplacePlan = (
    plan: IMarketplacePlanDto | undefined,
    planTypeId: PlanTypeIds
): Partial<ISelectedPlan> => ({
    planTypeId,
    benefitsLink: plan?.benefitsUrl,
    exchange: planTypeId === PlanTypeIds.OffExchange ? undefined : plan?.exchange,
    familyDeductibleCost: plan?.familyDeductibleCost,
    familyMoopCost: plan?.familyMoopCost,
    genericDrugCost: plan?.genericDrugCost,
    healthCareTypeId: HealthCareTypeIds.Medical,
    individualDeductibleCost: plan?.individualDeductibleCost,
    individualMoopCost: plan?.individualMoopCost,
    isHsaEligible: plan?.isHsaEligible,
    issuerName: plan?.issuer?.name,
    networkType: plan?.planType,
    planId: plan?.id,
    planName: plan?.name,
    planPremium: plan?.premium ?? 0,
    planPremiumWithCredits:
        planTypeId === PlanTypeIds.OffExchange ? plan?.premium ?? 0 : plan?.premiumWithCredits ?? 0,
    primaryCareCost: plan?.primaryCareCost,
    specialistCost: plan?.specialistCost,
    state: plan?.state,
});

const CommonSelectedPlanProps = {
    familyDeductibleCost: undefined,
    familyMoopCost: undefined,
    genericDrugCost: undefined,
    healthCareTypeId: HealthCareTypeIds.Medical,
    individualDeductibleCost: undefined,
    individualMoopCost: undefined,
    isCustom: true,
    networkType: undefined,
    planPremium: 0,
    planPremiumWithCredits: 0,
    primaryCareCost: undefined,
    specialistCost: undefined,
};

export const buildSelectedTermMedicalPlanFromPlanInputs = (
    planTypeId: PlanTypeIds,
    { issuerName, planName }: Pick<ISelectedPlan, 'issuerName' | 'planName'>
) => ({
    ...CommonSelectedPlanProps,
    issuerName,
    planName,
    planTypeId,
});

const SPOUSE = 'Spouse';
const PARENT = 'Parent';
export const buildSelectedOtherCoverageFromPlanInputs = (
    planInputs: { deductible?: number; planPremium?: number },
    planTypeId: PlanTypeIds
): Partial<ISelectedPlan> => ({
    ...CommonSelectedPlanProps,
    planTypeId,
    familyDeductibleCost: planInputs.deductible,
    individualDeductibleCost: planInputs.deductible,
    issuerName: planTypeId === PlanTypeIds.SpouseEmployer ? SPOUSE : PARENT,
    planName: planTypeId === PlanTypeIds.SpouseEmployer ? SPOUSE : PARENT,
    planPremium: planInputs.planPremium,
    planPremiumWithCredits: planInputs.planPremium,
});

const VETERANS_AFFAIRS = 'Veterans Affairs';
export const buildSelectedVeteransAffairsFromPlanInputs = ({
    planName,
}: Pick<ISelectedPlan, 'planName'>) => ({
    ...CommonSelectedPlanProps,
    planName,
    issuerName: VETERANS_AFFAIRS,
    planTypeId: PlanTypeIds.VeteransAffairs,
});

type IAncillaryPlanProperties = 'coverageEndDate' | 'coverageStartDate' | 'issuerName' | 'planName';
export const buildSelectedAncillaryPlanFromPlanInputs = ({
    planName,
    coverageStartDate,
    coverageEndDate,
    healthCareTypeId,
    issuerName,
}: Pick<ISelectedPlan, IAncillaryPlanProperties> & {
    healthCareTypeId: string | undefined;
}): Partial<ISelectedPlan> => ({
    ...CommonSelectedPlanProps,
    coverageEndDate,
    coverageStartDate,
    issuerName,
    planName,
    healthCareTypeId: stringToInt(healthCareTypeId ?? '') as HealthCareTypeIds,
    planTypeId: PlanTypeIds.Ancillary,
});

const MEDICARE = 'Medicare';
const MEDICARE_MONTHLY_PREMIUM = 255.5 as const;
export const selectedMedicarePlan: Partial<ISelectedPlan> = {
    ...CommonSelectedPlanProps,
    issuerName: MEDICARE,
    planName: MEDICARE,
    planPremium: MEDICARE_MONTHLY_PREMIUM,
    planPremiumWithCredits: MEDICARE_MONTHLY_PREMIUM,
    planTypeId: PlanTypeIds.Medicare,
};

const MEDICAID = 'Medicaid';
export const selectedMedicaidPlan: Partial<ISelectedPlan> = {
    ...CommonSelectedPlanProps,
    issuerName: MEDICAID,
    planName: MEDICAID,
    planTypeId: PlanTypeIds.Medicaid,
};

const CHIP = 'CHIP';
export const selectedChipPlan: Partial<ISelectedPlan> = {
    ...CommonSelectedPlanProps,
    issuerName: CHIP,
    planName: CHIP,
    planTypeId: PlanTypeIds.CHIP,
};
