import { capitalize } from 'lodash';

/**
 * Converts input string to Start Case while maintaining punctuation
 * @param someValue
 * @example toStartCase("JHON&JOHN C/O DR. BLah") // output: "Jhon&John C/O Dr. Blah"
 * @source https://github.com/lodash/lodash/issues/3383#issuecomment-430586750
 */
export const toStartCase = (someValue: string | undefined) =>
    someValue?.replace(/\w+/g, capitalize) ?? '';
