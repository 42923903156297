import { EnrollmentVerificationTypes } from 'api/generated/enums';
import startCase from 'lodash/startCase';
import { enumToNameValueArray, hasValue } from 'utilities';

export const VERIFICATION_TYPE_ITEMS = enumToNameValueArray(EnrollmentVerificationTypes, {
    formatName: startCase,
    nameMap: {
        SsnVerification: 'SSN Verification',
        VaCoverageStatus: 'VA Coverage Status',
    },
});

export const getEnrollmentVerificationTypeText = (
    evt: EnrollmentVerificationTypes | undefined
): string | undefined =>
    hasValue(evt) ? (VERIFICATION_TYPE_ITEMS[evt]?.['name'] as string) : undefined;
