import { IAction } from 'actions/commonAction';
import { CLEAR_SUBMITTED_EXPENSE_ASSIGNABLE_REIMBURSEMENT_REPORTS } from 'actions/submittedExpenses/clearAssignableReimbursementReports';
import { GET_SUBMITTED_EXPENSE_ASSIGNABLE_REIMBURSEMENT_REPORTS } from 'actions/submittedExpenses/getAssignableReimbursementReports';
import { IAssignableReportDto } from 'api/generated/models';

const initialState: IAssignableReportDto[] = [];

export const assignableReimbursementReports = (
    state = initialState,
    action: IAction<IAssignableReportDto[]>
): IAssignableReportDto[] => {
    switch (action.type) {
        case GET_SUBMITTED_EXPENSE_ASSIGNABLE_REIMBURSEMENT_REPORTS.success: {
            return action.data;
        }
        case CLEAR_SUBMITTED_EXPENSE_ASSIGNABLE_REIMBURSEMENT_REPORTS:
            return initialState;
        default:
            return state;
    }
};
