export const keysToBeSanitized: string[] = [
    'address',
    'addressLine1',
    'addressLine2',
    'city',
    'county',
    'countyFips',
    'householdPreExistingConditions',
    'mailingAddress',
    'noteText',
    'password',
    'phone',
    'socialSecurityNumber',
    'socialSecurityNumbers',
    'summarySections',
    'token',
    'zip',
    'fifteenMinuteTimeIntervals',
    'pathwayBlueprint',
    'pathwayBlueprintModalState',
    'teamProfiles',
    'userProfiles',
    'login',
    'options',
    'planModalState',
    'payrollReports',
    'paymentsInfo',
];

export const actionsToRedact: string[] = [
    'GET_TEAM_PROFILES_SUCCESS',
    'GET_USER_PROFILES_SUCCESS',
    'GET_PATHWAY_BLUEPRINT_SUCCESS',
    'TOKEN_SUCCESS',
    'GET_TEAM_USER_ROLES_SUCCESS',
    'GET_PAYROLL_REPORTS_SUCCESS',
    'GET_SSN_TASK_CONTENT_ACTION_SUCCESS',
    'GET_ACCOUNT_SNAPSHOT_ACTION_SUCCESS',
];
