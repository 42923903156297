import get from 'lodash/get';
import { Cell, Column, ColumnInstance, HeaderProps } from 'react-table';

const DEFAULT_MIN_WIDTH = 100;

export type CustomColumn<T extends object, C = object> = C & Column<T>;

export type CustomColumnCell<T extends object, V, C = object> = Omit<Cell<T, V>, 'column'> & {
    column: C & ColumnInstance<T>;
};

export type CustomColumnHeader<T extends object, C = object> = Omit<HeaderProps<T>, 'column'> & {
    column: C & ColumnInstance<T>;
};

const DEFAULT_MAGIC_NUMBER = 7.5;
export const getColumnWidth = <T>(
    data: T[],
    accessor: string | ((row: T) => string | undefined),
    headerText: string,
    options?: { magicSpacing?: number; minWidth?: number }
) => {
    const magicSpacing = options?.magicSpacing ?? DEFAULT_MAGIC_NUMBER;
    const minWidth = options?.minWidth ?? DEFAULT_MIN_WIDTH;
    const cellLength = Math.max(
        ...data.map((row) => {
            let value: number | string | undefined = '';

            if (typeof accessor === 'string') {
                value = get(row, accessor);
            } else {
                value = accessor(row);
            }

            if (typeof value === 'number') {
                return value.toString().length;
            }
            return (value || '').length;
        }),
        headerText.length
    );

    return Math.max(cellLength * magicSpacing, minWidth);
};

export const DEFAULT_ACTIONS_COLUMN_PROPS = {
    className: 'overflow-visible',
    disableSortBy: true,
    Header: 'Actions',
    id: 'actions',
};
