import { SurveyTypes, Tasks, TeamStateIds } from 'api/generated/enums';
import DOMPurify from 'dompurify';
import { History } from 'history';
import every from 'lodash/every';
import lodashIsEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
export type RecursivePartial<T> = {
    [P in keyof T]?: RecursivePartial<T[P]>;
};

export const nameof = <T>(name: keyof T) => name;

export const arrayToObject = <T>(arr: T[], keyField: keyof T) =>
    Object.assign({}, ...arr.map((item) => ({ [`${item[keyField]}`]: item })));

export const reduceToObject = <T>(array: T[], key: keyof T, value: keyof T) =>
    array.reduce<Record<string, T[keyof T]>>((obj, item) => {
        obj[`${item[key]}`] = item[value];
        return obj;
    }, {});

export const objectToArray = <T extends Record<string, R>, R>(obj: T) =>
    Object.keys(obj).map((i) => obj[i]);

export const flagsToValuesArray = (
    value: number | undefined,
    names: Record<number, string>,
    valueKey = 'status'
) => {
    const data: {
        name: string;
        [x: string]: number | string;
    }[] = [];
    if (value === undefined) {
        return data;
    }
    let mask = 0b1;
    let temp = value;
    while (temp > 0) {
        const bitValue = temp & mask;
        if (bitValue) {
            data.push({ name: names[bitValue] as string, [valueKey]: bitValue });
        }
        temp = temp ^ mask;
        mask = mask << 0b1;
    }
    return data;
};

export const updateFlag = (shouldSetFlag: boolean, currentFlags: number, flagToUpdate: number) =>
    shouldSetFlag ? currentFlags | flagToUpdate : currentFlags & ~flagToUpdate;

/**
 * Method to check the existence of a flag in a flag-based number/enum
 * @param flagBasedValue A number/enum value that uses flags
 * @param flagToCheckFor Flag to check against flagBasedValue
 * @returns boolean of whether or not flagToCheckFor is in flagBasedValue
 * @definition [What is a flag from stackoverflow?](https://stackoverflow.com/questions/8447/what-does-the-flags-enum-attribute-mean-in-c)
 */
export const hasFlag = <T extends number>(
    flagBasedValue: T | undefined,
    flagToCheckFor: T
): flagBasedValue is T => {
    if (flagBasedValue === undefined) {
        return false;
    }
    const test = flagBasedValue & flagToCheckFor;
    return test === flagToCheckFor;
};

/**
 * Method to check the existence of one or more flags in a flag-based number/enum
 * @param flagBasedValue A number/enum value that uses flags
 * @param flagsToCheckFor One or more flags to check against flagBasedValue
 * @returns boolean of whether or not flagsToCheckFor is in flagBasedValue
 * @definition [What is a flag from stackoverflow?](https://stackoverflow.com/questions/8447/what-does-the-flags-enum-attribute-mean-in-c)
 */
export const hasAnyFlag = <T extends number>(flagBasedValue: T, ...flagsToCheckFor: T[]) => {
    const mask = flagsToCheckFor.reduce((acc, current) => acc | current, 0);
    return (mask & flagBasedValue) !== 0;
};

/**
 * Method to get the number of set bits on a flag
 * @param flagBasedValue A number/enum value that uses flags
 * @returns number of set bits for the given flagBasedValue
 * @definition [What is a flag from stackoverflow?](https://stackoverflow.com/questions/8447/what-does-the-flags-enum-attribute-mean-in-c)
 */
export const getNumberOfSetBitsOnFlag = (flagBasedValue: number | undefined) => {
    if (flagBasedValue === undefined) {
        return 0;
    }
    let count = 0;
    while (flagBasedValue) {
        count += flagBasedValue & 1;
        flagBasedValue >>= 1;
    }
    return count;
};

/**
 * Explicitly check if a value is not whitespace/empty string, `null` or `undefined`
 * @param input A value that is type and value checked if `undefined`, `null`, and whitespace or empty `string`
 */
export const hasValue = <T>(input: T | null | undefined): input is T => {
    if (typeof input === 'string') {
        return input.trim() !== '';
    }

    return input !== undefined && input !== null;
};

export const arrayHasValue = <T>(input: T[] | undefined): input is T[] =>
    hasValue(input) ? input.length !== 0 : false;

/**
 * @deprecated Originally used for validation; use `yup` for validation instead
 * or one of the `isTrue`, `isFalse` or `isTrueOrFalse` methods for determining boolean values
 */
export const allFalse = <T>(collection: T): boolean =>
    every((collection as unknown) as object, (value) => {
        if (Array.isArray(value)) {
            return allFalse(value);
        } else if (typeof value === 'object') {
            return every(
                (value as unknown) as object,
                (x) => typeof x !== 'boolean' || x === false
            );
        } else if (typeof value === 'boolean') {
            return value === false;
        }
        return true;
    });

const unknownToString = (value: unknown) =>
    typeof value === 'bigint' ||
    typeof value === 'boolean' ||
    typeof value === 'number' ||
    typeof value === 'string' ||
    typeof value === 'symbol'
        ? value.toString()
        : undefined;

/**
 * Returns boolean expression of the value directly equaling `false` or will `toString` and lowercase the value and will check equal to `'false'`
 */
export const isFalse = (value: unknown) =>
    value === false || unknownToString(value)?.toLowerCase() === 'false';
/**
 * Returns boolean expression of the value directly equaling `true` or will `toString` and lowercase the value and will check equal to `'true'`
 */
export const isTrue = (value: unknown) =>
    value === true || unknownToString(value)?.toLowerCase() === 'true';
export const isTrueOrFalse = (value: unknown) => isTrue(value) || isFalse(value);

const ssnPattern = /^\d{3}-\d{2}-\d{4}$/;
export const invalidSsn = (value: string | undefined) =>
    lodashIsEmpty(value) || !value?.match(ssnPattern);
declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Array<T> {
        /**
         * Calls `Array.includes` directly; no changes except to allow typings that can be `undefined`.
         * Determines whether an array includes a certain element, returning true or false as appropriate.
         *
         * @calls `Array.includes`
         */
        contains: (this: (T | undefined)[], arg: T | undefined) => boolean;
        joinSerialComma: (this: T[]) => string;
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Number {
        readableFileSize: (this: number, isMetric?: boolean) => string;
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface String {
        clear: string;
        errorMessage: string;
        failed: string;
        forbid: string;
        progress: string;
        removeAll: (this: string, pattern: string) => string;
        removeNonNumericCharacters: (this: string) => string;
        requested: string;
        statusCode: string;
        success: string;
        withParams: string;
    }
}
Object.defineProperty(String.prototype, 'clear', {
    get() {
        return `${this}_CLEAR`;
    },
});

Object.defineProperty(String.prototype, 'errorMessage', {
    get() {
        return `${this}_ERROR_MESSAGE`;
    },
});

Object.defineProperty(String.prototype, 'failed', {
    get() {
        return `${this}_FAILED`;
    },
});

Object.defineProperty(String.prototype, 'forbid', {
    get() {
        return `${this}_FORBID`;
    },
});

Object.defineProperty(String.prototype, 'progress', {
    get() {
        return `${this}_PROGRESS`;
    },
});

Object.defineProperty(String.prototype, 'requested', {
    get() {
        return `${this}_REQUESTED`;
    },
});

Object.defineProperty(String.prototype, 'statusCode', {
    get() {
        return `${this}_STATUS_CODE`;
    },
});

Object.defineProperty(String.prototype, 'success', {
    get() {
        return `${this}_SUCCESS`;
    },
});

Object.defineProperty(String.prototype, 'withParams', {
    get() {
        return `${this}_WITH_PARAMS`;
    },
});

/**
 * Will take a given nullable array and check if the length is greater than `0`
 * @returns `true` if array has a length greater than `0` or `false` if not
 */
export const hasContents = <V, T extends V[] | string>(value: T | undefined): value is T =>
    (value?.length ?? 0) > 0;

Array.prototype.contains = function<T>(arg: T | undefined) {
    return this.includes(arg);
};
Array.prototype.joinSerialComma = function() {
    if (this.length === 2) {
        return this.join(' and ');
    }
    return this.join(', ').replace(/, ([^,]*)$/, ', and $1');
};

/**
 * Will Take in array of objects T and a key of T and return a deduplicated array of object T based on the given key of T
 * @source https://stackoverflow.com/a/56768137/4965713
 * @example
 * const someArray = [{id: 1, someValue: 2}, {id: 1, someValue: 5}]
 * const result = getUniqueListBy(someArray, 'id');
 * console.log(result); // [{id: 1, someValue: 5}]
 */
export const getUniqueListBy = <T>(someArray: T[] | undefined, key: keyof T) =>
    someArray ? [...new Map(someArray.map((item) => [item[key], item])).values()] : someArray;

export const readableFileSize = (bytes: number | undefined) => {
    const thresh = 1000;
    if (bytes === undefined) {
        return '';
    }
    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }
    const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    do {
        bytes /= thresh;
        ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return `${bytes.toFixed(1)} ${units[u]}`;
};

String.prototype.removeNonNumericCharacters = function() {
    return this.replace(/\D/g, '');
};

String.prototype.removeAll = function(pattern: string) {
    if (this.search(pattern) < 0) {
        return this;
    }
    return this.replace(pattern, '').removeAll(pattern);
};

/**
 * @returns `true` if `n` is not `null` or `undefined` and is greater than `value`; otherwise will return `false`
 */
export const greaterThan = (n: number | undefined, value: number): n is number =>
    hasValue(n) ? n > value : false;

const ONE_HUNDRED = 100;
export const roundTwoDecimals = (n: number | undefined) =>
    Math.round(((n ?? NaN) + Number.EPSILON) * ONE_HUNDRED) / ONE_HUNDRED;

export const safeGetArray = (value: unknown) => {
    if (!value) {
        return undefined;
    }
    if (Array.isArray(value)) {
        return value;
    }
    return [value];
};

export const clearCacheAndReload = () => {
    if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then((names) => {
            for (const name of names) {
                caches.delete(name);
            }
        });
    }
    // delete browser cache and hard reload
    window.location.reload();
};

type Enum<E> = Record<keyof E, number | string> & Record<number, string>;

export const getEnumNames = <E extends Enum<E>>(
    enumObject: E,
    transform: ((value: string) => string) | null = null
) => {
    let enumNames = Object.keys(enumObject).filter(
        (x) => typeof enumObject[x as keyof typeof enumObject] === 'number'
    );
    if (transform) {
        enumNames = enumNames.map((x) => transform(x));
    }
    return enumNames;
};

export const enumToString = <E extends Enum<E>>(
    enumObject: E,
    enumValue: number,
    enumExceptions?: { name: string; taskId: Tasks }[] | undefined
) => {
    let enumName = '';
    if (arrayHasValue(enumExceptions)) {
        enumName = enumExceptions.find((e) => e.taskId === enumValue)?.name as string;
    }

    if (hasValue(enumName)) {
        return enumName;
    } else {
        return enumObject[enumValue]?.replace(/([a-z])([A-Z])/g, '$1 $2');
    }
};

type IEnumToNameValueOptions<E extends Enum<E>> = {
    formatName?: ((name: string) => string) | null;
    nameKey?: string;
    nameMap?: Partial<Record<keyof E, string>> | null;
};

export const enumToNameValueArray = <E extends Enum<E>>(
    enumObject: E,
    { nameKey = 'name', nameMap = null, formatName = null }: IEnumToNameValueOptions<E> = {
        formatName: null,
        nameKey: 'name',
        nameMap: null,
    },
    sortAlphabetically?: boolean
) => {
    const arr = getEnumNames(enumObject).map((x) => {
        const name = nameMap?.[x as keyof E] ?? formatName?.(x) ?? x;
        return {
            [nameKey]: name,
            value: enumObject[x as keyof E],
        };
    });
    if (sortAlphabetically) {
        arr.sort((a, b) => {
            const aName = a['name']?.toString() ?? '';
            const bName = b['name']?.toString() ?? '';
            return aName.localeCompare(bName);
        });
    }
    return arr;
};

export const enumArrayToNameArray = <E extends Enum<E>>(
    enumArray: number[] | undefined,
    enumObject: E,
    options?: IEnumToNameValueOptions<E> | undefined
) => {
    const enumNameValueArray = enumToNameValueArray(enumObject, {
        formatName: startCase,
        ...options,
    });
    return enumArray?.map(
        (option) => enumNameValueArray.find((x) => x.value === option)?.['name'] as string
    );
};

const numberOfDecimals = 2;
export const decimalToPercentageString = (n?: number) => {
    if (n === undefined) {
        return 'N/A';
    }
    return `${(n * 100).toFixed(numberOfDecimals)}%`; // NOSONAR
};

/**
 * Will check if `value` not `hasValue` or `isNaN` and return `undefined` if true or `value` if not
 * If not null, whitespace, or NaN will `transform` if given or return `value`
 * @param value
 * @param transform optional
 * @returns `undefined` or `value`
 */
const toValue = <T, U = undefined>(
    value: T | undefined,
    transform?: (value: T) => U
): T | U | undefined => {
    if (!hasValue(value) || (typeof value === 'number' && isNaN(value))) {
        return undefined;
    }
    return transform?.(value) ?? value;
};

/**
 * Will check if value not `hasValue` and then parse int of value
 * @param value string
 * @returns `undefined` or `parseInt(value)`
 */
export const stringToInt = (value: string | undefined) =>
    toValue(value, parseInt) as number | undefined;

/**
 * Will check if value not `hasValue` and then parse float of value
 * @param value string
 * @returns `undefined` or `parseFloat(value)`
 */
export const stringToFloat = (value: string | undefined) =>
    toValue(value, parseFloat) as number | undefined;
export const stringToFloatAbs = (value: string | undefined) =>
    toValue(value, (_value) => (_value ? Math.abs(parseFloat(_value)) : NaN));

/**
 * If value not `hasValue` will return `undefined` else call `toBoolean`
 * @calls not `hasValue` or `toBoolean`
 * @param value
 * @returns `undefined` or `boolean`
 */
export const toUndefinedOrBoolean = <T>(value: T): boolean | undefined =>
    toValue(value, toBoolean) as boolean | undefined;

/**
 * Convert given value into boolean
 * Scenarios:
 * 1. If boolean will return value as-is
 * 1. If string will check if string value is `'true'`, `'1'`, `'on'`, `'yes'` and return `true`; all other values will be false
 * 1. Else return `!!value`
 * @param value
 * @returns boolean
 */
export const toBoolean = <T>(value: T): boolean => {
    if (typeof value === 'boolean') {
        return value;
    } else if (typeof value === 'string') {
        switch (value.trim().toLowerCase()) {
            case 'true':
            case '1':
            case 'on':
            case 'yes':
                return true;
            default:
                return false;
        }
    }
    return !!value;
};

/**
 * If value is empty string or null return null otherwise convert to boolean
 * @param value
 * @returns boolean | null
 */
export const toNullOrBoolean = <T>(value: T): boolean | null => {
    if ((typeof value === 'string' && value === '') || value === null) {
        return null;
    }
    return toBoolean(value);
};

export const convertUrlsToHyperlinks = (text: string) => {
    // eslint-disable-next-line no-useless-escape
    const regex = /(?:^|)((http|https):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/gi;
    const clean = DOMPurify.sanitize(text);
    return clean.replace(regex, "<a href='$1' target='_blank'>$1</a>");
};

export const isHandledByCustomerSuccess = (teamStateId: TeamStateIds) =>
    [TeamStateIds.Customer, TeamStateIds.Renewing, TeamStateIds.Offboarding].includes(teamStateId);

export const ichraCheckThroughRenewal = (
    pathHistory: History<unknown>,
    isIchraPathway: boolean | undefined,
    surveyTypeToSend: SurveyTypes | undefined
) =>
    pathHistory.location.pathname === '/member-renewal-survey'
        ? isIchraPathway || surveyTypeToSend === SurveyTypes.ICHRA
        : surveyTypeToSend === SurveyTypes.ICHRA;
export const IS_DEV_MODE = process.env['DEV_MODE'] === 'true';

export const getEnumElementByIndex = <T>(enumType: T, index: number): T[keyof T] | undefined => {
    const enumValues = Object.values(enumType).filter((value) => typeof value === 'number');
    return enumValues[index] !== undefined ? (enumValues[index] as T[keyof T]) : undefined;
};

export function getEnumValueByIndex<T>(enumObj: T, index: number): string | undefined {
    const enumValues = Object.keys(enumObj).filter(key => isNaN(Number(key)));
    return enumValues[index];
}
