import { IAction } from 'actions/commonAction';
import { LIST_AGE_PREMIUM_RATIOS_ACTION } from 'actions/pathwayBlueprint/listAgePremiumRatios';

const initialState: Record<string, number> = {};

export const agePremiumRatios = (
    state = initialState,
    action: IAction<Record<string, number>>
): Record<string, number> => {
    if (action.type === LIST_AGE_PREMIUM_RATIOS_ACTION.success) {
        return action.data;
    }
    return state;
};
