import { GET_STATES_SUCCESS } from 'actions/getStates';

type IState = { abbrev: string; name: string };

const initialState: IState[] = [];

export const states = (
    state = initialState,
    action: { states: IState[]; type: string }
): IState[] => {
    switch (action.type) {
        case GET_STATES_SUCCESS:
            return [...action.states];
        default:
            return state;
    }
};
