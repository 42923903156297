import { useCallback, useState } from 'react';
import {
    IFormErrors,
    IValidateData,
    formatErrors,
    validate as validateForm,
} from 'utilities/forms';
import { BaseSchema, ValidationError } from 'yup';
import { ValidateOptions } from 'yup/lib/types';

const useForm = <T extends BaseSchema>(schema: T) => {
    const [errors, setErrors] = useState<IFormErrors<typeof schema>>();
    const validate = useCallback(
        async (
            data: IValidateData<T>,
            options?: ValidateOptions
        ): Promise<{ data?: IValidateData<T>; isValid: boolean }> => {
            try {
                const validData = await validateForm(schema, data, options);
                setErrors(null);
                return { data: validData, isValid: true };
            } catch (_errors) {
                setErrors(formatErrors(_errors as ValidationError));
                return { isValid: false };
            }
        },
        [schema]
    );

    return { errors, setErrors, validate };
};

export default useForm;
