import { CLEAR_USER } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_USER_ACTION } from 'actions/user/getUser';
import { IUser } from 'api/generated/models';

const initialState: IUser = ({} as unknown) as IUser;

export const user = (state = initialState, action: IAction<IUser>): IUser => {
    if (action.type === GET_USER_ACTION.success) {
        return {
            ...state,
            ...action.data,
        };
    } else if (action.type === CLEAR_USER) {
        return ({} as unknown) as IUser;
    } else {
        return state;
    }
};
