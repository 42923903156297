import { useCallback, useMemo, useState } from 'react';

export type WithKey<T> = T & { key: number };

export type ListItemChangeCallback<T> = <K extends keyof WithKey<T>, V extends WithKey<T>[K]>(
    key: number,
    prop: K
) => (value: V) => void;

const useEditableListState = <T>(initialState: T[], createNew: () => T) => {
    const itemsWithKeys = useMemo(() => {
        const now = Date.now();
        return (initialState ?? []).map((x, i) => ({ ...x, key: now + i }));
    }, [initialState]);
    const [items, setItems] = useState(itemsWithKeys);

    const addNew = useCallback(() => {
        const newItem = { ...createNew(), key: Date.now() };
        setItems((_items) => [..._items, newItem]);
    }, [createNew]);

    const onItemFieldChange: ListItemChangeCallback<T> = useCallback(
        (key, prop) => (value) => {
            setItems((_items) =>
                _items.map((x) => {
                    if (x.key !== key) {
                        return x;
                    }
                    x[prop] = value;
                    return x;
                })
            );
        },
        []
    );

    const removeItem = useCallback(
        (key: number) => () => {
            setItems((_items) => _items.filter((x) => x.key !== key));
        },
        []
    );

    return { addNew, items, onItemFieldChange, removeItem };
};
export default useEditableListState;
