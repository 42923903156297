type ICallback<T> = (data: T) => void;

type IEventEmitter<T> = {
    emit: (event: string, data?: T) => void;
    events: Record<string, ICallback<T>[]>;
    listen: (event: string, callback: ICallback<T>) => void;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EventEmitter: IEventEmitter<any> = {
    emit<T>(event: string, data?: T | undefined) {
        if (!this.events[event]) {
            return;
        }
        this.events[event]?.forEach((callback) => callback(data));
    },
    events: {},
    listen<T>(event: string, callback: ICallback<T>) {
        if (!this.events[event]) {
            this.events[event] = [];
        }
        this.events[event]?.push(callback);
    },
};
export default EventEmitter;
