import { CLEAR_TEAM_BENEFIT_TYPE_CARRIERS } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_TEAM_BENEFIT_CARRIERS_BY_TYPE_ACTION } from 'actions/teamBenefitType/getTeamBenefitCarriersByType';
import { ITeamBenefitTypesCarrier } from 'api/generated/models';

const initialState: ITeamBenefitTypesCarrier[] = [];

export const teamBenefitTypeCarriers = (
    state = initialState,
    action: IAction<ITeamBenefitTypesCarrier[]>
): ITeamBenefitTypesCarrier[] => {
    switch (action.type) {
        case GET_TEAM_BENEFIT_CARRIERS_BY_TYPE_ACTION.success:
            return [...action.data];
        case CLEAR_TEAM_BENEFIT_TYPE_CARRIERS:
            return [];
        default:
            return state;
    }
};
