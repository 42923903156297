import { CLEAR_SELECTED_PLANS_ACTION } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { ADD_CUSTOM_SELECTED_PLANS_ACTION } from 'actions/selectedPlan/addCustomSelectedPlans';
import { ADD_SELECTED_PLAN_ACTION } from 'actions/selectedPlan/addSelectedPlan';
import { DELETE_SELECTED_PLAN_ACTION } from 'actions/selectedPlan/deleteSelectedPlan';
import { GET_SELECTED_PLANS_ACTION } from 'actions/selectedPlan/getSelectedPlans';
import { PATCH_SELECTED_PLAN_ACTION } from 'actions/selectedPlan/patchSelectedPlans';
import { ISelectedPlan } from 'api/generated/models';

const initialState: ISelectedPlan[] = [];

export const selectedPlans = (
    state = initialState,
    action: IAction<ISelectedPlan | ISelectedPlan[]>
): ISelectedPlan[] => {
    switch (action.type) {
        case GET_SELECTED_PLANS_ACTION.success:
            return [...(action.data as ISelectedPlan[])];
        case ADD_CUSTOM_SELECTED_PLANS_ACTION.success:
            return [...state, ...(action.data as ISelectedPlan[])];
        case ADD_SELECTED_PLAN_ACTION.success:
            return [...state, action.data as ISelectedPlan];
        case PATCH_SELECTED_PLAN_ACTION.success: {
            const data = action.data as ISelectedPlan;
            return state.map((x) => (x.selectedPlanId === data.selectedPlanId ? data : x));
        }
        case DELETE_SELECTED_PLAN_ACTION.success:
            return state.filter(
                (x) => x.selectedPlanId !== (action.data as ISelectedPlan).selectedPlanId
            );
        case CLEAR_SELECTED_PLANS_ACTION:
            return initialState;
        default:
            return state;
    }
};
