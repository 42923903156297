import { IAction } from 'actions/commonAction';
import { GET_HOUSEHOLD_ELIGIBILITY_ACTION } from 'actions/user/getHouseholdEligibility';
import { IMemberEligibility } from 'api/generated/models';

const initialState: IMemberEligibility[] = [];

export const householdEligibility = (
    state = initialState,
    action: IAction<IMemberEligibility[]>
) => {
    if (action.type === GET_HOUSEHOLD_ELIGIBILITY_ACTION.success) {
        return [...action.data];
    }
    return state;
};
