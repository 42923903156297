import TeamManagementContext from 'contexts/TeamManagementContext';
import React, { useContext, useEffect } from 'react';
import { hot } from 'react-hot-loader';
import { StaticContext } from 'react-router';
import { Route, RouteComponentProps } from 'react-router-dom';
import { IPageComponent, IPageData } from 'routers/routes';

export const getPathData = (
    routeData: IPageData,
    paths: string[],
    isTeamManagementPage = false
): IRouteData[] =>
    paths.map((path) => ({
        ...routeData,
        isTeamManagementPage,
        path,
    }));

export type IRouteData = {
    Component: IPageComponent;
    isTeamManagementPage: boolean;
    path: string;
};

const RouteComponent = ({
    Component,
    RouterProps,
    isTeamManagementPage,
}: {
    Component: IPageComponent;
    RouterProps: RouteComponentProps<object, StaticContext, unknown>;
    isTeamManagementPage: boolean;
}) => {
    const { setIsTeamManagementPage } = useContext(TeamManagementContext);
    useEffect(() => {
        setIsTeamManagementPage?.(isTeamManagementPage);
    }, [isTeamManagementPage, setIsTeamManagementPage]);
    return <Component isTeamManagementPage={isTeamManagementPage} {...RouterProps} />;
};

const RouteData = ({ data }: { data: IRouteData[] }) => (
    <React.Fragment>
        {data.map(({ Component, isTeamManagementPage, path }) => (
            <Route
                exact
                key={path}
                path={path}
                render={(props) => (
                    <RouteComponent
                        Component={Component}
                        isTeamManagementPage={isTeamManagementPage}
                        RouterProps={props}
                    />
                )}
            />
        ))}
    </React.Fragment>
);

export default hot(module)(RouteData);
