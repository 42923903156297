import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { getTeamProps } from 'selectors';

const useTeamProps = (teamId?: string) => {
    const params = useParams<{ teamId?: string }>();
    return useSelector((state: AppStore) => getTeamProps(state, teamId ? { teamId } : params));
};

export default useTeamProps;
