import { CLEAR_SHOPPING_PATHWAY_MEDISHARE_PROGRAMS } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { IMediSharePlan } from 'api/generated/models';

export const GET_PATHWAY_MEDISHARE_PROGRAMS_FOR_USER_ACTION =
    'GET_PATHWAY_MEDISHARE_PROGRAMS_FOR_USER';

const initialState: IMediSharePlan[] = [];

export const shoppingPathwayMediSharePrograms = (
    state = initialState,
    action: IAction<IMediSharePlan[]>
): IMediSharePlan[] => {
    switch (action.type) {
        case CLEAR_SHOPPING_PATHWAY_MEDISHARE_PROGRAMS:
            return [];
        case GET_PATHWAY_MEDISHARE_PROGRAMS_FOR_USER_ACTION.success:
            return [...action.data];
        default:
            return state;
    }
};
