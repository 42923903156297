import { ahpOptions } from 'constants/ahpOptions';
import {
    MARKETPLACE_BENCHMARK,
    MARKETPLACE_FLAT,
    SHARING_BENCHMARK,
    SHARING_FLAT,
} from 'constants/budgetStrategies';
import GENDERS from 'constants/genders';
import { groupLevelAbbreviations, groupLevelNames, groupLevels } from 'constants/groupLevels';
import { taxFilingStatuses } from 'constants/taxFilingStatuses';
import cloneDeep from 'lodash/cloneDeep';

export const yesOrNo = [
    { text: 'Yes', value: true },
    { text: 'No', value: false },
];
export const genderList = [
    { id: GENDERS.FEMALE, value: 'Female' },
    { id: GENDERS.MALE, value: 'Male' },
];
export const filingStatusOptions = [
    { filingStatusId: taxFilingStatuses.Unknown, name: 'Unknown' },
    { filingStatusId: taxFilingStatuses.Single, name: 'Single' },
    { filingStatusId: taxFilingStatuses.MarriedJoint, name: 'Married Filing Jointly' },
    { filingStatusId: taxFilingStatuses.MarriedSeparate, name: 'Married Filing Separately' },
    { filingStatusId: taxFilingStatuses.HeadOfHousehold, name: 'Head of Household' },
];

const initialState = {
    ahpOptions,
    budgetStrategyOptions: [
        { id: MARKETPLACE_FLAT, name: 'Marketplace - Flat' },
        { id: MARKETPLACE_BENCHMARK, name: 'Marketplace - Benchmark' },
        { id: SHARING_FLAT, name: 'Sharing Program - Flat' },
        { id: SHARING_BENCHMARK, name: 'Sharing Program - Benchmark' },
    ],
    fifteenMinuteIntervalTimes: [
        { display: '7:00 AM', value: '07:00:00' },
        { display: '7:15 AM', value: '07:15:00' },
        { display: '7:30 AM', value: '07:30:00' },
        { display: '7:45 AM', value: '07:45:00' },
        { display: '8:00 AM', value: '08:00:00' },
        { display: '8:15 AM', value: '08:15:00' },
        { display: '8:30 AM', value: '08:30:00' },
        { display: '8:45 AM', value: '08:45:00' },
        { display: '9:00 AM', value: '09:00:00' },
        { display: '9:15 AM', value: '09:15:00' },
        { display: '9:30 AM', value: '09:30:00' },
        { display: '9:45 AM', value: '09:45:00' },
        { display: '10:00 AM', value: '10:00:00' },
        { display: '10:15 AM', value: '10:15:00' },
        { display: '10:30 AM', value: '10:30:00' },
        { display: '10:45 AM', value: '10:45:00' },
        { display: '11:00 AM', value: '11:00:00' },
        { display: '11:15 AM', value: '11:15:00' },
        { display: '11:30 AM', value: '11:30:00' },
        { display: '11:45 AM', value: '11:45:00' },
        { display: '12:00 PM', value: '12:00:00' },
        { display: '12:15 PM', value: '12:15:00' },
        { display: '12:30 PM', value: '12:30:00' },
        { display: '12:45 PM', value: '12:45:00' },
        { display: '1:00 PM', value: '13:00:00' },
        { display: '1:15 PM', value: '13:15:00' },
        { display: '1:30 PM', value: '13:30:00' },
        { display: '1:45 PM', value: '13:45:00' },
        { display: '2:00 PM', value: '14:00:00' },
        { display: '2:15 PM', value: '14:15:00' },
        { display: '2:30 PM', value: '14:30:00' },
        { display: '2:45 PM', value: '14:45:00' },
        { display: '3:00 PM', value: '15:00:00' },
        { display: '3:15 PM', value: '15:15:00' },
        { display: '3:30 PM', value: '15:30:00' },
        { display: '3:45 PM', value: '15:45:00' },
        { display: '4:00 PM', value: '16:00:00' },
        { display: '4:15 PM', value: '16:15:00' },
        { display: '4:30 PM', value: '16:30:00' },
        { display: '4:45 PM', value: '16:45:00' },
        { display: '5:00 PM', value: '17:00:00' },
        { display: '5:15 PM', value: '17:15:00' },
        { display: '5:30 PM', value: '17:30:00' },
        { display: '5:45 PM', value: '17:45:00' },
        { display: '6:00 PM', value: '18:00:00' },
        { display: '6:15 PM', value: '18:15:00' },
        { display: '6:30 PM', value: '18:30:00' },
        { display: '6:45 PM', value: '18:45:00' },
        { display: '7:00 PM', value: '19:00:00' },
        { display: '7:15 PM', value: '19:15:00' },
        { display: '7:30 PM', value: '19:30:00' },
        { display: '7:45 PM', value: '19:45:00' },
        { display: '8:00 PM', value: '20:00:00' },
        { display: '8:15 PM', value: '20:15:00' },
        { display: '8:30 PM', value: '20:30:00' },
        { display: '8:45 PM', value: '20:45:00' },
    ],
    filingStatusOptions: cloneDeep(filingStatusOptions),
    genderList: cloneDeep(genderList),
    groupLevels: [
        {
            abbrev: groupLevelAbbreviations[groupLevels.Unknown],
            fulltext: groupLevelNames[groupLevels.Unknown],
            groupLevelId: groupLevels.Unknown,
        },
        {
            abbrev: groupLevelAbbreviations[groupLevels.EmployeeOnly],
            fulltext: groupLevelNames[groupLevels.EmployeeOnly],
            groupLevelId: groupLevels.EmployeeOnly,
        },
        {
            abbrev: groupLevelAbbreviations[groupLevels.EmployeeAndSpouse],
            fulltext: groupLevelNames[groupLevels.EmployeeAndSpouse],
            groupLevelId: groupLevels.EmployeeAndSpouse,
        },
        {
            abbrev: groupLevelAbbreviations[groupLevels.EmployeeWithChildren],
            fulltext: groupLevelNames[groupLevels.EmployeeWithChildren],
            groupLevelId: groupLevels.EmployeeWithChildren,
        },
        {
            abbrev: groupLevelAbbreviations[groupLevels.Family],
            fulltext: groupLevelNames[groupLevels.Family],
            groupLevelId: groupLevels.Family,
        },
        {
            abbrev: groupLevelAbbreviations[groupLevels.Waived],
            fulltext: groupLevelNames[groupLevels.Waived],
            groupLevelId: groupLevels.Waived,
        },
    ],
    yesOrNo: cloneDeep(yesOrNo),
};

export const options = (state = initialState) => state;
