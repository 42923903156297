import { getPathwayBlueprint } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import axios from 'axios';
import Toast from 'components/Toast';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { useCallback, useEffect, useRef, useState } from 'react';

const FIVE_SECONDS_IN_MILLISECONDS = 5000;
const ONE_THOUSAND_MILLISECONDS = 1000;
const SIXTY = 60;
const TEN = 10;
const TEN_MINUTES_IN_MILLISECONDS = ONE_THOUSAND_MILLISECONDS * SIXTY * TEN;
export const useGetPathwayBlueprintInterval = (
    pathwayBlueprintId: string | undefined,
    isCalculating: boolean
) => {
    const dispatch = useThunkDispatch();
    const cancelTokenSource = axios.CancelToken.source();
    const [isGettingBlueprintAtInterval, setIsGettingBlueprintAtInterval] = useState(isCalculating);
    const intervalToGetBlueprint = useRef<NodeJS.Timeout>();
    const timeoutToGetBlueprint = useRef<NodeJS.Timeout>();

    const clearGetPathwayBlueprint = useCallback(() => {
        if (intervalToGetBlueprint.current !== undefined) {
            clearInterval(intervalToGetBlueprint.current);
        }
        if (timeoutToGetBlueprint.current !== undefined) {
            clearTimeout(timeoutToGetBlueprint.current);
        }
        intervalToGetBlueprint.current = undefined;
        timeoutToGetBlueprint.current = undefined;
        cancelTokenSource.cancel();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intervalToGetBlueprint, timeoutToGetBlueprint]);

    useEffect(() => {
        setIsGettingBlueprintAtInterval(isCalculating);
        if (!isCalculating) {
            clearGetPathwayBlueprint();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCalculating]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => clearGetPathwayBlueprint, []);

    useEffect(() => {
        if (
            isCalculating &&
            isGettingBlueprintAtInterval &&
            !intervalToGetBlueprint.current &&
            !timeoutToGetBlueprint.current
        ) {
            intervalToGetBlueprint.current = setInterval(async () => {
                await dispatch(
                    getPathwayBlueprint(pathwayBlueprintId, {
                        cancelToken: cancelTokenSource.token,
                        onSuccess: (pathwayBlueprint) => {
                            if (!pathwayBlueprint.isCalculating) {
                                Toast.success('Calculations are complete.');
                            }
                        },
                    })
                );
            }, FIVE_SECONDS_IN_MILLISECONDS);
            timeoutToGetBlueprint.current = setTimeout(() => {
                setIsGettingBlueprintAtInterval(false);
                clearGetPathwayBlueprint();
                Toast.info(
                    'Calculation is taking a while... You will receive an email when it is complete.'
                );
            }, TEN_MINUTES_IN_MILLISECONDS);
        } else if (
            !isCalculating &&
            !!intervalToGetBlueprint.current &&
            !!timeoutToGetBlueprint.current
        ) {
            clearGetPathwayBlueprint();
        }
    }, [
        clearGetPathwayBlueprint,
        dispatch,
        intervalToGetBlueprint,
        isGettingBlueprintAtInterval,
        isCalculating,
        pathwayBlueprintId,
        timeoutToGetBlueprint,
        cancelTokenSource.token,
    ]);
};
