import { IAction } from 'actions/commonAction';
import { DELETE_FAVORITE_TEAM_ACTION } from 'actions/favoriteTeams/deleteFavoriteTeam';
import { GET_FAVORITE_TEAMS_ACTION } from 'actions/favoriteTeams/getFavoriteTeams';
import { FavoriteTeamDto } from 'api/generated/models';

const initialState: FavoriteTeamDto[] = [];

export const favoriteTeams = (
    state = initialState,
    action: IAction<FavoriteTeamDto | FavoriteTeamDto[]>
): FavoriteTeamDto[] => {
    switch (action.type) {
        case GET_FAVORITE_TEAMS_ACTION.success:
            return [...(action.data as FavoriteTeamDto[])];
        case DELETE_FAVORITE_TEAM_ACTION.success:
            return state.filter((uft) => uft.teamId !== (action.data as FavoriteTeamDto).teamId);
        default:
            return state;
    }
};
