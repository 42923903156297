import { IAction } from 'actions/commonAction';
import { GET_PROVIDERS_IN_PLAN_NETWORK_ACTION } from 'actions/providerSearch/getProvidersInPlanNetwork';
import { IInNetworkProviderDto } from 'api/generated/models';
import { hasValue } from 'utilities/index';

const initialState: IInNetworkProviderDto[] = [];

export const ideonProvidersInPlanNetworks = (
    state = initialState,
    action: IAction<IInNetworkProviderDto>
): IInNetworkProviderDto[] => {
    switch (action.type) {
        case GET_PROVIDERS_IN_PLAN_NETWORK_ACTION.success: {
            const isExistingData = state.find(
                (inNetworkProviders) => inNetworkProviders.planId === action.data.planId
            );
            if (hasValue(isExistingData)) {
                return state.map((inNetworkProviders) =>
                    inNetworkProviders.planId === action.data.planId
                        ? action.data
                        : inNetworkProviders
                );
            } else {
                return [action.data, ...state];
            }
        }
        default:
            return state;
    }
};
