import { CLEAR_YEARLY_REIMBURSEMENT_REPORT_ACTION } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_YEARLY_REIMBURSEMENT_REPORT_ACTION } from 'actions/reimbursementReport/getYearlyReimbursementReport';
import { IReimbursementReportsYearlyDto } from 'api/generated/models';

const initialState: IReimbursementReportsYearlyDto = ({} as unknown) as IReimbursementReportsYearlyDto;

export const reimbursementReportsYearly = (
    state = initialState,
    action: IAction<IReimbursementReportsYearlyDto>
): IReimbursementReportsYearlyDto => {
    switch (action.type) {
        case GET_YEARLY_REIMBURSEMENT_REPORT_ACTION.success: {
            return action.data;
        }
        case CLEAR_YEARLY_REIMBURSEMENT_REPORT_ACTION:
            return initialState;
        default:
            return state;
    }
};
