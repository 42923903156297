import { IAction } from 'actions/commonAction';
import { GET_SURVEY_YEAR_FOR_TEAM_ACTION } from 'actions/survey/getSurveyYearForTeam';
import { GET_SURVEY_YEAR_FOR_USER_ACTION } from 'actions/survey/getSurveyYearForUser';

const initialState = {
    surveyYear: 0,
};

export const survey = (state = initialState, action: IAction<number>): typeof initialState => {
    if (
        [GET_SURVEY_YEAR_FOR_TEAM_ACTION.success, GET_SURVEY_YEAR_FOR_USER_ACTION.success].includes(
            action.type
        )
    ) {
        return {
            ...state,
            surveyYear: action.data,
        };
    } else {
        return state;
    }
};
