import { CLEAR_TEAM } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { CREATE_OR_PATCH_TEAM_OPERATIONS_INFO_ACTION } from 'actions/team/createOrPatchTeamOperationsInfo';
import { GET_TEAM_OPERATIONS_INFO_ACTION } from 'actions/team/getTeamOperationsInfo';
import { ITeamOperationsInfo } from 'api/generated/models';

const initialState: ITeamOperationsInfo = ({} as unknown) as ITeamOperationsInfo;

export const teamOperationsInfo = (
    state = initialState,
    action: IAction<ITeamOperationsInfo>
): ITeamOperationsInfo => {
    switch (action.type) {
        case GET_TEAM_OPERATIONS_INFO_ACTION.success:
        case CREATE_OR_PATCH_TEAM_OPERATIONS_INFO_ACTION.success:
            return { ...action.data };
        case GET_TEAM_OPERATIONS_INFO_ACTION.failed:
        case CLEAR_TEAM:
            return ({} as unknown) as ITeamOperationsInfo;
        default:
            return state;
    }
};
