import { CLEAR_IMPORTED_AGE_BANDED_TABLE_ACTION } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { IMPORT_AGE_BANDED_TABLE_ACTION } from 'actions/pathwayBlueprint/importAgeBandedTable';
import { IImportAgeBandedTableResponseDto } from 'api/generated/models';

const initialState: IImportAgeBandedTableResponseDto = ({} as unknown) as IImportAgeBandedTableResponseDto;

export const importedAgeBandedTable = (
    state = initialState,
    action: IAction<IImportAgeBandedTableResponseDto>
): IImportAgeBandedTableResponseDto => {
    if (action.type === IMPORT_AGE_BANDED_TABLE_ACTION.success) {
        return action.data;
    } else if (action.type === CLEAR_IMPORTED_AGE_BANDED_TABLE_ACTION) {
        return initialState;
    }
    return state;
};
