type IApiError = Record<string, number | string>;

export const apiErrors = (
    state: IApiError = {},
    action: {
        errorMessage: string;
        params: Record<string, string>;
        statusCode: number;
        type: string;
    }
): IApiError => {
    const { type, statusCode, errorMessage } = action;
    const matches = /(.*)_(REQUESTED|SUCCESS|FAILED|CLEAR)/.exec(type);

    // not a *_REQUESTED / *_SUCCESS /  *_FAILED actions, so we ignore them
    if (!matches) {
        return state;
    }

    const [, requestName, requestState] = matches;
    const newState = {
        ...state,
        // e.g. statusCode when receiving GET_TODOS_FAILED, delete otherwise
        [requestName?.statusCode as string]: statusCode,
        // e.g. errorMessage when receiving GET_TODOS_FAILED, delete otherwise
        [requestName?.errorMessage as string]: errorMessage,
    };
    if (requestState !== 'FAILED') {
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete newState[requestName?.statusCode as string];
        // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
        delete newState[requestName?.errorMessage as string];
    }
    return newState;
};
