import { IAction } from 'actions/commonAction';
import { COMPLETE_TASK_ACTION } from 'actions/taskFlows/completeTask';
import { EDIT_TASK_STATUS_ACTION } from 'actions/taskFlows/editTaskStatus';
import { LIST_VISIBLE_FLOWS_ACTION } from 'actions/taskFlows/listVisibleFlows';
import { IUserFlowDto } from 'api/generated/models';

const initialState: IUserFlowDto[] = ([] as unknown) as IUserFlowDto[];

export const userFlows = (
    state = initialState,
    action: IAction<IUserFlowDto | IUserFlowDto[]>
): IUserFlowDto[] => {
    switch (action.type) {
        case COMPLETE_TASK_ACTION.success:
        case EDIT_TASK_STATUS_ACTION.success: {
            const data = action.data as IUserFlowDto;
            const userFlowToChange = state.find((x) => x.globalId === data.globalId);
            if (userFlowToChange) {
                userFlowToChange.tasks = data.tasks;
                userFlowToChange.isVisible = data.isVisible;
            }
            return state;
        }
        case LIST_VISIBLE_FLOWS_ACTION.success:
            return action.data as IUserFlowDto[];
        default:
            return state;
    }
};
