import { push } from 'connected-react-router';
import useThunkDispatch from 'hooks/useThunkDispatch';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router';

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface URLSearchParams {
        toggle: (this: URLSearchParams, paramKey: string, paramValue: string) => void;
    }
}

URLSearchParams.prototype.toggle = function(paramKey: string, paramValue: string) {
    const params = this.getAll(paramKey);
    if (params.length === 1 && params.includes(paramValue)) {
        this.delete(paramKey);
    } else if (params.includes(paramValue)) {
        this.delete(paramKey);
        params.filter((x) => x !== paramValue).forEach((x) => this.append(paramKey, x));
    } else {
        this.append(paramKey, paramValue);
    }
};

const useQuery = () => {
    const dispatch = useThunkDispatch();
    const { search } = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(search), [search]);
    const setQueryParams = useCallback(
        (newQueryParams: URLSearchParams) =>
            dispatch(
                push({
                    search: newQueryParams.toString(),
                })
            ),
        [dispatch]
    );
    return [queryParams, setQueryParams] as const;
};
export default useQuery;
