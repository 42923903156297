/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
export const ARCHIVED_TEAM_STATE = 'Archived';
export const teamStateNames = [
    'Prospect',
    'Proposal',
    null,
    null,
    null,
    'Inactive',
    'RH Launch',
    'Team Admin Launch',
    'Member Launch',
    'Ready for Member Launch',
    null,
    null,
    null,
    null,
    null,
    'Customer',
    null,
    'Renewing',
    ARCHIVED_TEAM_STATE,
];

export const teamStateIds = {
    Unknown: -1,
    Prospect: 1,
    Proposal: 2,
    Inactive: 6,
    RhLaunch: 7,
    TeamAdminLaunch: 8,
    MemberLaunch: 9,
    ReadyForMemberLaunch: 10,
    Lead: 13,
    Analysis: 14,
    Handoff: 15,
    Customer: 16,
    Offboarding: 17,
    Renewing: 18,
    ...[null, ...teamStateNames],
};
