import { ADD_PATHWAY_ACTION } from 'actions/pathwayBlueprint/addPathway';
import { ASSIGN_USERS_ACTION } from 'actions/pathwayBlueprint/assignUsers';
import { CALCULATE_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/calculatePathwayBlueprint';
import { CALCULATE_PATHWAY_USER_ACTION } from 'actions/pathwayBlueprint/calculatePathwayUser';
import { CLEAR_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/clearPathwayBlueprint';
import { DELETE_PATHWAY_ACTION } from 'actions/pathwayBlueprint/deletePathway';
import { EDIT_PATHWAY_ACTION } from 'actions/pathwayBlueprint/editPathway';
import { EDIT_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/editPathwayBlueprint';
import { GET_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/getPathwayBlueprint';
import { GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION } from 'actions/pathwayBlueprint/getPathwayBlueprintDataForUser';
import { GET_PATHWAY_USER_ACTION } from 'actions/pathwayBlueprint/getPathwayUser';
import { PATCH_PATHWAY_BLUEPRINT_COSTS_ACTION } from 'actions/pathwayBlueprint/patchPathwayBlueprintCosts';
import { PATCH_PATHWAY_USER_ACTION } from 'actions/pathwayBlueprint/patchPathwayUser';
import { SET_CUSTOM_PROJECTED_REIMBURSEMENT_ACTION } from 'actions/pathwayBlueprint/setCustomProjectedReimbursement';
import { SET_CUSTOM_PROJECTED_WAGE_UP_ACTION } from 'actions/pathwayBlueprint/setCustomProjectedWageUp';
import {
    IPathway,
    IPathwayBlueprint,
    IPathwayUser,
    IUserPathwayBlueprintDto,
    Pathway,
    PathwayUser,
    UserPathwayBlueprintDto,
} from 'api/generated/models';

const initialState: IPathwayBlueprint = ({} as unknown) as IPathwayBlueprint;

type IPathwayBlueprintDataType =
    | IPathway
    | IPathwayBlueprint
    | IPathwayUser
    | IUserPathwayBlueprintDto;

export const pathwayBlueprint = (
    state = initialState,
    action: {
        data?: IPathwayBlueprintDataType;
        type?: string;
    } = {}
): IPathwayBlueprint => {
    switch (action.type) {
        case ASSIGN_USERS_ACTION.success:
        case GET_PATHWAY_BLUEPRINT_ACTION.success:
        case EDIT_PATHWAY_BLUEPRINT_ACTION.success:
        case DELETE_PATHWAY_ACTION.success:
        case EDIT_PATHWAY_ACTION.success:
        case CALCULATE_PATHWAY_BLUEPRINT_ACTION.success:
        case PATCH_PATHWAY_USER_ACTION.success:
        case PATCH_PATHWAY_BLUEPRINT_COSTS_ACTION.success:
        case SET_CUSTOM_PROJECTED_REIMBURSEMENT_ACTION.success:
        case SET_CUSTOM_PROJECTED_WAGE_UP_ACTION.success:
            return action.data as IPathwayBlueprint;
        case ADD_PATHWAY_ACTION.success:
            return {
                ...state,
                pathways: [action.data as Pathway, ...(state.pathways ?? [])],
            };
        case GET_PATHWAY_USER_ACTION.success:
        case CALCULATE_PATHWAY_USER_ACTION.success: {
            const data = action.data as PathwayUser;
            return {
                ...state,
                pathwayUsers: state.pathwayUsers?.map((pathwayUser) =>
                    pathwayUser.id === data.id ? data : pathwayUser
                ),
            };
        }
        case GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION.success: {
            const data = action.data as UserPathwayBlueprintDto;
            if (data?.pathwayBlueprintDto) {
                return ({
                    id: data.pathwayBlueprintDto.id,
                    name: data.pathwayBlueprintDto.name,
                    year: data.pathwayBlueprintDto.year,
                } as unknown) as IPathwayBlueprint;
            } else {
                return state;
            }
        }
        case CLEAR_PATHWAY_BLUEPRINT_ACTION:
            return initialState;
    }
    return state;
};
