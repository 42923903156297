import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { AppStore } from 'reducers/appReducer';
import { getUserProps } from 'selectors';

const useUserProps = (userId?: string) => {
    const params = useParams<{ userId?: string }>();
    return useSelector((state: AppStore) => getUserProps(state, userId ? { userId } : params));
};

export default useUserProps;
