import moment from 'moment';
import { AppStore } from 'reducers/appReducer';
import { IUserProps } from 'selectors';
import { isAdvisorSelector, isRhSelector } from 'selectors/role';

const INCOME_STRUCTURE_UPDATE_RELEASE_DATE = '05/18/2021';
const getIsBeforeIncomeUpdates = (date?: string) =>
    moment(INCOME_STRUCTURE_UPDATE_RELEASE_DATE).isSameOrAfter(date?.toMomentDate());

export const getShouldShowMayIncludeAdditionalIncomeMessage = (
    state: AppStore,
    { memberVerifiedInfo }: IUserProps
) => {
    const isRhOrAdvisor = isRhSelector(state) || isAdvisorSelector(state);
    return isRhOrAdvisor && getIsBeforeIncomeUpdates(memberVerifiedInfo?.incomeVerifiedDate);
};
