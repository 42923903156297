import { IAction } from 'actions/commonAction';
import { GET_SELECTED_PLANS_FOR_REVIEW_ACTION } from 'actions/selectedPlan/getSelectedPlansForReview';
import { ISelectedPlansForReviewDto } from 'api/generated/models';

const initialState: ISelectedPlansForReviewDto = {};

export const selectedPlansForReview = (
    state = initialState,
    action: IAction<ISelectedPlansForReviewDto>
): ISelectedPlansForReviewDto => {
    if (action.type === GET_SELECTED_PLANS_FOR_REVIEW_ACTION.success) {
        return action.data;
    }
    return state;
};
