import { CLEAR_USER_BUDGET } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { EDIT_USER_BUDGET_ACTION } from 'actions/user/addOrEditUserBudget';
import { GET_CURRENT_USER_BUDGET_ACTION } from 'actions/user/getCurrentUserBudget';
import { GET_HOUSEHOLD_ACTION } from 'actions/user/getHousehold';
import { GET_USER_BUDGET_ACTION } from 'actions/user/getUserBudget';
import { IHousehold, IUserBudget } from 'api/generated/models';

const initialState: IUserBudget = ({} as unknown) as IUserBudget;

export const userBudget = (
    state = initialState,
    action: IAction<IHousehold | IUserBudget>
): IUserBudget => {
    switch (action.type) {
        case GET_HOUSEHOLD_ACTION.success:
            return {
                ...((action.data as IHousehold).userBudget as IUserBudget),
            };
        case GET_USER_BUDGET_ACTION.success:
        case GET_CURRENT_USER_BUDGET_ACTION.success:
        case EDIT_USER_BUDGET_ACTION.success:
            return {
                ...state,
                ...action.data,
            };
        case CLEAR_USER_BUDGET:
            return initialState;
        default:
            return state;
    }
};
