import { CLEAR_USERS } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { APPLY_CALCULATION_FOR_PATHWAY_BLUEPRINT } from 'actions/pathwayBlueprint/applyCalculationForPathwayBlueprint';
import { SET_ACTIVE_WAGEUP_NOTE_ACTION } from 'actions/pathwayBlueprint/setActiveWageUpNote';
import { SEND_SURVEY_EMAIL_ACTION } from 'actions/survey/sendSurveyEmail';
import { SEND_SURVEY_EMAILS_TO_TEAM_ACTION } from 'actions/survey/sendSurveyEmailsToTeam';
import { COMPLETE_All_ICHRA_ENROLLMENT_TASKS_ACTION } from 'actions/taskFlows/completeAllIchraEnrollmentTasks';
import { BULK_SEND_RENEWAL_INVITES_ACTION } from 'actions/team/bulkSendRenewalInvites';
import { BULK_SET_SURVEY_TYPE_TO_SEND_ACTION } from 'actions/team/bulkSetSurveyTypeToSend';
import { UPDATE_WAIVING_TEAM_MEMBERS_ACTION } from 'actions/team/updateWaivingMembers';
import { CREATE_RESET_REQUIRED_LOGIN_ACTION } from 'actions/user/CreateResetRequiredLogin';
import { COMPLETE_ENROLLMENT_ACTION } from 'actions/user/completeEnrollment';
import { DELETE_USER_ACTION } from 'actions/user/deleteUser';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import { GET_USER_PROFILES_ACTION } from 'actions/user/getUserProfiles';
import { PATCH_MEMBER_QUOTE_INFO_ACTION } from 'actions/user/patchMemberQuoteInfo';
import { PATCH_USER_ACTION } from 'actions/user/patchUser';
import { PATCH_YEARLY_USER_INFO_ACTION } from 'actions/user/patchYearlyUserInfo';
import { SEND_RENEWAL_EMAIL_ACTION } from 'actions/user/sendRenewalEmail';
import { SET_ENROLLMENT_STATUS_TO_ENROLLED_ACTION } from 'actions/user/setEnrollmentStatusToEnrolled';
import { SET_USER_STATUS_ACTION } from 'actions/user/setUserStatus';
import {
    ActiveWageUp,
    IActiveWageUp,
    IMemberQuoteInfo,
    IMemberVerifiedInfo,
    IUser,
    IUserProfile,
    IYearlyUserInfoDto,
    User,
    YearlyUserInfoDto,
} from 'api/generated/models';
import sortBy from 'lodash/sortBy';

const initialState: IUserProfile[] = [];

type IUserProfilesDataType =
    | IActiveWageUp
    | IActiveWageUp[]
    | IMemberQuoteInfo
    | IMemberVerifiedInfo
    | IUser
    | IUser[]
    | IUserProfile
    | IUserProfile[]
    | IYearlyUserInfoDto
    | IYearlyUserInfoDto[];

export const userProfiles = (
    state = initialState,
    action: IAction<IUserProfilesDataType> & { isSingleUser?: boolean }
): IUserProfile[] => {
    switch (action.type) {
        case PATCH_USER_ACTION.success:
        case SEND_SURVEY_EMAIL_ACTION.success:
        case SET_USER_STATUS_ACTION.success: {
            const data = action.data as IUser;
            const userToChange = state.find((x) => x.user?.userId === data.userId);
            if (userToChange?.user) {
                userToChange.user.status = data.status;
                userToChange.user.surveyLastSentDate = data.surveyLastSentDate;
            }
            return state;
        }
        case SEND_RENEWAL_EMAIL_ACTION.success:
        case GET_USER_PROFILE_ACTION.success: {
            const data = action.data as IUserProfile;
            const userToChange = state.find((x) => x.user?.userId === data.user?.userId);
            if (userToChange) {
                userToChange.user = data.user;
                userToChange.yearlyUserInfo = data.yearlyUserInfo;
            }
            return state;
        }
        case COMPLETE_ENROLLMENT_ACTION.success: {
            const data = action.data as IUserProfile;
            const index = state.indexOf(
                state.find((x) => x.user?.userId === data.user?.userId) as IUserProfile
            );
            const newState = state.filter((x) => x.user?.userId !== data.user?.userId);
            newState.splice(index, 0, data);
            return newState;
        }
        case UPDATE_WAIVING_TEAM_MEMBERS_ACTION.success: {
            const updatedProfiles = state.map((up) => {
                const updatedProfile = (action.data as IUserProfile[]).find(
                    (p) => p.user?.userId === up.user?.userId
                );
                if (updatedProfile) {
                    up = updatedProfile;
                }
                return up;
            });
            return [...sortBy(updatedProfiles, (userProfile) => userProfile.user?.lastName)];
        }
        case BULK_SEND_RENEWAL_INVITES_ACTION.success: {
            const updatedProfiles = state.map((up) => {
                const updatedYearlyUserInfo = (action.data as YearlyUserInfoDto[]).find(
                    (yui) => up.user?.userId === yui.userId
                );
                if (updatedYearlyUserInfo) {
                    up.yearlyUserInfo = updatedYearlyUserInfo;
                }
                return up;
            });
            return [...sortBy(updatedProfiles, (userProfile) => userProfile.user?.lastName)];
        }
        case BULK_SET_SURVEY_TYPE_TO_SEND_ACTION.success:
        case SEND_SURVEY_EMAILS_TO_TEAM_ACTION.success: {
            const updatedProfiles = state.map((x) => {
                const updatedUser = (action.data as IUser[]).find(
                    (u) => x.user?.userId === u.userId
                );
                if (updatedUser && x.user) {
                    x.user.surveyTypeToSend = updatedUser.surveyTypeToSend;
                    x.user.surveyLastSentDate = updatedUser.surveyLastSentDate;
                }
                return x;
            });
            return [...sortBy(updatedProfiles, (userProfile) => userProfile.user?.lastName)];
        }
        case CREATE_RESET_REQUIRED_LOGIN_ACTION.success: {
            const data = action.data as IUserProfile;
            const userToChange = state.find((x) => x.user?.userId === data.user?.userId);
            if (userToChange) {
                userToChange.user = data.user;
                userToChange.loginCreatedDate = data.loginCreatedDate;
            }
            return state;
        }
        case GET_USER_PROFILES_ACTION.success:
            return [
                ...sortBy(
                    action.data as IUserProfile[],
                    (userProfile) => userProfile.user?.lastName
                ),
            ];
        case CLEAR_USERS:
            return [];
        case DELETE_USER_ACTION.success:
            return state.filter(
                (userProfile) => userProfile.user?.userId !== (action.data as IUser).userId
            );
        case APPLY_CALCULATION_FOR_PATHWAY_BLUEPRINT.success:
            return state.map(
                (userProfile): IUserProfile => {
                    const newActiveWageUp = (action.data as ActiveWageUp[]).find(
                        (activeWageUp) => userProfile.user?.userId === activeWageUp.userId
                    );
                    const activeWageUps = newActiveWageUp ? [newActiveWageUp] : [];

                    if (newActiveWageUp || !action.isSingleUser) {
                        return {
                            ...userProfile,
                            user: new User({
                                ...userProfile.user,
                                activeWageUps,
                            } as IUser),
                        };
                    }
                    return userProfile;
                }
            );
        case SET_ACTIVE_WAGEUP_NOTE_ACTION.success: {
            const data = action.data as ActiveWageUp;
            return state.map(
                (userProfile): IUserProfile =>
                    userProfile?.activeWageUp?.id === data.id
                        ? {
                              ...userProfile,
                              user: new User({
                                  ...userProfile.user,
                                  activeWageUps: userProfile.user?.activeWageUps?.map(
                                      (activeWageUp) =>
                                          activeWageUp.id === data.id ? data : activeWageUp
                                  ) ?? [data],
                              } as IUser),
                          }
                        : userProfile
            );
        }
        case SET_ENROLLMENT_STATUS_TO_ENROLLED_ACTION.success:
        case COMPLETE_All_ICHRA_ENROLLMENT_TASKS_ACTION.success: {
            const data = action.data as YearlyUserInfoDto;
            const userToChange = state.find((x) => x.user?.userId === data.userId);
            if (userToChange) {
                userToChange.yearlyUserInfo = data;
            }
            return state;
        }
        case PATCH_YEARLY_USER_INFO_ACTION.success: {
            const data = action.data as YearlyUserInfoDto;
            const userToChange = state.find((x) => x.user?.userId === data.userId);
            if (userToChange) {
                userToChange.yearlyUserInfo = data;
                userToChange.majorMedicalCoverageLevel = data.majorMedicalCoverageLevel;
            }
            return state;
        }
        case PATCH_MEMBER_QUOTE_INFO_ACTION.success: {
            const data = action.data as IMemberQuoteInfo;
            const userToChange = state.find((x) => x.user?.userId === data.userId);
            if (userToChange) {
                (userToChange.memberQuoteInfo as IMemberQuoteInfo).previousEmployeePremium =
                    data.previousEmployeePremium;
                (userToChange.memberQuoteInfo as IMemberQuoteInfo).previousEmployerPremium =
                    data.previousEmployerPremium;
                (userToChange.memberQuoteInfo as IMemberQuoteInfo).previousRenewalEmployeePremium =
                    data.previousRenewalEmployeePremium;
                (userToChange.memberQuoteInfo as IMemberQuoteInfo).previousRenewalEmployerPremium =
                    data.previousRenewalEmployerPremium;
            }
            return state;
        }
        default:
            return state;
    }
};
