import { CLEAR_TEAM_USER_ROLES } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_TEAM_USER_ROLES_ACTION } from 'actions/user/getTeamUserRoles';
import { IUserRoleStatus } from 'api/generated/models';

const initialState: IUserRoleStatus[] = [];

export const teamUserRoles = (
    state = initialState,
    action: IAction<IUserRoleStatus[]>
): IUserRoleStatus[] => {
    switch (action.type) {
        case GET_TEAM_USER_ROLES_ACTION.success:
            return [...action.data];
        case CLEAR_TEAM_USER_ROLES:
            return [];
        default:
            return state;
    }
};
