import { CLEAR_MULTI_SELECT } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { SEARCH_DRUGS_ACTION } from 'actions/marketplacePlan/searchDrugs';
import { IDrug } from 'api/generated/models';

const initialState: IDrug[] = [];

export const drugs = (state = initialState, action: IAction<IDrug[]>): IDrug[] => {
    switch (action.type) {
        case SEARCH_DRUGS_ACTION.success:
            return [...action.data];
        case CLEAR_MULTI_SELECT:
            return [];
        default:
            return state;
    }
};
