import { CLEAR_BLUEPRINT_MIGRATIONS } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_BLUEPRINT_MIGRATIONS_ACTION } from 'actions/pathwayBlueprint/getPlansForNextYearAsync';
import { IBlueprintMigrationDto } from 'api/generated/models';

const initialState: IBlueprintMigrationDto = {
    ichraClassMigrations: [],
    pathwayBenchmarkMigrations: [],
    pathwayRecommendedMigrations: [],
};

export const blueprintMigrations = (
    state = initialState,
    action: IAction<IBlueprintMigrationDto>
): IBlueprintMigrationDto => {
    if (action.type === GET_BLUEPRINT_MIGRATIONS_ACTION.success) {
        return action.data;
    } else if (action.type === CLEAR_BLUEPRINT_MIGRATIONS) {
        return initialState;
    }

    return state;
};
