import { IAction } from 'actions/commonAction';
import { GET_SELECTED_PLANS_FOR_RENEWAL_ACTION } from 'actions/selectedPlan/getSelectedPlansForRenewal';
import { ISelectedPlansForRenewalDto } from 'api/generated/models';

const initialState: ISelectedPlansForRenewalDto = ({} as unknown) as ISelectedPlansForRenewalDto;

export const selectedPlansForRenewal = (
    state = initialState,
    action: IAction<ISelectedPlansForRenewalDto>
): ISelectedPlansForRenewalDto => {
    if (action.type === GET_SELECTED_PLANS_FOR_RENEWAL_ACTION.success) {
        return action.data;
    }
    return state;
};
