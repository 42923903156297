import { IAction } from 'actions/commonAction';
import { EDIT_TEAM_RELATIONSHIP_ACTION } from 'actions/team/editTeamRelationship';
import { GET_TEAM_RELATIONSHIPS_ACTION } from 'actions/team/getTeamRelationships';
import { IUserTeamRelationship } from 'api/generated/models';

const initialState: IUserTeamRelationship[] = [];

export const teamRelationships = (
    state = initialState,
    action: IAction<IUserTeamRelationship[]>
): IUserTeamRelationship[] => {
    switch (action.type) {
        case GET_TEAM_RELATIONSHIPS_ACTION.success:
        case EDIT_TEAM_RELATIONSHIP_ACTION.success:
            return [...action.data];
        default:
            return state;
    }
};
