import { IAction } from 'actions/commonAction';
import { CLEAR_PAGED_USERS } from 'actions/clear';
import { OPEN_UP_SHOPPING_ACTION } from 'actions/taskFlows/openUpShopping';
import { IPaginatedResultsDtoOfUserProfile, UserProfile } from 'api/generated/models';
import { LIST_PAGED_USER_PROFILES_ACTION } from 'actions/user/listPagedUserProfiles';

const initialState: IPaginatedResultsDtoOfUserProfile = ({} as unknown) as IPaginatedResultsDtoOfUserProfile;

export const pagedUserProfiles = (
    state = initialState,
    action: IAction<IPaginatedResultsDtoOfUserProfile>
): IPaginatedResultsDtoOfUserProfile => {
    if (action.type === LIST_PAGED_USER_PROFILES_ACTION.success) {
        return action.data;
    } else if (action.type === CLEAR_PAGED_USERS) {
        return initialState;
    } else if (action.type === OPEN_UP_SHOPPING_ACTION.success) {
        const { userId } = (action as unknown) as { userId: string };
        state.results =
            state.results?.map((up) => {
                if (up.user?.userId === userId) {
                    return new UserProfile({ ...up, canOpenUpShopping: false });
                }
                return up;
            }) ?? [];
        return state;
    }
    return state;
};
