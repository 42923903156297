import { HouseholdMemberTypes } from 'api/generated/enums';
import {
    IBasicHouseholdMemberDto,
    IHouseholdMember,
    IHouseholdMemberDto,
    ISelectedPlan,
    ISurveyHouseholdMemberDto,
    IUser,
} from 'api/generated/models';
import filter from 'lodash/filter';
import find from 'lodash/find';
import isUndefined from 'lodash/isUndefined';
import some from 'lodash/some';
import sumBy from 'lodash/sumBy';
import without from 'lodash/without';
import { hasValue, isTrue } from 'utilities';

const isDependent = (member: IHouseholdMemberDto | ISurveyHouseholdMemberDto) =>
    member.householdMemberTypeId === HouseholdMemberTypes.Dependent;

export const isEmployedAndNotSameEmployer = (
    h: Pick<IHouseholdMemberDto, 'hasSameEmployer' | 'isEmployed'>
) => isTrue(h.isEmployed) && !isTrue(h.hasSameEmployer);

type IHouseholdMemberMethod = <T>(
    householdMembers: T[] | undefined,
    filter: (householdMember: T) => boolean
) => T | T[] | boolean;
type IAgeThreshold = (age: number | undefined) => boolean;

const methodMemberType = <T extends IHouseholdMemberDto | ISurveyHouseholdMemberDto>(
    method: IHouseholdMemberMethod,
    householdMembers: T[] | undefined,
    type: HouseholdMemberTypes,
    ageThreshold?: IAgeThreshold | undefined
) =>
    method(
        householdMembers,
        (x) =>
            x.householdMemberTypeId === type &&
            (ageThreshold === undefined || ageThreshold(x.dateOfBirth?.getAge()))
    );

const methodMemberAllIchraChildren = <T extends IHouseholdMemberDto | ISurveyHouseholdMemberDto>(
    method: IHouseholdMemberMethod,
    householdMembers: T[] | undefined,
    ageThreshold?: IAgeThreshold | undefined
) =>
    method(
        householdMembers,
        (x) =>
            x.householdMemberTypeId !== HouseholdMemberTypes.Spouse &&
            (ageThreshold === undefined || ageThreshold(x.dateOfBirth?.getAge()))
    );
const methodMemberAllDependentChildren = <
    T extends IHouseholdMemberDto | ISurveyHouseholdMemberDto
>(
    method: IHouseholdMemberMethod,
    householdMembers: T[] | undefined,
    ageThreshold?: IAgeThreshold | undefined
) =>
    method(
        householdMembers,
        (x) =>
            x.householdMemberTypeId !== HouseholdMemberTypes.Spouse &&
            x.householdMemberTypeId !== HouseholdMemberTypes.Unclaimed &&
            (ageThreshold === undefined || ageThreshold(x.dateOfBirth?.getAge()))
    );

export const hasMemberType = <T extends IHouseholdMemberDto | ISurveyHouseholdMemberDto>(
    householdMembers: T[] | undefined,
    type: HouseholdMemberTypes,
    ageThreshold?: IAgeThreshold | undefined
) => methodMemberType(some, householdMembers, type, ageThreshold) as boolean;

export const findMemberType = <T extends IHouseholdMemberDto | ISurveyHouseholdMemberDto>(
    householdMembers: T[] | undefined,
    type: HouseholdMemberTypes,
    ageThreshold?: IAgeThreshold | undefined
) => methodMemberType(find, householdMembers, type, ageThreshold) as T;

export const getAllMembersOfType = <T extends IHouseholdMemberDto | ISurveyHouseholdMemberDto>(
    householdMembers: T[] | undefined,
    type: HouseholdMemberTypes,
    ageThreshold?: IAgeThreshold | undefined
) => methodMemberType(filter, householdMembers, type, ageThreshold) as T[];

export const getAllIchraChildren = <T extends IHouseholdMemberDto | ISurveyHouseholdMemberDto>(
    householdMembers: T[] | undefined,
    ageThreshold?: IAgeThreshold | undefined
) => methodMemberAllIchraChildren(filter, householdMembers, ageThreshold) as T[];

export const getAllDependentChildren = <T extends IHouseholdMemberDto | ISurveyHouseholdMemberDto>(
    householdMembers: T[] | undefined,
    ageThreshold?: IAgeThreshold | undefined
) => methodMemberAllDependentChildren(filter, householdMembers, ageThreshold) as T[];

const toNumberOrDefault = (n: unknown) => parseFloat(n as string) || 0;

export const calculateHouseholdIncome = (
    data:
        | { additionalIncome?: unknown; deductions?: unknown; employeeIncome?: unknown }
        | undefined,
    householdMembers: Partial<IHouseholdMemberDto>[] | undefined,
    singleFilerStandardDeduction: number
) => {
    const employeeIncome = toNumberOrDefault(data?.employeeIncome);
    const additionalIncome = toNumberOrDefault(data?.additionalIncome);
    const deductions = toNumberOrDefault(data?.deductions);
    const spouseMember = householdMembers?.filter(
        (x) => x.householdMemberTypeId === HouseholdMemberTypes.Spouse
    );
    const claimedMembersOverDeduction = householdMembers?.filter(
        (x) =>
            x.householdMemberTypeId === HouseholdMemberTypes.Dependent &&
            toNumberOrDefault(x.income) > singleFilerStandardDeduction
    );
    const totalHouseholdIncome =
        employeeIncome +
        sumBy(spouseMember, (h) => toNumberOrDefault(h.income)) +
        sumBy(claimedMembersOverDeduction, (h) => toNumberOrDefault(h.income)) +
        additionalIncome -
        deductions;

    return Math.max(totalHouseholdIncome, 0);
};

export const getMemberNamesString = (
    isPrimaryIncluded: boolean,
    user: IUser | undefined,
    householdMembers: Pick<IHouseholdMember, 'firstName'>[] | undefined
) => {
    const members = householdMembers?.map((x) => x.firstName) ?? [];
    if (isPrimaryIncluded) {
        members.unshift(user?.firstName);
    }
    return members.join(', ');
};

type IFirstNameFields = 'firstName' | 'preferredName';

export const getDisplayFirstName = (user: Pick<IUser, IFirstNameFields> | undefined): string => {
    if (hasValue(user) && hasValue(user?.firstName)) {
        return user?.firstName;
    }
    return '';
};

export const getDisplayFirstNameWithAge = (
    householdMember: Partial<Pick<IHouseholdMember, 'dateOfBirth' | 'firstName'>> | undefined,
    targetDate?: string
): string =>
    `${getDisplayFirstName(householdMember)} (${householdMember?.dateOfBirth?.getAge(targetDate)})`;

type IMember =
    | Pick<IHouseholdMember, IFirstNameFields | 'householdMemberId'>
    | Pick<IUser, IFirstNameFields | 'userId'>;
export const hasMatchingId = (id: string, x: IMember) =>
    (x as IHouseholdMember)?.householdMemberId === id ||
    (isUndefined((x as IHouseholdMember).householdMemberId) && (x as IUser)?.userId === id);

export const getMemberNames = (entityIds: string[] | undefined, members: IMember[]) =>
    entityIds?.map((id) => getDisplayFirstName(members.find((x) => hasMatchingId(id, x))));

export const getHouseholdMemberBelowStandardDeductionMessage = (
    members: (IHouseholdMember | ISurveyHouseholdMemberDto)[],
    year: number | undefined
) => {
    const names = members.map((m) => getDisplayFirstName(m)).joinSerialComma();
    const multiple = members.length > 1;
    const income = multiple ? 'incomes are' : 'income is';
    const pronoun = multiple ? "they're" : "it's";
    return `${names}'s ${income} not included in the total household income because ${pronoun} below the standard deduction for ${year}.`;
};

export const isDependentAndOverStandardDeduction = (
    member: IHouseholdMemberDto | ISurveyHouseholdMemberDto,
    income: number | undefined,
    singleFilerStandardDeduction: number
) => isDependent(member) && (income ?? NaN) > singleFilerStandardDeduction;

export const isDependentAndUnderOrEqualToStandardDeduction = (
    member: IHouseholdMemberDto | ISurveyHouseholdMemberDto,
    income: number | undefined,
    singleFilerStandardDeduction: number
) => isDependent(member) && (income ?? NaN) <= singleFilerStandardDeduction;

export const getCoverageForString = (
    { householdMembersCovered, isPrimaryCovered }: ISelectedPlan,
    user: IUser | undefined,
    householdMembers: IBasicHouseholdMemberDto[]
) => {
    const primaryName = householdMembersCovered?.length ? `${user?.firstName}, ` : user?.firstName;
    const userFirstName = isPrimaryCovered ? primaryName : '';
    const memberString = without(
        householdMembersCovered?.map(
            (householdMemberId) =>
                householdMembers.find((x) => x.householdMemberId === householdMemberId)?.firstName
        ),
        undefined
    ).join(', ');
    return userFirstName + memberString;
};
