import { CLEAR_MULTI_SELECT } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { SEARCH_IDEON_PROVIDERS_ACTION } from 'actions/providerSearch/searchIdeonProviders';
import { ProviderSource } from 'api/generated/enums';
import { IIdeonProvider } from 'api/generated/models';

export type IIdeonProviderWithSource = Partial<IIdeonProvider & { providerSource: ProviderSource }>;

const initialState: IIdeonProviderWithSource[] = [];

export const ideonProviders = (
    state = initialState,
    action: IAction<Partial<IIdeonProvider>[]>
): IIdeonProviderWithSource[] => {
    switch (action.type) {
        case SEARCH_IDEON_PROVIDERS_ACTION.success: {
            const modifiedData = action.data.map((provider) => {
                const ideonProvider = provider as IIdeonProviderWithSource;
                ideonProvider.providerSource = ProviderSource.Ideon;
                return ideonProvider;
            });
            return [...modifiedData];
        }
        case CLEAR_MULTI_SELECT:
            return [];
        default:
            return state;
    }
};
