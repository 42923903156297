import { IAction } from 'actions/commonAction';
import { GET_ICHRA_OFFER_ACTION } from 'actions/taskFlows/getIchraOffer';
import { IIchraOfferDto, IchraOfferDto } from 'api/generated/models';

const initialState: IchraOfferDto = ({} as unknown) as IchraOfferDto;

export const ichraOffer = (
    state = initialState,
    action: IAction<IIchraOfferDto>
): IIchraOfferDto => {
    if (action.type === GET_ICHRA_OFFER_ACTION.success) {
        return action.data as IIchraOfferDto;
    } else {
        return state;
    }
};
