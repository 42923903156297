import { CLEAR_PERMISSIONS } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_PERMISSIONS_ACTION } from 'actions/permission/getPermissions';
import { IPermission } from 'api/generated/models';

const initialState: IPermission[] = [];

export const permissions = (
    state = initialState,
    action: IAction<IPermission[]>
): IPermission[] => {
    switch (action.type) {
        case GET_PERMISSIONS_ACTION.success:
            return [...action.data];
        case CLEAR_PERMISSIONS:
            return [];
        default:
            return state;
    }
};
