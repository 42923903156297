import { IAction } from 'actions/commonAction';
import { GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION } from 'actions/pathwayBlueprint/getPathwayBlueprintDataForUser';
import { IUserPathwayBlueprintDto } from 'api/generated/models';

const initialState: IUserPathwayBlueprintDto = {};

export const userPathwayBlueprint = (
    state = initialState,
    action: IAction<IUserPathwayBlueprintDto>
): IUserPathwayBlueprintDto => {
    if (action.type === GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION.success) {
        return action.data;
    }
    return state;
};
