import { startCase } from 'lodash';
import isArray from 'lodash/isArray';
import { Moment } from 'moment';
import { hasValue } from 'utilities';
import { isValidDate, toMomentOrUndefined } from 'utilities/moment';
import {
    addMethod,
    AnySchema,
    array,
    BaseSchema,
    InferType,
    mixed,
    string,
    StringSchema,
    TestContext,
    TestFunction,
    ValidationError,
} from 'yup';
import ArraySchema, { OptionalArraySchema } from 'yup/lib/array';
import { ConditionConfig } from 'yup/lib/Condition';
import Lazy from 'yup/lib/Lazy';
import {
    AnyObject,
    Maybe,
    Message,
    MessageParams,
    Optionals,
    SchemaLike,
    ValidateOptions,
} from 'yup/lib/types';
import { Asserts, TypedSchema, TypeOf } from 'yup/lib/util/types';

export const onChange = <T>(
    setValue: React.Dispatch<React.SetStateAction<T>> | ((value: T) => void)
) => ({
    target: { value },
}: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) =>
    setValue((value as unknown) as T);

export const onEnterPress = <T>(doSomething: (e: React.KeyboardEvent<T>) => void) => (
    e: React.KeyboardEvent<T>
) => {
    if (e.key === 'Enter') {
        doSomething(e);
    }
};

export const onAllPactSafe = (
    setValue: React.Dispatch<React.SetStateAction<boolean>> | ((value: boolean) => void)
) => (name: 'checked' | 'invalid' | 'unchecked' | 'valid') => {
    switch (name) {
        case 'valid':
        case 'checked':
            return setValue(true);
        case 'invalid':
        case 'unchecked':
            return setValue(false);
        default:
            return () => undefined;
    }
};

export const invalidCurrencyValue = (value: unknown) => !hasValue(value) || (value as number) < 0;

export type IFormErrors<T extends TypedSchema> = Partial<
    Record<keyof InferType<T>, string[] | undefined>
> | null;
export const formatErrors = <T extends TypedSchema>(error: ValidationError) =>
    error.inner.reduce<IFormErrors<T> | null>((finalErrors, innerError): IFormErrors<T> => {
        const errors: IFormErrors<T> = finalErrors ?? (({} as unknown) as IFormErrors<T>);
        if (errors !== null) {
            errors[innerError.path as keyof InferType<T>] = [
                ...(finalErrors?.[innerError.path as keyof InferType<T>] ?? []),
                innerError.message,
            ] as string[];
        }
        return errors;
    }, null);

export type IValidateData<T extends BaseSchema> = Record<string, unknown> & TypeOf<T>;
/**
 * Will call `yupSchema.validate` given data and options with `abortEarly = false` so as to check all errors
 * @param schema `yup` schema
 * @param data Data that should conform to given schema
 * @param options Options for `yupSchema.validate`
 * @returns Promise with output of validation
 */
export const validate = async <T extends BaseSchema>(
    schema: T,
    data: IValidateData<T>,
    options?: ValidateOptions
): Promise<IValidateData<T>> => schema.validate(data, { abortEarly: false, ...options });

type IYupArrayUnique = <T extends AnySchema>({
    message,
    transform,
}: {
    message?: string;
    transform?: (entry: TypeOf<T>[]) => unknown[];
}) => ArraySchema<T>;
type IYupArrayUniqueOf = <T extends AnySchema>(
    message: string,
    listToCompare: unknown[],
    transform?: (entries: TypeOf<T>[]) => unknown[]
) => ArraySchema<T>;
declare module 'yup' {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions, @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any
    interface BaseSchema<TCast = any, TContext = AnyObject, TOutput = any> {
        isOneOrMoreFiles: <T extends BaseSchema>(this: T) => T;
        isRequiredWhen: <T extends BaseSchema>(
            this: T,
            arg: ConditionConfig<T> | boolean,
            msg?: string
        ) => T;
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface ArraySchema<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        T extends AnySchema | Lazy<any, any>,
        C extends AnyObject = AnyObject,
        TIn extends Maybe<TypeOf<T>[]> = TypeOf<T>[] | undefined,
        TOut extends Maybe<Asserts<T>[]> = Asserts<T>[] | Optionals<TIn>
    > extends BaseSchema<TIn, C, TOut> {
        /**
         * Uses `yup.test` to test uniqueness
         * @param message Error message to show when not unique
         * @param transform Optional - Function that allows changing what `yup.array` contains
         */
        unique: IYupArrayUnique;
        /**
         * Uses `yup.test` to test uniqueness given the `listToCompare`
         * Specifically will check `yup.array.includes` any values from `listToCompare`
         * @param message Error message to show when not unique
         * @param listToCompare List of values that should not be in `yup.array`
         * @param transform Optional - Function that allows changing what `yup.array` contains
         */
        uniqueOf: IYupArrayUniqueOf;
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface StringSchema<
        TType extends Maybe<string> = string | undefined,
        TContext extends AnyObject = AnyObject,
        TOut extends TType = TType
    > extends BaseSchema<TType, TContext, TOut> {
        /**
         * Checks if date string is after date string value of given field
         * @note If either date string evaluates to `undefined` from `toMomentOrUndefined` then there will be no error
         * @uses {@link toMomentOrUndefined}
         * @example
         * string().isValidDate().isAfter('someDate');
         * string().isAfter({
         *     message: (messageParams, baseMessage) => `${baseMessage} some date`, // Or 'Some error message'
         *     momentValue: someMomentValue,
         * });
         */
        isAfter: (arg: IDateOptions | string) => StringSchema;
        /**
         * Checks if date string is before date string value of given field
         * @note If either date string evaluates to `undefined` from `toMomentOrUndefined` then there will be no error
         * @uses {@link toMomentOrUndefined}
         * @example
         * string().isValidDate().isBefore('someDate');
         * string().isBefore({
         *     message: (messageParams, baseMessage) => `${baseMessage} some date`, // Or 'Some error message'
         *     momentValue: someMomentValue,
         * });
         */
        isBefore: (arg: IDateOptions | string) => StringSchema;
        /**
         * Checks if date string is same or after date string value of given field
         * @note If either date string evaluates to `undefined` from `toMomentOrUndefined` then there will be no error
         * @uses {@link toMomentOrUndefined}
         */
        isSameOrAfter: (arg: IDateOptions | string) => StringSchema;
        /**
         * Checks if date string is same or before date string value of given field
         * @note If either date string evaluates to `undefined` from `toMomentOrUndefined` then there will be no error
         * @uses {@link toMomentOrUndefined}
         */
        isSameOrBefore: (arg: IDateOptions | string) => StringSchema;
        /**
         * Check if date string is valid using `isValidDate` moment utility
         * @param isRequired Optional `(default = true)` - Indicates whether or not the field is required
         * @uses {@link isValidDate}
         */
        isValidDate: (isRequired?: boolean) => StringSchema;
        /**
         * Check if string is a valid phone number
         * @returns Raw phone number with non-numeric characters removed
         * @param isRequired Optional `(default = true)` - Indicates whether or not the field is required
         * @uses {@link string.removeNonNumericCharacters}
         */
        isValidPhoneNumber: (isRequired?: boolean) => StringSchema;
    }
}

const anySchemaIsRequiredWhen = function<T extends BaseSchema>(
    this: T,
    arg: ConditionConfig<SchemaLike> | boolean,
    msg?: string
): T {
    let whenOptions: ConditionConfig<T> = {
        is: () => arg,
        then: (_schema: T) => _schema.required(msg),
    };
    if (typeof arg !== 'boolean') {
        whenOptions = { ...whenOptions, ...arg };
    }
    return this.when(whenOptions);
};
// Currently this is set for string, but should be global; global does not work
// Open issue on github: https://github.com/jquense/yup/issues/1560#issuecomment-1016464129
addMethod(mixed, 'isRequiredWhen', anySchemaIsRequiredWhen);

const getUniqueResult = (
    message: string,
    context: TestContext<AnyObject>,
    duplicateValueIndices: number[]
) => {
    if (duplicateValueIndices.length) {
        const errors = duplicateValueIndices.map((i) =>
            context.createError({ message, path: `${context.path}[${i}]` })
        );
        return new ValidationError(errors);
    }
    return true;
};

const unique: IYupArrayUnique = function<T extends AnySchema>(
    this: OptionalArraySchema<T>,
    {
        message = 'Must be unique',
        transform,
    }: { message?: string; transform?: (entry: TypeOf<T>[]) => unknown[] }
) {
    return this.test('unique', message, (list, context) => {
        const _list = list ? transform?.(list) : list;
        const duplicates: Record<number | string | symbol, number[]> = {};
        const duplicateValueIndices: number[] = [];
        _list?.forEach((item, index) => {
            const key = item as number | string | symbol;
            if (duplicates[key]?.length || _list.lastIndexOf(key) !== index) {
                duplicates[key] = [index, ...(duplicates[key] ?? [])];
                duplicateValueIndices.push(index);
            }
        });
        return getUniqueResult(message, context, duplicateValueIndices);
    });
};
addMethod(array, 'unique', unique);

const uniqueOf: IYupArrayUniqueOf = function<T extends AnySchema>(
    this: OptionalArraySchema<T>,
    message: string,
    listToCompare: unknown[],
    transform?: ((entries: TypeOf<T>[]) => unknown[]) | undefined
) {
    return this.test('uniqueOf', message, (list, context) => {
        const _list = list ? transform?.(list) : list;
        const duplicateValueIndices =
            _list?.reduce<number[]>((acc, x, index) => {
                if (listToCompare.includes(x)) {
                    acc.push(index);
                }
                return acc;
            }, []) ?? [];
        return getUniqueResult(message, context, duplicateValueIndices);
    });
};
addMethod(array, 'uniqueOf', uniqueOf);

type IMessagePredicate = (params: MessageParams, baseMessage: string) => unknown;
type IDateOptions = {
    field?: string;
    message: IMessagePredicate | string;
    momentValue: Moment | undefined;
};
const getFieldAndMessage = (arg: IDateOptions | string, messageConditionText: string) => {
    let field: Moment | string | undefined;
    let message: Message;
    const baseMessage = (x: MessageParams) =>
        `${startCase(x.path)} must be ${messageConditionText}`;
    if (typeof arg === 'string') {
        field = arg;
        message = (x) => `${baseMessage(x)} ${startCase(arg)}`;
    } else {
        field = arg?.momentValue;
        message =
            typeof arg?.message === 'function'
                ? (x) => (arg?.message as IMessagePredicate)(x, baseMessage(x))
                : arg.message;
    }
    return { field, message };
};
const isBeforeOrAfter = (
    field: Moment | string | undefined,
    predicate: (left: Moment, right: Moment) => boolean
): TestFunction<string | undefined, AnyObject> => (value, context) => {
    const left = toMomentOrUndefined(value);
    let right: Moment | undefined = undefined;
    if (typeof field === 'string') {
        right = toMomentOrUndefined(context.parent[field]);
        if (!hasValue(right)) {
            const x = context.options.context?.[field];
            right = toMomentOrUndefined(x);
        }
    } else {
        right = field;
    }
    if (left === undefined || right === undefined) {
        return true;
    }
    return predicate(left, right);
};
const stringIsAfter = function(this: StringSchema, arg: IDateOptions | string) {
    const { message, field } = getFieldAndMessage(arg, 'after');
    return this.test(
        'isAfter',
        message,
        isBeforeOrAfter(field, (l, r) => l > r)
    );
};
addMethod(string, 'isAfter', stringIsAfter);

const stringIsBefore = function(this: StringSchema, arg: IDateOptions | string) {
    const { message, field } = getFieldAndMessage(arg, 'before');
    return this.test(
        'isBefore',
        message,
        isBeforeOrAfter(field, (l, r) => l < r)
    );
};
addMethod(string, 'isBefore', stringIsBefore);

const stringIsSameOrBefore = function(this: StringSchema, arg: IDateOptions | string) {
    const { message, field } = getFieldAndMessage(arg, 'the same or before');
    return this.test(
        'isSameOrBefore',
        message,
        isBeforeOrAfter(field, (l, r) => l <= r)
    );
};
addMethod(string, 'isSameOrBefore', stringIsSameOrBefore);

const stringIsSameOrAfter = function(this: StringSchema, arg: IDateOptions | string) {
    const { message, field } = getFieldAndMessage(arg, 'the same or after');
    return this.test(
        'isSameOrAfter',
        message,
        isBeforeOrAfter(field, (l, r) => l >= r)
    );
};
addMethod(string, 'isSameOrAfter', stringIsSameOrAfter);

const stringIsValidDate = function(this: StringSchema, isRequired = true) {
    return this.test('isValidDate', 'Invalid Date', (value) => isValidDate(value, isRequired));
};
addMethod(string, 'isValidDate', stringIsValidDate);

const invalidPhoneValue = (value: string | undefined, isRequired = true) => {
    const strippedValue = value?.removeNonNumericCharacters();
    const isInvalid = strippedValue?.length !== 10;
    return isRequired
        ? isInvalid || !hasValue(strippedValue)
        : isInvalid && hasValue(strippedValue);
};
const stringIsValidPhoneNumber = function(this: StringSchema, isRequired = true) {
    return this.transform((x) => x?.removeNonNumericCharacters()).test(
        'isValidPhoneNumber',
        'Invalid Phone Number',
        (value) => !invalidPhoneValue(value, isRequired)
    );
};
addMethod(string, 'isValidPhoneNumber', stringIsValidPhoneNumber);

const mixedIsOneOrMoreFiles = function(this: AnySchema) {
    return this.test('isOneOrMoreFiles', 'A file is required', (value) => {
        if (isArray(value)) {
            return value.length > 0 && value[0] instanceof File;
        }

        return value instanceof File;
    });
};
addMethod(mixed, 'isOneOrMoreFiles', mixedIsOneOrMoreFiles);
