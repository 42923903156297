/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
const HTTP_STATUS = {
    OK: 200,
    CREATED: 201,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    CONFLICT: 409,
    CLOUDFLARE_TIMEOUT: 524,
} as const;
export default HTTP_STATUS;
