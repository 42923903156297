import {GET_HRS_BENEFITS_ELIGIBILITY_CLASS_CODES} from 'actions/hrsIntegrations/getHrsBenefitsEligibilityClassCodes';
import {SET_HRS_BENEFITS_ELIGIBILITY_CLASS_CODES} from 'actions/hrsIntegrations/setHrsBenefitsEligibilityClassCodes';
import {IAction} from 'actions/commonAction';

const initialState: string[] = [];

export const hrsBenefitsEligibilityClassCodes = (
    state = initialState,
    action: IAction<string[]>
): string[] => {
    if (
        action.type === GET_HRS_BENEFITS_ELIGIBILITY_CLASS_CODES.success ||
        action.type === SET_HRS_BENEFITS_ELIGIBILITY_CLASS_CODES.success
    ) {
        return action.data;
    }
    return state;
};
