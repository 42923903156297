import React from 'react';
import { hot } from 'react-hot-loader';
import RouteData, { getPathData, IRouteData } from 'routers/RouteData';
import { PAGE_DATA } from 'routers/routes';

const AuthorizedRoutes = () => {
    const data = PAGE_DATA.reduce<IRouteData[]>((accumulatedRouteData, routeData) => {
        if (routeData.paths) {
            accumulatedRouteData = accumulatedRouteData.concat(
                getPathData(routeData, routeData.paths)
            );
        }
        if (routeData.teamManagementPaths) {
            accumulatedRouteData = accumulatedRouteData.concat(
                getPathData(routeData, routeData.teamManagementPaths, true)
            );
        }
        return accumulatedRouteData;
    }, []);
    return <RouteData data={data} />;
};

export default hot(module)(AuthorizedRoutes);
