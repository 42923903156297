import { IAction } from 'actions/commonAction';
import { GET_RH_PASSWORD_OPTIONS } from 'actions/security/getRhPasswordOptions';
import { IRhPasswordOptions } from 'api/generated/models';

const initialState: IRhPasswordOptions = {
    lockoutAttemptThreshold: 0,
    lockoutDurationMinutes: 0,
    requireDigit: false,
    requiredLength: 0,
    requiredUniqueChars: 0,
    requireLowercase: false,
    requireNonAlphanumeric: false,
    requireUppercase: false,
};

export const rhPasswordOptions = (
    state = initialState,
    action: IAction<IRhPasswordOptions>
): IRhPasswordOptions => {
    let newState = state;
    if (action.type === GET_RH_PASSWORD_OPTIONS.success) {
        newState = action.data;
    }
    return newState;
};
