import { IAction } from 'actions/commonAction';
import { CLEAR_USER_PROFILES_METADATA } from 'actions/clear';
import { IUserProfilesMetadataDto } from 'api/generated/models';
import { GET_USER_PROFILES_METADATA_ACTION } from 'actions/user/getUserProfilesMetadata';

const initialState: IUserProfilesMetadataDto = ({} as unknown) as IUserProfilesMetadataDto;

export const userProfilesMetadata = (
    state = initialState,
    action: IAction<IUserProfilesMetadataDto>
): IUserProfilesMetadataDto => {
    if (action.type === GET_USER_PROFILES_METADATA_ACTION.success) {
        return action.data;
    } else if (action.type === CLEAR_USER_PROFILES_METADATA) {
        return initialState;
    }
    return state;
};
