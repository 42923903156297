import { CLEAR_USER_PROFILE } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { APPLY_CALCULATION_FOR_PATHWAY_BLUEPRINT } from 'actions/pathwayBlueprint/applyCalculationForPathwayBlueprint';
import { ADD_USER_PROFILE_PICTURE_ACTION } from 'actions/rhFile/addUserProfilePicture';
import { SEND_SURVEY_EMAIL_ACTION } from 'actions/survey/sendSurveyEmail';
import { COMPLETE_All_ICHRA_ENROLLMENT_TASKS_ACTION } from 'actions/taskFlows/completeAllIchraEnrollmentTasks';
import { OPEN_UP_SHOPPING_ACTION } from 'actions/taskFlows/openUpShopping';
import { SELECT_ANCILLARY_BENEFITS_ACTION } from 'actions/teamBenefit/selectAncillaryBenefits';
import {
    EDIT_USER_HOME_ADDRESS_ACTION,
    EDIT_USER_MAILING_ADDRESS_ACTION,
} from 'actions/user/addOrEditUserAddress';
import { COMPLETE_ENROLLMENT_ACTION } from 'actions/user/completeEnrollment';
import { DELETE_USER_MAILING_ADDRESS_ACTION } from 'actions/user/deleteUserMailingAddress';
import { REMOVE_USER_RELATIONSHIP_ACTION } from 'actions/user/deleteUserRelationship';
import { GET_HOUSEHOLD_ACTION } from 'actions/user/getHousehold';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import { GET_YEARLY_USER_INFO_ACTION } from 'actions/user/getYearlyUserInfo';
import { PATCH_MEMBER_QUOTE_INFO_ACTION } from 'actions/user/patchMemberQuoteInfo';
import { PATCH_MEMBER_VERIFIED_INFO_ACTION } from 'actions/user/patchMemberVerifiedInfo';
import { PATCH_USER_ACTION } from 'actions/user/patchUser';
import { PATCH_YEARLY_USER_INFO_ACTION } from 'actions/user/patchYearlyUserInfo';
import { RESET_ACCOUNT_LOCKOUT_ACTION } from 'actions/user/resetAccountLockout';
import { SEND_RENEWAL_EMAIL_ACTION } from 'actions/user/sendRenewalEmail';
import { SET_ENROLLMENT_STATUS_TO_ENROLLED_ACTION } from 'actions/user/setEnrollmentStatusToEnrolled';
import {
    ADD_USER_RELATIONSHIP_ACTION,
    EDIT_USER_RELATIONSHIP_ACTION,
} from 'actions/user/setUserRelationship';
import { SET_USER_STATUS_ACTION } from 'actions/user/setUserStatus';
import { UPDATE_INCOME_VERIFIED_DATE_ACTION } from 'actions/user/updateIncomeVerifiedDate';
import { UserInfoStatus } from 'api/generated/enums';
import {
    ActiveWageUp,
    Address,
    IActiveWageUp,
    IAddress,
    IHousehold,
    IHouseholdMemberPatchRecalculateDto,
    IMemberQuoteInfo,
    IMemberVerifiedInfo,
    IUser,
    IUserProfile,
    IUserUserRelationship,
    IYearlyUserInfoDto,
    MemberQuoteInfo,
    MemberVerifiedInfo,
    User,
    UserUserRelationship,
    YearlyUserInfoDto,
} from 'api/generated/models';
import { processSsnChange } from 'utilities/userProfile';

const initialState: IUserProfile = ({} as unknown) as IUserProfile;

type IUserProfileDataType =
    | IActiveWageUp
    | IActiveWageUp[]
    | IAddress
    | IHousehold
    | IHouseholdMemberPatchRecalculateDto
    | IMemberQuoteInfo
    | IMemberVerifiedInfo
    | IUser
    | IUserProfile
    | IUserUserRelationship
    | IYearlyUserInfoDto;

export const userProfile = (
    state = initialState,
    action: IAction<IUserProfileDataType> & { isCurrent?: boolean }
): IUserProfile => {
    if (!action.isCurrent) {
        switch (action.type) {
            case UPDATE_INCOME_VERIFIED_DATE_ACTION.success:
                return {
                    ...state,
                    memberVerifiedInfo: new MemberVerifiedInfo({
                        ...(state.memberVerifiedInfo as IMemberVerifiedInfo),
                        incomeVerifiedDate: (action.data as IMemberVerifiedInfo).incomeVerifiedDate,
                    }),
                };
            case GET_HOUSEHOLD_ACTION.success:
                return {
                    ...state,
                    ...(action.data as IHousehold).userProfile,
                };
            case GET_USER_PROFILE_ACTION.success:
            case RESET_ACCOUNT_LOCKOUT_ACTION.success:
            case SEND_RENEWAL_EMAIL_ACTION.success:
            case COMPLETE_ENROLLMENT_ACTION.success: {
                const updatedUserProfile = processSsnChange(action.data as IUserProfile, state);
                return {
                    ...updatedUserProfile,
                };
            }
            case CLEAR_USER_PROFILE:
                return ({} as unknown) as IUserProfile;
            case EDIT_USER_HOME_ADDRESS_ACTION.success:
                return {
                    ...state,
                    address: action.data as Address,
                };
            case EDIT_USER_MAILING_ADDRESS_ACTION.success:
                return {
                    ...state,
                    mailingAddress: action.data as Address,
                };
            case DELETE_USER_MAILING_ADDRESS_ACTION.success:
                return {
                    ...state,
                    mailingAddress: undefined,
                };
            case PATCH_USER_ACTION.success:
            case SET_USER_STATUS_ACTION.success:
                return {
                    ...state,
                    user: new User({
                        ...action.data,
                        userInfoStatus: state.user?.userInfoStatus as UserInfoStatus,
                    } as IUser),
                };
            case PATCH_MEMBER_QUOTE_INFO_ACTION.success:
                return {
                    ...state,
                    memberQuoteInfo: action.data as MemberQuoteInfo,
                };
            case PATCH_MEMBER_VERIFIED_INFO_ACTION.success:
                return {
                    ...state,
                    memberVerifiedInfo: action.data as MemberVerifiedInfo,
                };
            case ADD_USER_PROFILE_PICTURE_ACTION.success:
                return {
                    ...state,
                    user: new User({
                        ...state.user,
                        profilePictureRhFileId: action.data as string,
                    } as IUser),
                };
            case REMOVE_USER_RELATIONSHIP_ACTION.success:
                return {
                    ...state,
                    userUserRelationships: state.userUserRelationships?.filter(
                        (relationship) =>
                            relationship.id !== (action.data as IUserUserRelationship).id
                    ),
                };
            case EDIT_USER_RELATIONSHIP_ACTION.success:
                return {
                    ...state,
                    userUserRelationships: state.userUserRelationships?.map((x) => {
                        const data = action.data as UserUserRelationship;
                        if (x.id === data.id) {
                            return data;
                        }

                        return x;
                    }),
                };
            case ADD_USER_RELATIONSHIP_ACTION.success:
                return {
                    ...state,
                    userUserRelationships: [
                        ...(state.userUserRelationships ?? []),
                        action.data as UserUserRelationship,
                    ],
                };
            case SEND_SURVEY_EMAIL_ACTION.success: {
                const data = action.data as IUser;
                return {
                    ...state,
                    user: new User({
                        ...state.user,
                        surveyLastSentByUser: data.surveyLastSentByUser,
                        surveyLastSentDate: data.surveyLastSentDate,
                    } as IUser),
                };
            }
            case APPLY_CALCULATION_FOR_PATHWAY_BLUEPRINT.success:
                return {
                    ...state,
                    user: new User({
                        ...state.user,
                        activeWageUps: action.data as ActiveWageUp[],
                    } as IUser),
                };
            case PATCH_YEARLY_USER_INFO_ACTION.success:
            case SET_ENROLLMENT_STATUS_TO_ENROLLED_ACTION.success:
            case GET_YEARLY_USER_INFO_ACTION.success:
            case SELECT_ANCILLARY_BENEFITS_ACTION.success:
            case COMPLETE_All_ICHRA_ENROLLMENT_TASKS_ACTION.success:
                return {
                    ...state,
                    yearlyUserInfo: action.data as YearlyUserInfoDto,
                };
            case OPEN_UP_SHOPPING_ACTION.success: {
                const { userId } = (action as unknown) as { userId: string };
                if (userId === state.user?.userId) {
                    return {
                        ...state,
                        canOpenUpShopping: false,
                    };
                }
                return state;
            }
        }
    }
    return state;
};
