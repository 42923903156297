/* eslint-disable @typescript-eslint/consistent-type-definitions */
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
    createTheme,
    PaletteColor,
    PaletteColorOptions,
    responsiveFontSizes,
    ThemeProvider,
} from '@mui/material/styles';
import React, { ReactNode } from 'react';
import { hot } from 'react-hot-loader';

declare module '@mui/material/styles' {
    interface Palette {
        blueGray: PaletteColor;
        dark: PaletteColor;
        muted: PaletteColor;
        neutral: PaletteColor;
        orange: PaletteColor;
        sage: PaletteColor;
        yellow: PaletteColor;
    }
    interface PaletteOptions {
        blueGray: PaletteColorOptions;
        dark: PaletteColorOptions;
        muted: PaletteColorOptions;
        neutral: PaletteColorOptions;
        orange: PaletteColorOptions;
        sage: PaletteColorOptions;
        yellow: PaletteColorOptions;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        neutral: true;
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        dark: true;
    }
}

const boxShadow = '0px 1px 3px rgba(0,0,0,0.1), 0px 6px 12px rgba(0,0,0,0.08)';
const innerShadow =
    '0px -1px 3px rgba(55, 73, 88, 0.035) inset, 0px -6px 12px rgba(55, 73, 88, 0.02) inset';

let theme = createTheme({
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    '::before': {
                        backgroundColor: 'transparent',
                    },
                    color: '#6c757d',
                    padding: 0,
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    padding: 1.5,
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                paper: {
                    padding: 0,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                    paddingBottom: '12px',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    paddingTop: '12px',
                    textTransform: 'none',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    boxShadow: boxShadow + ', ' + innerShadow,
                    marginBottom: '24px',
                    overflow: 'visible', // this is to fix Action Button dropdowns being hidden
                    padding: 0,
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    ':last-child': {
                        paddingBottom: '24px',
                    },
                    padding: '16px',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    padding: 0,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem',
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paperAnchorDockedLeft: {
                    padding: 0,
                },
                paperAnchorLeft: {
                    paddingInline: 0,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: boxShadow + ', ' + innerShadow,
                    color: '#374958',
                    padding: '24px',
                },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    padding: 0,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    whiteSpace: 'pre-wrap',
                },
            },
        },
    },
    palette: {
        blueGray: {
            main: '#606772',
        },
        dark: {
            contrastText: '#f5f5f5',
            main: '#313a46',
        },
        muted: {
            contrastText: '#313a46',
            main: '#98a6ad',
        },
        neutral: {
            contrastText: '#313a46',
            dark: '#cbd8e1',
            main: '#e3eaef',
        },
        orange: {
            contrastText: '#ffffff',
            main: '#E1795A',
        },
        primary: {
            contrastText: '#fff',
            main: '#374958',
        },
        sage: {
            main: '#8EAE9C',
        },
        secondary: {
            contrastText: '#ffffff',
            main: '#5cc2a7',
        },
        text: {
            primary: '#374958',
        },
        yellow: {
            main: '#F6C45D',
        },
    },
    typography: {
        body2: {
            color: '#6c757d',
        },
        fontFamily: 'Nunito',
        h1: {
            fontSize: '2.25rem',
            fontWeight: '600',
            margin: '10px 0',
        },
        h2: {
            fontSize: '1.875rem',
            fontWeight: '600',
            margin: '10px 0',
        },
        h3: {
            fontSize: '1.5rem',
            fontWeight: '600',
            margin: '10px 0',
        },
        h4: {
            fontSize: '1.125rem',
            fontWeight: '600',
            margin: '10px 0',
        },
        h5: {
            fontSize: '0.9375rem',
            fontWeight: '600',
            margin: '10px 0',
        },
        h6: {
            fontSize: '0.75rem',
            fontWeight: '600',
            margin: '10px 0',
        },
    },
});
theme = responsiveFontSizes(theme);

const RhThemeProvider = ({ children }: { children: ReactNode }) => (
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default hot(module)(RhThemeProvider);
