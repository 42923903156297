import { IAction } from 'actions/commonAction';
import { ADD_ENROLLMENT_VERIFICATION_ACTION } from 'actions/enrollmentVerification/addEnrollmentVerification';
import { ADD_ENROLLMENT_VERIFICATION_RH_FILE_ACTION } from 'actions/enrollmentVerification/addEnrollmentVerificationRhFile';
import { DELETE_ENROLLMENT_VERIFICATION_ACTION } from 'actions/enrollmentVerification/deleteEnrollmentVerification';
import { GET_ALL_ENROLLMENT_VERIFICATION_FOR_USER_ACTION } from 'actions/enrollmentVerification/getAllEnrollmentVerificationForUser';
import { PATCH_ENROLLMENT_VERIFICATION_ACTION } from 'actions/enrollmentVerification/patchEnrollmentVerification';
import { UNLINK_ENROLLMENT_VERIFICATION_RH_FILE_ACTION } from 'actions/enrollmentVerification/unlinkEnrollmentVerificationRhFile';
import { RENAME_USER_RH_FILES_ACTION } from 'actions/rhFile/renameUserRhFile';
import { IEnrollmentVerification, IRhFile, RhFile } from 'api/generated/models';

const initialState = [] as IEnrollmentVerification[];

type IEnrollmentVerificationsDataType =
    | IEnrollmentVerification
    | IEnrollmentVerification[]
    | IRhFile;

export const enrollmentVerifications = (
    state = initialState,
    action: IAction<IEnrollmentVerificationsDataType>
): IEnrollmentVerification[] => {
    switch (action.type) {
        case GET_ALL_ENROLLMENT_VERIFICATION_FOR_USER_ACTION.success:
            return [...(action.data as IEnrollmentVerification[])];
        case DELETE_ENROLLMENT_VERIFICATION_ACTION.success:
            return [...state.filter((x) => x.id !== (action.data as IEnrollmentVerification).id)];
        case ADD_ENROLLMENT_VERIFICATION_RH_FILE_ACTION.success:
        case UNLINK_ENROLLMENT_VERIFICATION_RH_FILE_ACTION.success:
        case PATCH_ENROLLMENT_VERIFICATION_ACTION.success: {
            const data = action.data as IEnrollmentVerification;
            return [...state.filter((x) => x.id !== data.id), data];
        }
        case RENAME_USER_RH_FILES_ACTION.success: {
            const data = action.data as RhFile;
            const enrollmentVerification = state.find((x) =>
                x.rhFiles?.some((f) => f.id === data.id)
            );
            if (enrollmentVerification) {
                return [
                    ...state.filter((x) => x.id !== enrollmentVerification.id),
                    {
                        ...enrollmentVerification,
                        rhFiles: [
                            ...(enrollmentVerification.rhFiles ?? []).filter(
                                (x) => x.id !== data.id
                            ),
                            data,
                        ],
                    },
                ];
            }
            return state;
        }
        case ADD_ENROLLMENT_VERIFICATION_ACTION.success:
            return [...state, action.data as IEnrollmentVerification];
        default:
            return state;
    }
};
