import { CLEAR_TEAM } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { ADD_LAUNCH_DETAILS_ACTION } from 'actions/launchDetails/addLaunchDetails';
import { GET_LAUNCH_DETAILS_ACTION } from 'actions/launchDetails/getLaunchDetails';
import { UPDATE_LAUNCH_DETAILS_ACTION } from 'actions/launchDetails/updateLaunchDetails';
import { ILaunchDetails } from 'api/generated/models';

const initialState: ILaunchDetails = ({} as unknown) as ILaunchDetails;

export const launchDetails = (
    state = initialState,
    action: IAction<ILaunchDetails>
): ILaunchDetails => {
    switch (action.type) {
        case GET_LAUNCH_DETAILS_ACTION.success:
        case UPDATE_LAUNCH_DETAILS_ACTION.success:
        case ADD_LAUNCH_DETAILS_ACTION.success:
            return {
                ...action.data,
            };
        case CLEAR_TEAM: {
            return initialState;
        }
        default:
            return state;
    }
};
