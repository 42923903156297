import { CLEAR_ANCILLARY_BENEFITS_ACTION } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { LIST_ANCILLARY_BENEFITS_ACTION } from 'actions/teamBenefit/listAncillaryBenefits';
import { IAncillaryBenefitDto } from 'api/generated/models';

const initialState: IAncillaryBenefitDto[] = [];

export const ancillaryBenefits = (
    state = initialState,
    action: IAction<IAncillaryBenefitDto[]>
): IAncillaryBenefitDto[] => {
    if (action.type === LIST_ANCILLARY_BENEFITS_ACTION.success) {
        return action.data;
    }
    if (action.type === CLEAR_ANCILLARY_BENEFITS_ACTION) {
        return initialState;
    }
    return state;
};
