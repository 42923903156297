import { IAction } from 'actions/commonAction';
import { GET_CURRENT_USER_BUDGETS_FOR_TEAM_ACTION } from 'actions/team/getUserBudgetsForTeam';
import { GET_USER_BUDGET_ACTION } from 'actions/user/getUserBudget';
import { IUserBudget } from 'api/generated/models';

const initialState: IUserBudget[] = [];

export const userBudgets = (
    state = initialState,
    action: IAction<IUserBudget | IUserBudget[]>
): IUserBudget[] => {
    if (action.type === GET_CURRENT_USER_BUDGETS_FOR_TEAM_ACTION.success) {
        return action.data as IUserBudget[];
    } else if (action.type === GET_USER_BUDGET_ACTION.success) {
        const data = action.data as IUserBudget;
        return [...state.filter((x) => x.userBudgetId !== data.userBudgetId), data];
    }
    return state;
};
