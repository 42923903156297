import { INTERMEDIATE_TOKEN_ACTION, TOKEN_ACTION } from 'actions/token/tokenActionUtility';
import { push } from 'connected-react-router';
import decode from 'jwt-decode';
import { LOGIN_PATH } from 'routers/routes';
import { getStore } from 'store/accessor';
import { hasValue, safeGetArray, toBoolean } from 'utilities';

export const INTERMEDIATE_TOKEN_KEY = 'INTERMEDIATE_TOKEN';
const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
const IMPERSONATOR_USER_ACCESS_TOKEN_KEY = 'IMPERSONATOR_USER_ACCESS_TOKEN_KEY';
const REFRESH_TOKEN_KEY = 'REFRESH_TOKEN';
const IMPERSONATOR_USER_REFRESH_TOKEN_KEY = 'IMPERSONATOR_USER_REFRESH_TOKEN_KEY';
const IMPERSONATOR_USER_ORIGINAL_PATH = 'IMPERSONATOR_USER_ORIGINAL_PATH';

type ITokenData = {
    accessToken: string;
    refreshToken: string;
    teamId: string;
    up: string[];
    ur: string[];
    userId: string;
};
function dispatchUpdates(data: ITokenData) {
    const store = getStore();
    store?.dispatch({
        type: TOKEN_ACTION.success,
        ...data,
    });
}
type IIntermediateTokenData = {
    hasMfa: string;
    intermediateToken: string;
    userId?: string;
};
function dispatchIntermediateUpdates(data: IIntermediateTokenData) {
    const store = getStore();
    store?.dispatch({
        type: INTERMEDIATE_TOKEN_ACTION.success,
        ...data,
    });
}

const clearImpersonationTokens = () => {
    sessionStorage.removeItem(IMPERSONATOR_USER_ACCESS_TOKEN_KEY);
    sessionStorage.removeItem(IMPERSONATOR_USER_REFRESH_TOKEN_KEY);
    sessionStorage.removeItem(IMPERSONATOR_USER_ORIGINAL_PATH);
};

export const TokenService = {
    clearIntermediateTokenFromCache(errorMessage?: string) {
        sessionStorage.removeItem(INTERMEDIATE_TOKEN_KEY);
        const data = {
            allowRememberDevice: false,
            hasMfa: 'false',
            intermediateToken: '',
        };

        dispatchIntermediateUpdates(data);

        const { hash, pathname, search } = location;
        const path = pathname + search + hash;
        const store = getStore();
        if (hasValue(errorMessage)) {
            store?.dispatch(push(LOGIN_PATH));
        } else {
            store?.dispatch(push(`${LOGIN_PATH}?redirect=${encodeURIComponent(path)}`));
        }
    },
    clearTokenFromCache() {
        const existingIntermediateToken = sessionStorage.getItem(INTERMEDIATE_TOKEN_KEY);
        sessionStorage.removeItem(ACCESS_TOKEN_KEY);
        sessionStorage.removeItem(REFRESH_TOKEN_KEY);
        clearImpersonationTokens();

        const store = getStore();
        const { hash, pathname, search } = location;
        const path = pathname + search + hash;
        if (!path.includes(LOGIN_PATH) && !hasValue(existingIntermediateToken)) {
            store?.dispatch(push(`${LOGIN_PATH}?redirect=${encodeURIComponent(path)}`));
        }
    },
    decodeAndCacheIntermediateToken(intermediateToken: string | undefined) {
        if (hasValue(intermediateToken)) {
            sessionStorage.setItem(INTERMEDIATE_TOKEN_KEY, intermediateToken);

            const jwt = decode(intermediateToken);
            const data = {
                intermediateToken,
                allowRememberDevice: toBoolean(jwt.allowRememberDevice),
                hasMfa: jwt.hasMfa,
                userId: jwt.sub,
            };
            dispatchIntermediateUpdates(data);
        }
    },
    decodeAndCacheToken(accessToken: string | undefined, refreshToken: string | undefined) {
        if (hasValue(accessToken) && hasValue(refreshToken)) {
            const existingIntermediateToken = sessionStorage.getItem(INTERMEDIATE_TOKEN_KEY);
            sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
            sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
            if (hasValue(existingIntermediateToken)) {
                sessionStorage.removeItem(INTERMEDIATE_TOKEN_KEY);
            }

            const jwt = decode(accessToken);
            const data = {
                accessToken,
                refreshToken,
                teamId: jwt.tid,
                up: safeGetArray(jwt.up) ?? [],
                ur: safeGetArray(jwt.ur) ?? [],
                userId: jwt.sub,
            };
            dispatchUpdates(data);
        }
    },
    getHasExistingToken() {
        const existingAccessToken = sessionStorage.getItem(IMPERSONATOR_USER_ACCESS_TOKEN_KEY);
        const existingRefreshToken = sessionStorage.getItem(IMPERSONATOR_USER_REFRESH_TOKEN_KEY);
        return hasValue(existingAccessToken) && hasValue(existingRefreshToken);
    },

    loadTokenFromCache() {
        const accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);
        const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_KEY);

        if (accessToken && refreshToken) {
            TokenService.decodeAndCacheToken(accessToken, refreshToken);
        }
    },
    restoreExistingToken() {
        const existingAccessToken = sessionStorage.getItem(IMPERSONATOR_USER_ACCESS_TOKEN_KEY);
        const existingRefreshToken = sessionStorage.getItem(IMPERSONATOR_USER_REFRESH_TOKEN_KEY);
        if (existingAccessToken && existingRefreshToken) {
            TokenService.decodeAndCacheToken(existingAccessToken, existingRefreshToken);
        }

        const store = getStore();
        const path = sessionStorage.getItem(IMPERSONATOR_USER_ORIGINAL_PATH);
        if (path) {
            store?.dispatch(push(path));
        }

        clearImpersonationTokens();
    },
    storeExistingTokenAndDecodeAndCacheToken(
        accessToken: string | undefined,
        refreshToken: string | undefined
    ) {
        if (hasValue(accessToken) && hasValue(refreshToken)) {
            const existingAccessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY);
            const existingRefreshToken = sessionStorage.getItem(REFRESH_TOKEN_KEY);
            if (existingAccessToken && existingRefreshToken) {
                sessionStorage.setItem(IMPERSONATOR_USER_ACCESS_TOKEN_KEY, existingAccessToken);
                sessionStorage.setItem(IMPERSONATOR_USER_REFRESH_TOKEN_KEY, existingRefreshToken);
            }

            const { hash, pathname, search } = location;
            const path = pathname + search + hash;
            sessionStorage.setItem(IMPERSONATOR_USER_ORIGINAL_PATH, path);

            TokenService.decodeAndCacheToken(accessToken, refreshToken);
        }
    },
};
