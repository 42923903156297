import { getCalendlyLink, GET_CALENDLY_LINK_ACTION } from 'actions/user/getCalendlyLink';
import useTeamProps from 'hooks/useTeamProps';
import useThunkDispatch from 'hooks/useThunkDispatch';
import useUserProps from 'hooks/useUserProps';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStore } from 'reducers/appReducer';
import { hasApiActivity } from 'selectors/activity';

const PHONE_NUMBER_LENGTH = 10;
const formatPhoneNumber = (phone?: string) => {
    if (phone && phone.length === PHONE_NUMBER_LENGTH) {
        return `+1${phone}`;
    }
    return '';
};

export const useCalendly = (requestCalendlyLink: boolean) => {
    const dispatch = useThunkDispatch();
    const { teamName } = useTeamProps();
    const { user, userId, userProfile } = useUserProps();
    const { calendlyLink, isLoadingCalendlyLink } = useSelector((state: AppStore) => ({
        calendlyLink: state.calendlyLink,
        isLoadingCalendlyLink: hasApiActivity(state, GET_CALENDLY_LINK_ACTION),
    }));

    useEffect(() => {
        if (requestCalendlyLink) {
            dispatch(getCalendlyLink(userId));
        }
    }, [dispatch, requestCalendlyLink, userId]);

    const prefill = {
        customAnswers: {
            a1: teamName,
            a2: formatPhoneNumber(userProfile.user?.phone),
        },
        email: user?.email,
        firstName: user?.firstName,
        lastName: user?.lastName,
    };

    return {
        calendlyLink,
        isLoadingCalendlyLink,
        prefill,
    };
};
