import { IAction } from 'actions/commonAction';
import { GET_ROLES_ACTION } from 'actions/role/getRoles';
import { IRole } from 'api/generated/models';

const initialState: IRole[] = [];

export const roles = (state = initialState, action: IAction<IRole[]>) => {
    switch (action.type) {
        case GET_ROLES_ACTION.success:
            return [...action.data];
        default:
            return state;
    }
};
