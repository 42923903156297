import {MajorMedicalWaitingPeriodIds} from 'api/generated/enums';
import {startCase} from 'lodash';
import {enumToNameValueArray} from 'utilities';

export const majorMedicalWaitingPeriodIdNames = {
    [MajorMedicalWaitingPeriodIds.Immediate]: 'Immediate',
    [MajorMedicalWaitingPeriodIds.ThirtyDays]: '30 Days',
    [MajorMedicalWaitingPeriodIds.SixtyDays]: '60 Days',
    [MajorMedicalWaitingPeriodIds.NinetyDays]: '90 Days',
    [MajorMedicalWaitingPeriodIds.FirstDayOfNextMonth]: 'First Day of Next Month',
    [MajorMedicalWaitingPeriodIds.OffsetThenFirstDayOfNextMonth]: 'Offset Then First of Next Month'
};

export const MAJOR_MEDICAL_WAITING_PERIOD_ITEMS = enumToNameValueArray(
    MajorMedicalWaitingPeriodIds,
    {
        formatName: startCase,
        nameKey: 'text',
        nameMap: {
            FirstDayOfNextMonth:
                majorMedicalWaitingPeriodIdNames[MajorMedicalWaitingPeriodIds.FirstDayOfNextMonth],
            NinetyDays: majorMedicalWaitingPeriodIdNames[MajorMedicalWaitingPeriodIds.NinetyDays],
            OffsetThenFirstDayOfNextMonth: majorMedicalWaitingPeriodIdNames[MajorMedicalWaitingPeriodIds.OffsetThenFirstDayOfNextMonth],
            SixtyDays: majorMedicalWaitingPeriodIdNames[MajorMedicalWaitingPeriodIds.SixtyDays],
            ThirtyDays: majorMedicalWaitingPeriodIdNames[MajorMedicalWaitingPeriodIds.ThirtyDays],
        },
    }
);
