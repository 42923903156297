/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
export const taskItemIds = {
    CompleteTeamProfile: 1,
    GoToTeamMembersPage: 2,
    AddTeamMembers: 3,
    CompleteMemberInfo: 4,
    ScheduleTeamAdminSetupCall: 5,
    CompleteLaunchDetails: 6,
    SetRhLaunchComplete: 7,
    ReviewLaunchTimeline: 8,
    VerifyTeamInfo: 9,
    VerifyTeamRoster: 10,
    ReviewTeamForMemberLaunch: 11,
    TransitionToMemberLaunch: 12,
    ReviewMemberInfo: 13,
    ReviewKickoffDetails: 14,
    ReviewBenefitsInfo: 15,
    AcceptRHServices: 16,
    EnterSocialSecurityNumbers: 17,
};

export const taskListTypes = {
    Team: 'team',
    User: 'user',
};
