import { CLEAR_TEAM_BENEFIT_ACTION } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { ADD_OR_EDIT_TEAM_BENEFIT_TERM_DETAIL_ACTION } from 'actions/teamBenefit/addOrEditTeamBenefitTermDetail';
import { EDIT_TEAM_BENEFIT_ACTION } from 'actions/teamBenefit/editTeamBenefit';
import { GET_TEAM_BENEFIT_ACTION } from 'actions/teamBenefit/getTeamBenefit';
import { ITeamBenefitWithTermDetailsDto } from 'api/generated/models';

const initialState: ITeamBenefitWithTermDetailsDto = ({} as unknown) as ITeamBenefitWithTermDetailsDto;

export const teamBenefit = (
    state = initialState,
    action: IAction<ITeamBenefitWithTermDetailsDto>
): ITeamBenefitWithTermDetailsDto => {
    switch (action.type) {
        case GET_TEAM_BENEFIT_ACTION.success:
        case EDIT_TEAM_BENEFIT_ACTION.success:
        case ADD_OR_EDIT_TEAM_BENEFIT_TERM_DETAIL_ACTION.success:
            return action.data;
        case CLEAR_TEAM_BENEFIT_ACTION:
            return initialState;
        default:
            return state;
    }
};
