import { IAction } from 'actions/commonAction';
import { ADD_TEAM_NOTE_ACTION } from 'actions/teamNote/addTeamNote';
import { DELETE_TEAM_NOTE_ACTION } from 'actions/teamNote/deleteTeamNote';
import { EDIT_TEAM_NOTE_ACTION } from 'actions/teamNote/editTeamNote';
import { GET_TEAM_NOTES_ACTION } from 'actions/teamNote/getTeamNotes';
import { TOGGLE_ARCHIVE_TEAM_NOTE_ACTION } from 'actions/teamNote/toggleArchiveTeamNote';
import { INote } from 'api/generated/models';

const initialState: INote[] = [];

export const teamNotes = (state = initialState, action: IAction<INote | INote[]>): INote[] => {
    switch (action.type) {
        case DELETE_TEAM_NOTE_ACTION.success:
            return [...state.filter((x) => x.noteId !== (action.data as INote).noteId)];
        case TOGGLE_ARCHIVE_TEAM_NOTE_ACTION.success:
        case EDIT_TEAM_NOTE_ACTION.success: {
            const data = action.data as INote;
            const index = state.findIndex((x) => x.noteId === data.noteId);
            state[index] = data;
            return [...state];
        }
        case ADD_TEAM_NOTE_ACTION.success:
            return [action.data as INote, ...state];
        case GET_TEAM_NOTES_ACTION.success:
            return [...(action.data as INote[])];
        default:
            return state;
    }
};
