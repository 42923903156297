import { IAction } from 'actions/commonAction';
import { ADD_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/addPathwayBlueprint';
import { CLEAR_PATHWAY_BLUEPRINTS } from 'actions/pathwayBlueprint/clearPathwayBlueprints';
import { DELETE_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/deletePathwayBlueprint';
import { DUPLICATE_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/duplicatePathwayBlueprint';
import { EDIT_PATHWAY_BLUEPRINT_ACTION } from 'actions/pathwayBlueprint/editPathwayBlueprint';
import { GET_TEAM_PATHWAY_BLUEPRINTS_ACTION } from 'actions/pathwayBlueprint/getTeamPathwayBlueprints';
import { SET_ACTIVE_PATHWAY_BLUEPRINT } from 'actions/pathwayBlueprint/setActivePathwayBlueprint';
import { IPathwayBlueprint, IPathwayBlueprintDto } from 'api/generated/models';

const initialState: IPathwayBlueprintDto[] = [];

export const pathwayBlueprints = (
    state = initialState,
    action: IAction<IPathwayBlueprint | IPathwayBlueprintDto[]>
): IPathwayBlueprintDto[] => {
    switch (action.type) {
        case GET_TEAM_PATHWAY_BLUEPRINTS_ACTION.success:
        case SET_ACTIVE_PATHWAY_BLUEPRINT.success:
            return action.data as IPathwayBlueprintDto[];
        case ADD_PATHWAY_BLUEPRINT_ACTION.success:
        case DUPLICATE_PATHWAY_BLUEPRINT_ACTION.success:
            return [action.data as IPathwayBlueprint, ...state];
        case EDIT_PATHWAY_BLUEPRINT_ACTION.success: {
            const data = action.data as IPathwayBlueprint;
            return state.map((pathwayBlueprint) =>
                pathwayBlueprint.id === data.id ? data : pathwayBlueprint
            );
        }
        case DELETE_PATHWAY_BLUEPRINT_ACTION.success:
            return state.filter((x) => x.id !== (action.data as IPathwayBlueprint).id);
        case CLEAR_PATHWAY_BLUEPRINTS:
            return [];
        default:
            return state;
    }
};
