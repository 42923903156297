import {
    AdpIntegrationStatuses,
    DeductionStatus,
    FinchIntegrationStatuses,
    HrsIntegrationProviders,
    PaylocityIntegrationStatuses,
    TeamStateIds,
    UserInfoStatus,
    UserStatus,
} from 'api/generated/enums';
import {
    IActiveWageUp,
    IAddress,
    IMemberQuoteInfo,
    IMemberVerifiedInfo,
    ITeam,
    ITeamProfile,
    IUser,
    IUserProfile,
    IUserUserRelationship,
    YearlyUserInfoDto,
} from 'api/generated/models';
import { teamStateIds } from 'constants/teamStateIds';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import some from 'lodash/some';
import { AppStore } from 'reducers/appReducer';
import { hasValue } from 'utilities/index';

export type ITeamProps = {
    accountId: string | undefined;
    activeDate: string | undefined;
    adpIntegrationStatus: AdpIntegrationStatuses;
    advisementTeamId: string | undefined;
    allowRecurringExpensesByDefault: boolean | undefined;
    calendlyAdvisementLink?: string;
    createdBy: string | undefined;
    deductionDeadlineDay: number | null;
    enrollmentDeadline?: string;
    fein: string | undefined;
    finchIntegrationStatus: FinchIntegrationStatuses;
    finchPostTaxDeductionStatus: DeductionStatus;
    finchPreTaxDeductionStatus: DeductionStatus;
    hasTeam: boolean;
    hasTeamIdUrlParam: boolean;
    hbaLink?: string;
    hrsIntegrationProvider: HrsIntegrationProviders;
    inactiveDate?: string;
    includeFaithBasedQuestionInSurvey?: boolean;
    isAdvisor: boolean;
    isCustomer: boolean;
    isHrsIntegrationConnected: boolean;
    isSameTeam: boolean;
    isTeamArchived: boolean | undefined;
    legalName: string | undefined;
    memberAllowShoppingDefault?: boolean;
    originalStartDate?: string;
    paylocityIntegrationStatus: PaylocityIntegrationStatuses;
    payrollReportSharingEnabled: boolean | undefined;
    primaryPhone: string | undefined;
    sbeEnrollmentCalendlyLink?: string;
    secondaryPhone: string | undefined;
    surveyYear?: number;
    team?: ITeam;
    teamAddress?: IAddress;
    teamHasPaymentsSetup: boolean | undefined;
    teamId: string;
    teamName: string | undefined;
    teamProfile: ITeamProfile;
    teamStateId: TeamStateIds;
    useIchraLaunchFlow?: boolean;
    usePremiumTaxCreditsDefault?: boolean;
    useReimbursementProgramManagement: boolean | undefined;
    website: string | undefined;
};

/**
 * @deprecated Use `useTeamProps` hook instead of this method
 */
export const getTeamProps = (state: AppStore, params: { teamId?: string }) => {
    const obj: ITeamProps = {
        accountId: '',
        activeDate: '',
        adpIntegrationStatus: AdpIntegrationStatuses.NotConfigured,
        advisementTeamId: '',
        allowRecurringExpensesByDefault: false,
        createdBy: state.teamProfile.createdBy,
        deductionDeadlineDay: null,
        fein: '',
        finchIntegrationStatus: FinchIntegrationStatuses.NotConfigured,
        finchPostTaxDeductionStatus: DeductionStatus.NotConfigured,
        finchPreTaxDeductionStatus: DeductionStatus.NotConfigured,
        hasTeam: false,
        hasTeamIdUrlParam: hasValue(params.teamId),
        hrsIntegrationProvider: HrsIntegrationProviders.NotConfigured,
        includeFaithBasedQuestionInSurvey: true,
        isAdvisor: false,
        isCustomer: false,
        isHrsIntegrationConnected: false,
        isSameTeam: false,
        isTeamArchived: false,
        legalName: '',
        paylocityIntegrationStatus: PaylocityIntegrationStatuses.NotConfigured,
        payrollReportSharingEnabled: false,
        primaryPhone: '',
        secondaryPhone: '',
        teamHasPaymentsSetup: false,
        teamId: '',
        teamName: '',
        teamProfile: (undefined as unknown) as ITeamProfile,
        teamStateId: teamStateIds.Unknown as TeamStateIds,
        useIchraLaunchFlow: false,
        useReimbursementProgramManagement: false,
        website: '',
    };
    if (obj.hasTeamIdUrlParam) {
        obj.teamId = params.teamId?.toLowerCase() as string;
        obj.teamProfile = state.teamProfile;
    } else {
        obj.teamId = state.login.teamId?.toLowerCase() as string;
        obj.teamProfile = state.current.teamProfile;
    }
    obj.accountId = obj.teamProfile.team?.accountId;
    obj.activeDate = obj.teamProfile.team?.activeDate;
    obj.adpIntegrationStatus =
        obj.teamProfile?.team?.adpIntegrationStatus ?? AdpIntegrationStatuses.NotConfigured;
    obj.advisementTeamId = obj.teamProfile.team?.advisementTeamId;
    obj.allowRecurringExpensesByDefault = obj.teamProfile.team?.allowRecurringExpensesByDefault;
    obj.calendlyAdvisementLink = obj.teamProfile.team?.calendlyAdvisementLink;
    obj.deductionDeadlineDay = obj.team?.deductionDeadlineDay ?? null;
    obj.enrollmentDeadline = obj.teamProfile?.team?.enrollmentDeadline;
    obj.fein = obj.teamProfile.team?.fein;
    obj.finchIntegrationStatus =
        obj.teamProfile?.team?.finchIntegrationStatus ?? FinchIntegrationStatuses.NotConfigured;
    obj.finchPostTaxDeductionStatus =
        obj.teamProfile?.team?.finchPostTaxDeductionStatus ?? DeductionStatus.NotConfigured;
    obj.finchPreTaxDeductionStatus =
        obj.teamProfile?.team?.finchPreTaxDeductionStatus ?? DeductionStatus.NotConfigured;
    obj.hasTeam = !isUndefined(obj.teamProfile?.team);
    obj.hbaLink = obj.teamProfile.team?.hbaLink;
    obj.hrsIntegrationProvider =
        obj.teamProfile?.team?.hrsIntegrationProvider ?? HrsIntegrationProviders.NotConfigured;
    obj.inactiveDate = obj.teamProfile.team?.inactiveDate;
    obj.includeFaithBasedQuestionInSurvey =
        obj.teamProfile?.team?.includeFaithBasedQuestionInSurvey;
    obj.isAdvisor = obj.teamProfile.team?.isAdvisor ?? false;
    obj.isCustomer = obj.teamProfile.team?.isCustomer ?? false;
    obj.isHrsIntegrationConnected = obj.teamProfile?.team?.isHrsIntegrationConnected ?? false;
    obj.isSameTeam = obj.teamId === state.login.teamId;
    obj.isTeamArchived = obj.teamProfile.team?.isArchived;
    obj.legalName = obj.teamProfile.team?.legalName;
    obj.memberAllowShoppingDefault = obj.teamProfile.team?.memberAllowShoppingDefault;
    obj.originalStartDate = obj.teamProfile.team?.originalStartDate;
    obj.payrollReportSharingEnabled = obj.teamProfile.team?.payrollReportSharingEnabled;
    obj.useIchraLaunchFlow = obj.teamProfile.team?.useIchraLaunchFlow;
    obj.useReimbursementProgramManagement = obj.teamProfile.team?.useReimbursementProgramManagement;
    obj.primaryPhone = obj.teamProfile.team?.primaryPhoneNumber;
    obj.paylocityIntegrationStatus =
        obj.teamProfile?.team?.paylocityIntegrationStatus ??
        PaylocityIntegrationStatuses.NotConfigured;
    obj.sbeEnrollmentCalendlyLink = obj.teamProfile.team?.sbeEnrollmentCalendlyLink;
    obj.secondaryPhone = obj.teamProfile.team?.secondaryPhoneNumber;
    obj.surveyYear = obj.teamProfile.team?.surveyYear;
    obj.team = obj.teamProfile.team;
    obj.teamAddress = obj.teamProfile.address;
    obj.teamHasPaymentsSetup = obj.teamProfile.team?.hasPaymentsSetup;
    obj.teamName = obj.teamProfile.team?.name;
    obj.teamStateId = obj.teamProfile.team?.teamStateId ?? teamStateIds.Unknown;
    obj.usePremiumTaxCreditsDefault = obj.teamProfile.team?.usePremiumTaxCreditsDefault;
    obj.website = obj.teamProfile.team?.website;
    return obj;
};

/**
 * @deprecated Use `useUserProps` hook instead of this method
 */
export const getUserProps = (state: AppStore, params: { userId?: string }) => {
    const obj = {
        activeWageUp: undefined as IActiveWageUp | undefined,
        address: undefined as IAddress | undefined,
        canOpenUpShopping: false,
        currentUserId: (undefined as unknown) as string,
        hasMemberQuoteInfo: undefined as boolean | undefined,
        hasMemberVerifiedInfo: undefined as boolean | undefined,
        hasOnlyBasicInfo: undefined as boolean | undefined,
        hasUser: undefined as boolean | undefined,
        hasUserIdUrlParam: hasValue(params.userId),
        isCurrent: true,
        isInIchraPathway: undefined as boolean | undefined,
        isUserInactive: false,
        mailingAddress: undefined as IAddress | undefined,
        memberQuoteInfo: undefined as IMemberQuoteInfo | undefined,
        memberVerifiedInfo: undefined as IMemberVerifiedInfo | undefined,
        user: undefined as IUser | undefined,
        userHasPaymentsSetup: undefined as boolean | undefined,
        userId: (undefined as unknown) as string,
        userProfile: (undefined as unknown) as IUserProfile,
        userRelationships: undefined as IUserUserRelationship[] | undefined,
        year: undefined as number | undefined,
        yearlyUserInfo: undefined as YearlyUserInfoDto | undefined,
    };
    obj.currentUserId = state.login.userId as string;
    obj.isCurrent = !obj.hasUserIdUrlParam;
    if (obj.hasUserIdUrlParam) {
        obj.userId = params.userId?.toLowerCase() as string;
        obj.userProfile = state.userProfile;
    } else {
        obj.userId = state.login.userId?.toLowerCase() as string;
        obj.userProfile = state.current.userProfile;
    }
    obj.activeWageUp = obj?.userProfile?.activeWageUp;
    obj.canOpenUpShopping = obj?.userProfile.canOpenUpShopping ?? false;
    obj.isInIchraPathway = obj?.activeWageUp?.wasSetFromIchraPathway ?? false;
    obj.user = obj.userProfile.user;
    obj.hasUser = !isEmpty(obj.user);
    obj.address = obj.userProfile.address;
    obj.hasMemberQuoteInfo = !isEmpty(obj.userProfile?.memberQuoteInfo);
    obj.hasMemberVerifiedInfo = !isEmpty(obj.userProfile?.memberVerifiedInfo);
    obj.hasOnlyBasicInfo = obj.user?.userInfoStatus === UserInfoStatus.Basic;
    obj.isUserInactive = obj.userProfile.user?.status === UserStatus.Inactive;
    obj.mailingAddress = obj.userProfile.mailingAddress;
    obj.memberQuoteInfo = obj.userProfile.memberQuoteInfo;
    obj.memberVerifiedInfo = obj.userProfile.memberVerifiedInfo;
    obj.userHasPaymentsSetup = obj.userProfile.user?.hasPaymentsSetup;
    obj.userRelationships = obj.userProfile.userUserRelationships;
    obj.year = obj.userProfile.year;
    obj.yearlyUserInfo = obj.userProfile.yearlyUserInfo;
    return obj;
};
export type IUserProps = ReturnType<typeof getUserProps>;

export const hasSomePermissions = (state: AppStore, ...permissions: string[]) =>
    some(permissions, (x) => state.login.up.includes(x));
