import { IAction } from 'actions/commonAction';
import { GET_TEAM_REPRESENTATIVE_OPTIONS_ACTION } from 'actions/user/getTeamRepresentativeOptions';
import { IRepresentativeOption } from 'api/generated/models';

const initialState: IRepresentativeOption[] = [];

export const teamRepresentativeOptions = (
    state = initialState,
    action: IAction<IRepresentativeOption[]>
): IRepresentativeOption[] => {
    if (action.type === GET_TEAM_REPRESENTATIVE_OPTIONS_ACTION.success) {
        return [...action.data];
    }
    return state;
};
