import { GET_MONTH_AND_YEAR_OF_MOST_RECENT_PAYROLL_REPORT_ACTION } from 'actions/payroll/getMonthAndYearOfMostRecentPayrollReport';
import { IYearMonthDto } from 'api/generated/models';
import basicReducer from 'reducers/basicReducer';

const initialState: IYearMonthDto = ({} as unknown) as IYearMonthDto;

export const payrollReportYearMonth = basicReducer(
    initialState,
    GET_MONTH_AND_YEAR_OF_MOST_RECENT_PAYROLL_REPORT_ACTION.success
);
