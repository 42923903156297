export const CENTER_CONTENT = 'justify-content-center text-center';
export const ALIGN_ITEMS_START = 'align-items-start';
export const CENTER_AND_ALIGN = [CENTER_CONTENT, ALIGN_ITEMS_START];
export const MARGIN_LEFT_2 = 'ml-2';
export const ADJUSTMENT_WIDTH = 120;
export const BORDER_LEFT = 'border-left';
export const BORDER_RIGHT = 'border-right';
export const CENTER_AND_OVERFLOW_AND_ALIGN = [...CENTER_AND_ALIGN, 'overflow-visible'];
export const BORDER_X = [BORDER_LEFT, BORDER_RIGHT];

export const BODY_FONT_COLOR = '#6c757d';
export const HEADER_FONT_COLOR = '#374958';
