import { CLEAR_SUBMITTED_EXPENSES } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { EDIT_SUBMITTED_EXPENSE_ACTION } from 'actions/submittedExpenses/editSubmittedExpense';
import { LIST_SUBMITTED_EXPENSES_ACTION } from 'actions/submittedExpenses/listSubmittedExpenses';
import {
    BasicSubmittedExpenseDto,
    IPaginatedResultsDtoOfBasicSubmittedExpenseDto,
} from 'api/generated/models';
import { RESUBMIT_SUBMITTED_EXPENSE_ACTION } from 'actions/submittedExpenses/resubmitSubmittedExpense';

const initialState: IPaginatedResultsDtoOfBasicSubmittedExpenseDto = ({} as unknown) as IPaginatedResultsDtoOfBasicSubmittedExpenseDto;

export const submittedExpenses = (
    state = initialState,
    action: IAction<BasicSubmittedExpenseDto | IPaginatedResultsDtoOfBasicSubmittedExpenseDto>
): IPaginatedResultsDtoOfBasicSubmittedExpenseDto => {
    switch (action.type) {
        case LIST_SUBMITTED_EXPENSES_ACTION.success:
            return action.data as IPaginatedResultsDtoOfBasicSubmittedExpenseDto;
        case RESUBMIT_SUBMITTED_EXPENSE_ACTION.success:
        case EDIT_SUBMITTED_EXPENSE_ACTION.success: {
            const data = action.data as BasicSubmittedExpenseDto;
            return {
                ...state,
                results: state.results?.map((subExp) =>
                    subExp.globalId === data.globalId ? data : subExp
                ),
            };
        }
        case CLEAR_SUBMITTED_EXPENSES:
            return initialState;
        default:
            return state;
    }
};
