import useQuery from 'hooks/useQuery';
import { useState } from 'react';
import { hasValue } from 'utilities';

const useModalState = <T>(urlQueryParam?: { key: string; selectedItemKeyForValue?: keyof T }) => {
    const [queryParams, setQueryParams] = useQuery();
    const [isVisible, setIsVisible] = useState(
        hasValue(urlQueryParam) ? queryParams.has(urlQueryParam?.key) : false
    );
    const [selectedItem, setSelectedItem] = useState<T | undefined>();

    const openModal = () => {
        setIsVisible(true);
    };

    const openModalWithData = (item?: T) => () => {
        if (item !== undefined) {
            setSelectedItem(item);
            if (hasValue(urlQueryParam)) {
                queryParams.set(
                    urlQueryParam.key,
                    hasValue(urlQueryParam.selectedItemKeyForValue)
                        ? ((item?.[urlQueryParam.selectedItemKeyForValue] as unknown) as string)
                        : ''
                );
                setQueryParams(queryParams);
            }
        }
        openModal();
    };

    const closeModal = () => {
        if (hasValue(urlQueryParam)) {
            queryParams.delete(urlQueryParam.key);
            setQueryParams(queryParams);
        }
        setIsVisible(false);
        setSelectedItem(undefined);
    };

    return { closeModal, isVisible, openModal, openModalWithData, selectedItem };
};
export default useModalState;
