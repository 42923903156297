import { IAction } from 'actions/commonAction';
import { GET_PATHWAY_ACTION } from 'actions/pathwayBlueprint/getPathway';
import { IPathway } from 'api/generated/models';

const initialState: IPathway = ({} as unknown) as IPathway;

export const pathway = (state = initialState, action: IAction<IPathway>): IPathway => {
    if (action.type === GET_PATHWAY_ACTION.success) {
        return action.data;
    }
    return state;
};
