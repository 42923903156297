import moment, { Moment } from 'moment';
import { hasValue } from 'utilities';

const EARLIEST_SUPPORTED_YEAR = 2019;

type IMaxYearModifier = (today: Moment) => number;
const SEPTEMBER = 8;
export const getYears = (
    maxYearModifier: IMaxYearModifier = (today) => (today.month() > SEPTEMBER ? 1 : 0),
    startingYear = EARLIEST_SUPPORTED_YEAR
) => {
    let year = startingYear;
    const today = moment();
    const maxYear = today.year() + maxYearModifier(today);
    const items: number[] = [];
    while (year <= maxYear) {
        items.unshift(year);
        year++;
    }
    return items;
};

export const getYearOrDefault = (value: moment.MomentInput, defaultYear: number) => {
    const year = moment(value).year();

    return year >= EARLIEST_SUPPORTED_YEAR ? year : defaultYear;
};

export const years = getYears(() => 1).map((x) => ({ name: `${x}` }));

export const getYear = (selectedYear: string) => {
    let year = selectedYear;
    if (!hasValue(selectedYear)) {
        year = years[0]?.name as string;
    }
    return year;
};
