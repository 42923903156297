import { CLEAR_COMPLETED_USER_TASKS_ACTION } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_COMPLETED_USER_TASKS_ACTION } from 'actions/task/getCompletedUserTasks';
import { SAVE_COMPLETED_USER_TASK_ACTION } from 'actions/task/saveCompletedUserTask';
import { ITaskItem } from 'api/generated/models';

const initialState = {
    completedTasks: [] as ITaskItem[],
};

export const userTasks = (
    state = initialState,
    action: IAction<ITaskItem | ITaskItem[]>
): typeof initialState => {
    switch (action.type) {
        case SAVE_COMPLETED_USER_TASK_ACTION.success: {
            const completedTasks = state.completedTasks;
            const data = action.data as ITaskItem;
            if (!completedTasks.some((x) => x.taskItemId === data.taskItemId)) {
                completedTasks.push(data);
            }
            return { completedTasks };
        }
        case GET_COMPLETED_USER_TASKS_ACTION.success:
            return { completedTasks: action.data as ITaskItem[] };
        case CLEAR_COMPLETED_USER_TASKS_ACTION:
            return initialState;
        default:
            return state;
    }
};
