import { IAction } from 'actions/commonAction';
import { PROCESS_COVERAGE_LEVEL_FOR_CONTRIBUTION_ACTION } from 'actions/householdMember/processCoverageLevelForContribution';
import { APPLY_SHOPPING_COVERAGE_CHANGES } from 'actions/householdShoppingCoverage/applyShoppingCoverageChanges';
import { SET_INITIAL_CONTRIBUTION } from 'actions/householdShoppingCoverage/setInitialContribution';
import { IHouseholdMemberDto, IHouseholdMemberPatchRecalculateDto } from 'api/generated/models';

type IHouseholdShoppingCoverageState = {
    contribution: number;
    householdMembersChanged: Record<string, IHouseholdMemberDto>;
};

const initialState: IHouseholdShoppingCoverageState = {
    contribution: 0,
    householdMembersChanged: {},
};

export const householdShoppingCoverage = (
    state = initialState,
    action: IAction<IHouseholdMemberPatchRecalculateDto>
): IHouseholdShoppingCoverageState => {
    if (action.type === SET_INITIAL_CONTRIBUTION) {
        const { contribution } = (action.data as unknown) as { contribution: number };
        return {
            contribution,
            householdMembersChanged: {},
        };
    } else if (action.type === PROCESS_COVERAGE_LEVEL_FOR_CONTRIBUTION_ACTION.success) {
        const awu = action.data.activeWageUp;
        const activeContribution = awu?.reimbursement ?? awu?.wageUp ?? 0;
        return {
            contribution: activeContribution,
            householdMembersChanged: {
                ...state.householdMembersChanged,
                [action.data.householdMember?.householdMemberId ?? '']: action.data
                    .householdMember as IHouseholdMemberDto,
            },
        };
    } else if (action.type === APPLY_SHOPPING_COVERAGE_CHANGES) {
        return initialState;
    }
    return state;
};
