export const getMonthName = (month: number): string | undefined => {
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const firstMonth = 1;
    const lastMonth = 12;

    if (month < firstMonth || month > lastMonth) {
        throw new Error('Month must be between 1 and 12');
    }

    return months[month - 1];
};
