const basicReducer = <T>(initialState: T, ...types: string[]) => (
    state = initialState,
    action: { data: T; type: string }
) => {
    if (types.includes(action.type)) {
        return {
            ...state,
            ...action.data,
        };
    }
    return state;
};

export default basicReducer;
