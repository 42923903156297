export const groupLevels = {
    EmployeeAndSpouse: 1,
    EmployeeOnly: 0,
    EmployeeWithChildren: 2,
    Family: 3,
    Unknown: 100,
    Waived: 4,
};

export const groupLevelAbbreviations = {
    [groupLevels.EmployeeOnly]: 'EE',
    [groupLevels.EmployeeAndSpouse]: 'ES',
    [groupLevels.EmployeeWithChildren]: 'EC',
    [groupLevels.Family]: 'EF',
    [groupLevels.Waived]: 'W',
    [groupLevels.Unknown]: 'UK',
};

export const groupLevelNames = {
    [groupLevels.EmployeeOnly]: 'Employee Only',
    [groupLevels.EmployeeAndSpouse]: 'Employee + Spouse',
    [groupLevels.EmployeeWithChildren]: 'Employee + Child(ren)',
    [groupLevels.Family]: 'Employee + Family',
    [groupLevels.Waived]: 'Waived',
    [groupLevels.Unknown]: 'Unknown',
};
