import { AppStore } from 'reducers/appReducer';
import { createSelector } from 'reselect';
import { getUniqueMarketplaceCarriers } from 'utilities/marketplacePlan';

const marketplacePlansSelector = (state: AppStore) => state.marketplacePlans;

export const uniqueMarketplaceCarriersSelector = createSelector(
    marketplacePlansSelector,
    (marketplacePlans) => getUniqueMarketplaceCarriers(marketplacePlans)
);
