import { CLEAR_REIMBURSEMENT_REPORT_YEARS } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { LIST_REIMBURSEMENT_REPORTS_ACTION } from 'actions/reimbursementReport/listReimbursementReports';
import { IListReimbursementReportsResponseDto } from 'api/generated/models';

const initialState: number[] = [];

export const reimbursementReportYears = (
    state = initialState,
    action: IAction<IListReimbursementReportsResponseDto>
): number[] => {
    switch (action.type) {
        case LIST_REIMBURSEMENT_REPORTS_ACTION.success: {
            return action.data.years as number[];
        }
        case CLEAR_REIMBURSEMENT_REPORT_YEARS:
            return initialState;
        default:
            return state;
    }
};
