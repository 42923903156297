import { GET_SURVEY_USER_PROFILE_ACTION } from 'actions/survey/getSurveyUserProfile';
import { GET_TEAM_PROFILE_ACTION } from 'actions/team/getTeamProfile';
import { GET_HOUSEHOLD_ACTION } from 'actions/user/getHousehold';
import { GET_USER_PROFILE_ACTION } from 'actions/user/getUserProfile';
import LogRocket from 'logrocket';
import { AppStore } from 'reducers/appReducer';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';

let hasTeam = false;
let hasUser = false;
let hasSetLogRocketIdentify = false;
const customLogRocketMiddleware: Middleware = ({ getState }: MiddlewareAPI) => (next: Dispatch) => (
    action
) => {
    if (action.isCurrent) {
        if (action.type === GET_TEAM_PROFILE_ACTION.success) {
            hasTeam = true;
        }
        if ([GET_HOUSEHOLD_ACTION.success, GET_USER_PROFILE_ACTION.success].includes(action.type)) {
            hasUser = true;
        }
    }
    next(action);
    if (!hasSetLogRocketIdentify && hasTeam && hasUser) {
        const {
            current: {
                teamProfile: { team },
                userProfile: { user },
            },
        } = getState() as AppStore;
        if (user && team) {
            LogRocket.identify(user.userId, {
                email: user.email ?? 'unknown',
                name: user.displayName ?? 'unknown',
                team: team.name ?? 'unknown',
                teamId: team.teamId,
            });
            hasSetLogRocketIdentify = true;
        }
    }
    if (action.type === GET_SURVEY_USER_PROFILE_ACTION.success) {
        const {
            current: { teamProfile },
        } = getState() as AppStore;
        const user = action.data?.userProfile?.user;
        LogRocket.identify(user?.userId, {
            email: user?.email,
            name: user?.displayName,
            team: teamProfile?.team?.name ?? 'unknown',
            teamId: user.teamId,
        });
    }
};
export default customLogRocketMiddleware;
