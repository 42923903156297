import { CLEAR_MULTI_SELECT } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { SEARCH_PROVIDERS_ACTION } from 'actions/marketplacePlan/searchProviders';
import { IProvider } from 'api/generated/models';

const initialState: IProvider[] = [];

export const providers = (state = initialState, action: IAction<IProvider[]>): IProvider[] => {
    switch (action.type) {
        case SEARCH_PROVIDERS_ACTION.success:
            return [...action.data];
        case CLEAR_MULTI_SELECT:
            return [];
        default:
            return state;
    }
};
