export enum MemberNavIndex {
    Profile,
    Benefits,
    Shopping,
    VerificationDocuments,
    MedicaidDetails,
    Files,
    Notes,
    Qualitative,
    Payments,
}
