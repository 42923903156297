import { PathwayTypes } from 'api/generated/enums';
import startCase from 'lodash/startCase';
import { enumToNameValueArray } from 'utilities';

export const PATHWAY_TYPE_ITEMS = enumToNameValueArray(
    PathwayTypes,
    {
        formatName: startCase,
        nameMap: {
            IchraAgeBanded: 'ICHRA Age-Banded',
            IchraCappedEmployeeCost: 'ICHRA Capped Employee Cost',
            IchraCappedEmployerCost: 'ICHRA Capped Employer Cost',
            IchraCustom: 'ICHRA Custom',
            IchraFlat: 'ICHRA Flat',
            IchraMedicare: 'ICHRA Medicare',
        },
    },
    true
);
