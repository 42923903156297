import { CLEAR_ROLE } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_ROLE_ACTION } from 'actions/role/getRole';
import { IRole } from 'api/generated/models';

const initialState: IRole = ({} as unknown) as IRole;

export const role = (state = initialState, action: IAction<IRole>): IRole => {
    switch (action.type) {
        case GET_ROLE_ACTION.success:
            return {
                ...action.data,
            };
        case CLEAR_ROLE:
            return ({} as unknown) as IRole;
        default:
            return state;
    }
};
