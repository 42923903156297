import { CLEAR_TEAM_FINCH } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { CREATE_FINCH_CONNECT_SESSION_ACTION } from 'actions/finch/createFinchConnectSession';
import { GET_FINCH_STATUS_ACTION } from 'actions/finch/getFinchStatus';
import { FinchStatus } from 'api/generated/models';

type ITeamFinch = {
    sessionId?: string;
    status?: FinchStatus;
};

const initialState: ITeamFinch = {};

export const teamFinch = (
    state = initialState,
    action: IAction<FinchStatus | string>
): ITeamFinch => {
    switch (action.type) {
        case GET_FINCH_STATUS_ACTION.success:
            return { ...state, status: action.data as FinchStatus };
        case CREATE_FINCH_CONNECT_SESSION_ACTION.success:
            return { ...state, sessionId: action.data as string };
        case CLEAR_TEAM_FINCH:
            return initialState;
        default:
            return state;
    }
};
