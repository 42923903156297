import { routerMiddleware } from 'connected-react-router';
import { actionsToRedact, keysToBeSanitized } from 'constants/logrocket';
import { History } from 'history';
import isObject from 'lodash/isObject';
import keyBy from 'lodash/keyBy';
import transform from 'lodash/transform';
import LogRocket from 'logrocket';
import { AppStore, createRootReducer } from 'reducers/appReducer';
import { applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { getStore, setStore } from 'store/accessor';
import customLogRocketMiddleware from 'store/customLogRocketMiddleware';
import tokenSuccessMiddleware from 'store/tokenSuccessMiddleware';

export function configureStore(history: History, preloadedState?: AppStore): Store<AppStore> {
    const store = getStore<AppStore>();
    if (store) {
        return store;
    }

    const middlewares = [
        routerMiddleware(history), // for dispatching history actions
        thunkMiddleware,
        LogRocket.reduxMiddleware({
            actionSanitizer: (action) => sanitizeAction(action, actionsToRedact, keysToBeSanitized),
            stateSanitizer: (state: AppStore) => sanitize(state, keysToBeSanitized),
        }),
        customLogRocketMiddleware,
        tokenSuccessMiddleware,
    ];

    const middlewareEnhancer = applyMiddleware(...middlewares);

    const enhancers = [middlewareEnhancer];

    const composedEnhancers = composeWithDevTools(...enhancers);

    setStore(createStore(createRootReducer(history), preloadedState, composedEnhancers));

    if (process.env['NODE_ENV'] !== 'production' && module.hot) {
        module.hot.accept('reducers/appReducer', () =>
            ((store as unknown) as Store).replaceReducer(createRootReducer(history))
        );
    }

    return getStore<AppStore>() as Store<AppStore>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ISanitizable = Record<string, any>;
const sanitizeAction = (obj: ISanitizable, actionsToOmit: string[], keysToOmit: string[]) => {
    const actionsToOmitIndex = keyBy(
        Array.isArray(actionsToOmit) ? actionsToOmit : [actionsToOmit]
    );

    const actionName: string = obj['type'] as string;
    if (actionName in actionsToOmitIndex) {
        obj['data'] = Array.isArray(obj['data'])
            ? `Array length: ${obj['data'].length}`
            : 'Object omitted';

        return obj;
    }

    return sanitize(obj, keysToOmit);
};

//adapted from https://stackoverflow.com/questions/39085399/lodash-remove-items-recursively
const sanitize = (obj: ISanitizable, keysToOmit: string[]) => {
    const keysToOmitIndex = keyBy(Array.isArray(keysToOmit) ? keysToOmit : [keysToOmit]);

    const omitFromObject = (_obj: ISanitizable) =>
        transform(_obj, (result: ISanitizable, value: ISanitizable, key: string) => {
            if (key in keysToOmitIndex) {
                result[key] = undefined;
                return;
            }
            result[key] = isObject(value) ? omitFromObject(value) : value;
        });

    return omitFromObject(obj);
};
