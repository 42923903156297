import { CLEAR_MULTI_SELECT } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GLOBAL_SEARCH_ACTION } from 'actions/search/globalSearch';
import { ISearchResults } from 'api/generated/models';

const initialState: Partial<ISearchResults> = {};

export const search = (
    state = initialState,
    action: IAction<ISearchResults>
): Partial<ISearchResults> => {
    if (action.type === GLOBAL_SEARCH_ACTION.success) {
        return { ...action.data };
    } else if (action.type === CLEAR_MULTI_SELECT) {
        return {};
    }
    return state;
};
