import { IAction } from 'actions/commonAction';
import { ADD_CUSTOM_QUESTION_ACTION } from 'actions/customQuestions/addCustomQuestion';
import { EDIT_CUSTOM_QUESTION_ACTION } from 'actions/customQuestions/editCustomQuestion';
import { GET_ACTIVE_QUESTIONS_ACTION } from 'actions/customQuestions/getActiveQuestions';
import { LIST_CUSTOM_QUESTIONS_ACTION } from 'actions/customQuestions/listCustomQuestions';
import { TOGGLE_ARCHIVE_CUSTOM_QUESTION_ACTION } from 'actions/customQuestions/toggleArchiveCustomQuestion';
import { ICustomQuestion } from 'api/generated/models';

const initialState: ICustomQuestion[] = [];

export const customQuestions = (
    state = initialState,
    action: IAction<ICustomQuestion[]>
): ICustomQuestion[] => {
    switch (action.type) {
        case LIST_CUSTOM_QUESTIONS_ACTION.success:
        case GET_ACTIVE_QUESTIONS_ACTION.success:
        case ADD_CUSTOM_QUESTION_ACTION.success:
        case EDIT_CUSTOM_QUESTION_ACTION.success:
        case TOGGLE_ARCHIVE_CUSTOM_QUESTION_ACTION.success:
            return action.data;
        default:
            return state;
    }
};
