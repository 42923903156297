import { PlanTypeIds } from 'api/generated/enums';
import startCase from 'lodash/startCase';
import { enumToNameValueArray } from 'utilities';

export const MONTHLY_SHARE_AMOUNT = 'Monthly Share Amount';
export const PREMIUM = 'Premium';

export const PLAN_TYPE_ITEMS = enumToNameValueArray(PlanTypeIds, {
    formatName: startCase,
    nameMap: {
        CustomOffExchange: 'Custom Off-Exchange',
        CustomStateBasedExchange: 'Custom State-Based Exchange',
        MediShare: 'Medi-Share',
        OffExchange: 'Off-Exchange',
    },
});

export const ACA_PLAN_TYPE_IDS = [PlanTypeIds.CustomStateBasedExchange, PlanTypeIds.Marketplace];
export const ACA_MARKETPLACE_PLAN_TYPE_NAME = 'ACA Marketplace';

export const OFF_EXCHANGE_PLAN_TYPE_IDS = [PlanTypeIds.CustomOffExchange, PlanTypeIds.OffExchange];
export const OFF_EXCHANGE_PLAN_TYPE_NAME = 'Off-Exchange';

export const FEDERAL_EXCHANGE = 'Federal';

export const CMS_OR_IDEON_PLAN_TYPES = [PlanTypeIds.Marketplace, PlanTypeIds.OffExchange];
