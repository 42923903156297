import { IAction } from 'actions/commonAction';
import { LIST_PROVIDERS_FOR_HOUSEHOLD_ACTION } from 'actions/provider/listProvidersForHousehold';
import { SET_PROVIDERS_FOR_HOUSEHOLD_MEMBER_ACTION } from 'actions/provider/setProvidersForHouseholdMember';
import { SET_PROVIDERS_FOR_USER_ACTION } from 'actions/provider/setProvidersForUser';
import { IProvider } from 'api/generated/models';

const initialState: IProvider[] = [];

export const householdProviders = (
    state = initialState,
    { data, entityId, type }: IAction<IProvider[]> & { entityId: string }
) => {
    switch (type) {
        case LIST_PROVIDERS_FOR_HOUSEHOLD_ACTION.success:
            return data;
        case SET_PROVIDERS_FOR_HOUSEHOLD_MEMBER_ACTION.success:
        case SET_PROVIDERS_FOR_USER_ACTION.success:
            return [...state.filter((x) => x.entityId !== entityId), ...data];
    }
    return state;
};
