import { IAction } from 'actions/commonAction';
import {
    CLEAR_MANAGE_MEMBERS_PAGINATION_PARAMS,
    SET_MANAGE_MEMBERS_PAGINATION_PARAMS,
    SET_MANAGE_MEMBERS_SEARCH,
    SET_MANAGE_MEMBERS_SHOW_ONLY_INACTIVE,
} from 'actions/manageMembersActions';
import { GroupLevels, UserTeamBenefitTermDetailStatuses } from 'api/generated/enums';
import { Ordering } from 'api/generated/models';

export type IManageMembersState = {
    coverageLevelFilters: GroupLevels[] | null | undefined;
    orderings: Ordering[] | null | undefined;
    page?: number;
    pageSize?: number;
    search?: string;
    showOnlyInactive: boolean;
    userTermDetailStatusFilters: UserTeamBenefitTermDetailStatuses[] | null | undefined;
};

const initialState: IManageMembersState = {
    coverageLevelFilters: undefined,
    orderings: undefined,
    page: 0,
    pageSize: 15,
    search: '',
    showOnlyInactive: false,
    userTermDetailStatusFilters: undefined,
};

export const manageMembersState = (
    state = initialState,
    action: IAction<IManageMembersState> | IAction<string[]>
): IManageMembersState => {
    switch (action.type) {
        case SET_MANAGE_MEMBERS_SEARCH:
        case SET_MANAGE_MEMBERS_PAGINATION_PARAMS:
        case SET_MANAGE_MEMBERS_SHOW_ONLY_INACTIVE:
            return { ...state, ...action.data };
        case CLEAR_MANAGE_MEMBERS_PAGINATION_PARAMS:
            return initialState;
        default:
            return state;
    }
};
