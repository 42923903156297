import { IAction } from 'actions/commonAction';
import { ADD_RESOURCE_ACTION } from 'actions/resources/addResource';
import { DELETE_RESOURCE_ACTION } from 'actions/resources/deleteResource';
import { EDIT_RESOURCE_ACTION } from 'actions/resources/editResource';
import { GET_RESOURCES_ACTION } from 'actions/resources/getResources';
import { GET_SHARED_RESOURCES_ACTION } from 'actions/resources/getSharedResources';
import { IResource } from 'api/generated/models';

const initialState: IResource[] = [];

export const resources = (
    state = initialState,
    action: IAction<IResource | IResource[]>
): IResource[] => {
    switch (action.type) {
        case GET_SHARED_RESOURCES_ACTION.success:
        case GET_RESOURCES_ACTION.success:
            return action.data as IResource[];
        case ADD_RESOURCE_ACTION.success:
            return [action.data as IResource, ...state];
        case EDIT_RESOURCE_ACTION.success:
            return state.map((r) => {
                const data = action.data as IResource;
                if (r.id === data.id) {
                    return data;
                }
                return r;
            });
        case DELETE_RESOURCE_ACTION.success:
            return state.filter((r) => r.id !== (action.data as IResource).id);
    }
    return state;
};
