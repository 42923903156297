import { CLEAR_REIMBURSEMENT_REPORT_ACTION } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_REIMBURSEMENT_REPORT_ACTION } from 'actions/reimbursementReport/getReimbursementReport';
import { IReimbursementReportDto } from 'api/generated/models';

const initialState: IReimbursementReportDto = ({} as unknown) as IReimbursementReportDto;

export const reimbursementReport = (
    state = initialState,
    action: IAction<IReimbursementReportDto>
): IReimbursementReportDto => {
    switch (action.type) {
        case GET_REIMBURSEMENT_REPORT_ACTION.success: {
            return action.data;
        }
        case CLEAR_REIMBURSEMENT_REPORT_ACTION:
            return initialState;
        default:
            return state;
    }
};
