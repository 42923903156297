import { CLEAR_SUBMITTED_EXPENSE } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { CALCULATE_SUBMITTED_EXPENSE_REIMBURSEMENT_AMOUNT_ACTION } from 'actions/submittedExpenses/calculateSubmittedExpenseReimbursementAmount';
import { GET_SUBMITTED_EXPENSE_ACTION } from 'actions/submittedExpenses/getSubmittedExpense';
import { ICalculateReimbursementResponseDto, ISubmittedExpenseDto } from 'api/generated/models';

const initialState: ISubmittedExpenseDto = ({} as unknown) as ISubmittedExpenseDto;

export const submittedExpense = (
    state = initialState,
    action: IAction<ICalculateReimbursementResponseDto | ISubmittedExpenseDto | number>
): ISubmittedExpenseDto => {
    switch (action.type) {
        case GET_SUBMITTED_EXPENSE_ACTION.success:
            return action.data as ISubmittedExpenseDto;
        case CALCULATE_SUBMITTED_EXPENSE_REIMBURSEMENT_AMOUNT_ACTION.success: {
            const data = action.data as ICalculateReimbursementResponseDto;
            return {
                ...state,
                hsaReimbursementSummary: data.hsaReimbursementSummary,
                reimbursementToHsa: data.hsaReimbursement,
                reimbursementToWages: data.wageReimbursement,
                wagesReimbursementSummary: data.wageReimbursementSummary,
            };
        }
        case CLEAR_SUBMITTED_EXPENSE:
            return initialState;
        default:
            return state;
    }
};
