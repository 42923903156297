import { CLEAR_PAGED_TEAMS } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { LIST_PAGED_TEAM_PROFILES_ACTION } from 'actions/team/listPagedTeamProfiles';
import { IPaginatedResultsDtoOfTeamProfile } from 'api/generated/models';

const initialState: IPaginatedResultsDtoOfTeamProfile = ({} as unknown) as IPaginatedResultsDtoOfTeamProfile;

export const pagedTeamProfiles = (
    state = initialState,
    action: IAction<IPaginatedResultsDtoOfTeamProfile>
): IPaginatedResultsDtoOfTeamProfile => {
    if (action.type === LIST_PAGED_TEAM_PROFILES_ACTION.success) {
        return action.data;
    } else if (action.type === CLEAR_PAGED_TEAMS) {
        return initialState;
    }
    return state;
};
