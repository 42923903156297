import { TerminationDateDelays } from 'api/generated/enums';
import { startCase } from 'lodash';
import { enumToNameValueArray } from 'utilities';
export const terminationDateDelayNames = {
    [TerminationDateDelays.Immediate]: 'Immediate',
    [TerminationDateDelays.LastDayOfMonth]: 'Last Day of Month',
};
export const TERMINATION_DATE_DELAY_ITEMS = enumToNameValueArray(TerminationDateDelays, {
    formatName: startCase,
    nameKey: 'text',
    nameMap: {
        Immediate: terminationDateDelayNames[TerminationDateDelays.Immediate],
        LastDayOfMonth: terminationDateDelayNames[TerminationDateDelays.LastDayOfMonth],
    },
});
