import { CLEAR_TEAM } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { ADD_TEAM_PLAN_ACTION } from 'actions/teamPlan/addTeamPlan';
import { DELETE_TEAM_PLAN_ACTION } from 'actions/teamPlan/deleteTeamPlan';
import { EDIT_TEAM_PLAN_ACTION } from 'actions/teamPlan/editTeamPlan';
import { GET_TEAM_PLAN_ACTION } from 'actions/teamPlan/getTeamPlan';
import { GET_TEAM_PLANS_ACTION } from 'actions/teamPlan/getTeamPlans';
import { PATCH_TEAM_PLAN_ACTION } from 'actions/teamPlan/patchTeamPlan';
import { ITeamPlan } from 'api/generated/models';

const initialState: ITeamPlan[] = [];

export const teamPlans = (
    state = initialState,
    action: IAction<ITeamPlan | ITeamPlan[]> & { teamPlanId?: string }
): ITeamPlan[] => {
    switch (action.type) {
        case GET_TEAM_PLANS_ACTION.success:
            return [...(action.data as ITeamPlan[])];
        case GET_TEAM_PLAN_ACTION.success:
        case PATCH_TEAM_PLAN_ACTION.success:
        case EDIT_TEAM_PLAN_ACTION.success:
            return state.map((teamPlan) => {
                const data = action.data as ITeamPlan;
                if (teamPlan.teamPlanId !== data.teamPlanId) {
                    // This isn't the item we care about - keep it as-is
                    return teamPlan;
                }
                // Otherwise, this is the one we want - return an updated value
                return {
                    ...data,
                };
            });
        case ADD_TEAM_PLAN_ACTION.success:
            return [...state, action.data as ITeamPlan];
        case DELETE_TEAM_PLAN_ACTION.success:
            return state.filter((teamPlan) => teamPlan.teamPlanId !== action.teamPlanId);
        case CLEAR_TEAM:
            return [];
        default:
            return state;
    }
};
