import { PayrollReportChangeDiffType } from 'api/generated/enums';
import {
    IPayrollReportDto,
    IPayrollReportUserSnapshotDto,
    PayrollReportUserChange,
    PayrollReportUserChangeLog,
} from 'api/generated/models';
import {
    ACA_MARKETPLACE_PLAN_TYPE_NAME,
    ACA_PLAN_TYPE_IDS,
    OFF_EXCHANGE_PLAN_TYPE_IDS,
    OFF_EXCHANGE_PLAN_TYPE_NAME,
    PLAN_TYPE_ITEMS,
} from 'constants/selectedPlans';
import { EmployerContributionTypes } from 'pages/payrollReports/PayrollReportCustomEmployerContributionModal';
import { hasValue } from 'utilities';

export const MAJOR_MEDICAL_BENEFIT_TYPES_PROPERTY_NAME = 'majormedicalbenefittypes';

type IEmployerContributionCustomArguments = Pick<
    IPayrollReportUserSnapshotDto,
    | 'customFsaEmployerContribution'
    | 'customHsaEmployerContribution'
    | 'fsaEmployerContribution'
    | 'hsaEmployerContribution'
    | 'ichraReimbursementAdjustment'
    | 'reimbursablePremiums'
    | 'taxFreeReimbursementAdjustment'
>;

const isEmployerContributionCustom = (
    values: IEmployerContributionCustomArguments | undefined,
    contributionType: EmployerContributionTypes
) => {
    let customEmployerContribution = values?.customFsaEmployerContribution;
    let employerContribution = values?.fsaEmployerContribution;
    if (contributionType === EmployerContributionTypes.Hsa) {
        customEmployerContribution = values?.customHsaEmployerContribution;
        employerContribution = values?.hsaEmployerContribution;
    }
    return (
        hasValue(customEmployerContribution) &&
        hasValue(employerContribution) &&
        employerContribution !== Number(customEmployerContribution)
    );
};

export const isNoteRequired = (
    values:
        | (IEmployerContributionCustomArguments &
              Pick<
                  IPayrollReportUserSnapshotDto,
                  | 'postTaxWithholdingAdjustment'
                  | 'preTaxWithholdingAdjustment'
                  | 'wageUpAdjustment'
              >)
        | undefined
) => {
    const hasAdjustment =
        hasValue(values?.wageUpAdjustment) ||
        hasValue(values?.ichraReimbursementAdjustment) ||
        hasValue(values?.postTaxWithholdingAdjustment) ||
        hasValue(values?.taxFreeReimbursementAdjustment) ||
        hasValue(values?.preTaxWithholdingAdjustment) ||
        values?.reimbursablePremiums?.some((r) => hasValue(r.adjustment));
    const hasCustomContribution =
        isEmployerContributionCustom(values, EmployerContributionTypes.Hsa) ||
        isEmployerContributionCustom(values, EmployerContributionTypes.Fsa);
    return hasAdjustment || hasCustomContribution;
};

export const getIsMajorMedicalProperty = (propertyName: string | undefined) =>
    propertyName?.toLowerCase() === MAJOR_MEDICAL_BENEFIT_TYPES_PROPERTY_NAME;

const getPlanTypeNamesString = (value: string | undefined) => {
    const acaPlanTypes = PLAN_TYPE_ITEMS.filter((p) => ACA_PLAN_TYPE_IDS.includes(p.value)).map(
        (x) => x['name']
    );
    const offExchangePlanTypes = PLAN_TYPE_ITEMS.filter((p) =>
        OFF_EXCHANGE_PLAN_TYPE_IDS.includes(p.value)
    ).map((x) => x['name']);

    const updatedPlanNames = value?.split(', ').map((p) => {
        if (acaPlanTypes.includes(p)) {
            return ACA_MARKETPLACE_PLAN_TYPE_NAME;
        }
        if (offExchangePlanTypes.includes(p)) {
            return OFF_EXCHANGE_PLAN_TYPE_NAME;
        }
        return p;
    });

    return [...new Set(updatedPlanNames)].joinSerialComma();
};

const removeAcaMarketplaceChangesAndUpdateMajorMedicalBenefitName = (
    changes: PayrollReportUserChange[] | undefined
) =>
    changes
        ?.map((c) => {
            if (getIsMajorMedicalProperty(c.property)) {
                c.newValue = getPlanTypeNamesString(c.newValue);
                c.oldValue = getPlanTypeNamesString(c.oldValue);
            }
            return c;
        })
        .filter((x) => !(getIsMajorMedicalProperty(x.property) && x.newValue === x.oldValue));

export const updateMajorMedicalBenefitNameAndRemoveBlankEntries = (
    payrollReport: IPayrollReportDto
) => {
    payrollReport.changes = payrollReport?.changes?.reduce<PayrollReportUserChangeLog[]>(
        (acc, c) => {
            c.changes = removeAcaMarketplaceChangesAndUpdateMajorMedicalBenefitName(c.changes);
            if (c.diffType !== PayrollReportChangeDiffType.Changed || c.changes?.length) {
                acc.push(c);
            }
            return acc;
        },
        []
    );

    return payrollReport;
};
