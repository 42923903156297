import { IAction } from 'actions/commonAction';
import { GET_TEAM_ADVISOR_ACTION } from 'actions/team/getTeamAdvisor';
import { IUser } from 'api/generated/models';

const initialState: IUser[] = [];

export const teamAdvisors = (state = initialState, action: IAction<IUser[]>): IUser[] => {
    if (action.type === GET_TEAM_ADVISOR_ACTION.success) {
        return [...action.data];
    }
    return state;
};
