/* eslint-disable @typescript-eslint/no-explicit-any */
export default abstract class PactSafe {
    static agreeToContract(groupKey: string) {
        (window as any)._ps(`${groupKey}:send`, 'agreed', {
            disable_sending: false,
        });
    }
    static addDownloadUrlListener(listenerFn: (downloadUrl: string) => void) {
        (window as any)._ps.on('set:download_url', listenerFn);
    }
    static updateNameData(firstName: string, lastName: string) {
        (window as any)._ps('set', 'custom_data', {
            first_name: firstName,
            last_name: lastName,
        });
        (window as any)._ps('send', 'updated', { disable_sending: false });
        (window as any)._ps('set', 'custom_data', null);
    }
}

export const PACTSAFE_ACCESS_ID = process.env['PACTSAFE_ACCESSKEY'];
