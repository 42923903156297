import { CLEAR_ACCOUNT_SNAPSHOT_ACTION } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_ACCOUNT_SNAPSHOT_ACTION } from 'actions/payment/getAccountSnapshot';
import { IPaymentsInfoDto } from 'api/generated/models';

const initialState: IPaymentsInfoDto = { canSetupInPayments: false };

export const paymentsInfo = (
    state = initialState,
    action: IAction<IPaymentsInfoDto>
): IPaymentsInfoDto => {
    if (action.type === GET_ACCOUNT_SNAPSHOT_ACTION.success) {
        return action.data;
    } else if (action.type === CLEAR_ACCOUNT_SNAPSHOT_ACTION) {
        return initialState;
    }
    return state;
};
