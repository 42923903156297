import { IAction } from 'actions/commonAction';
import { GET_DEFAULT_MEMBER_DATA_YEAR_ACTION } from 'actions/user/getDefaultMemberDataYear';

const initialState = 0;

export const defaultMemberDataYear = (state = initialState, action: IAction<number>): number => {
    if (action.type === GET_DEFAULT_MEMBER_DATA_YEAR_ACTION.success) {
        return action.data;
    }
    return state;
};
