import { IAction } from 'actions/commonAction';
import { SET_CUSTOM_EXPENSES_ACTION } from 'actions/expenseSummary/setCustomExpenses';
import { DELETE_PAYROLL_REPORT_ACTION } from 'actions/payroll/deletePayrollReport';
import { EDIT_REIMBURSABLE_PREMIUM_ADJUSTMENT_ACTION } from 'actions/payroll/editReimbursablePremiumAdjustment';
import { GENERATE_PAYROLL_REPORT_ACTION } from 'actions/payroll/generatePayrollReport';
import { GET_PAYROLL_REPORTS_FOR_YEAR_ACTION } from 'actions/payroll/getPayrollReportsForYear';
import { PATCH_PAYROLL_REPORT_USER_SNAPSHOT_ACTION } from 'actions/payroll/patchPayrollReportUserSnapshot';
import { REFRESH_PAYROLL_REPORT_ACTION } from 'actions/payroll/refreshPayrollReport';
import { SET_PAYROLL_REPORT_NOTE_ACTION } from 'actions/payroll/setPayrollReportNote';
import { TOGGLE_PAYROLL_REPORT_LOCKED_ACTION } from 'actions/payroll/togglePayrollReportLocked';
import { TOGGLE_PAYROLL_REPORT_SHARED_ACTION } from 'actions/payroll/togglePayrollReportShared';
import { ExpenseSummary, IExpenseSummary, IPayrollReportDto } from 'api/generated/models';
import { updateMajorMedicalBenefitNameAndRemoveBlankEntries } from 'utilities/payrollReports';

const initialState: IPayrollReportDto[] = [];

export const payrollReports = (
    state = initialState,
    action: IAction<IExpenseSummary | IPayrollReportDto | IPayrollReportDto[]>
): IPayrollReportDto[] => {
    switch (action.type) {
        case GET_PAYROLL_REPORTS_FOR_YEAR_ACTION.success:
            return (action.data as IPayrollReportDto[]).map(
                updateMajorMedicalBenefitNameAndRemoveBlankEntries
            );
        case GENERATE_PAYROLL_REPORT_ACTION.success:
            return [
                ...state,
                updateMajorMedicalBenefitNameAndRemoveBlankEntries(
                    action.data as IPayrollReportDto
                ),
            ];
        case REFRESH_PAYROLL_REPORT_ACTION.success: {
            const data = updateMajorMedicalBenefitNameAndRemoveBlankEntries(
                action.data as IPayrollReportDto
            );
            return state.map((report) =>
                report.year === data.year && report.month === data.month ? data : report
            );
        }
        case SET_PAYROLL_REPORT_NOTE_ACTION.success: {
            const data = action.data as IPayrollReportDto;
            return state.map((report) =>
                report.id === data.id ? { ...report, note: data.note } : report
            );
        }
        case TOGGLE_PAYROLL_REPORT_LOCKED_ACTION.success: {
            const data = action.data as IPayrollReportDto;
            return state.map((report) =>
                report.id === data.id ? { ...report, isLocked: data.isLocked } : report
            );
        }
        case TOGGLE_PAYROLL_REPORT_SHARED_ACTION.success: {
            const data = action.data as IPayrollReportDto;
            return state.map((report) =>
                report.id === data.id ? { ...report, isShared: data.isShared } : report
            );
        }
        case EDIT_REIMBURSABLE_PREMIUM_ADJUSTMENT_ACTION.success:
        case PATCH_PAYROLL_REPORT_USER_SNAPSHOT_ACTION.success: {
            return state.map((payrollReport) =>
                payrollReport.id === (action.data as IPayrollReportDto).id
                    ? updateMajorMedicalBenefitNameAndRemoveBlankEntries(
                          action.data as IPayrollReportDto
                      )
                    : payrollReport
            );
        }
        case DELETE_PAYROLL_REPORT_ACTION.success:
            return state.filter(
                (payrollReport) => payrollReport.id !== (action.data as IPayrollReportDto).id
            );
        case SET_CUSTOM_EXPENSES_ACTION.success: {
            const data = action.data as IExpenseSummary;
            return state.map((report) =>
                report.expenseSummary?.id === data.id
                    ? { ...report, expenseSummary: ExpenseSummary.fromJS(data) }
                    : report
            );
        }
        default:
            return state;
    }
};
