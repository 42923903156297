import { IAction } from 'actions/commonAction';
import { CLEAR_MEMBER_REIMBURSEMENT_UTILIZATIONS_ACTION } from 'actions/submittedExpenses/clearMemberReimbursementUtilizations';
import { GET_MEMBER_REIMBURSEMENT_UTILIZATIONS_ACTION } from 'actions/submittedExpenses/getMemberReimbursementUtilizations';
import { IMemberReimbursementUtilizationDto } from 'api/generated/models';

const initialState: IMemberReimbursementUtilizationDto[] = [];

export const memberReimbursementUtilizations = (
    state = initialState,
    action: IAction<IMemberReimbursementUtilizationDto[]>
): IMemberReimbursementUtilizationDto[] => {
    if (action.type === GET_MEMBER_REIMBURSEMENT_UTILIZATIONS_ACTION.success) {
        return action.data;
    }
    if (action.type === CLEAR_MEMBER_REIMBURSEMENT_UTILIZATIONS_ACTION) {
        return initialState;
    }
    return state;
};
