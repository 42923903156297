import { IAction } from 'actions/commonAction';
import { GET_SSN_TASK_CONTENT_ACTION } from 'actions/taskFlows/getSsnTaskContent';
import { ISocialSecurityNumbersDto } from 'api/generated/models';

const initialState: ISocialSecurityNumbersDto = ({} as unknown) as ISocialSecurityNumbersDto;

export const socialSecurityNumbers = (
    state = initialState,
    action: IAction<ISocialSecurityNumbersDto>
): ISocialSecurityNumbersDto => {
    if (action.type === GET_SSN_TASK_CONTENT_ACTION.success) {
        return action.data;
    } else {
        return state;
    }
};
