import { IAction } from 'actions/commonAction';
import { GET_MOST_RECENT_REIMBURSEMENT_REPORT_ACTION } from 'actions/reimbursementReport/getMostRecentReimbursementReport';
import { IBasicReimbursementReportDto } from 'api/generated/models';

const initialState: IBasicReimbursementReportDto = ({} as unknown) as IBasicReimbursementReportDto;

export const mostRecentReimbursementReport = (
    state = initialState,
    action: IAction<IBasicReimbursementReportDto>
): IBasicReimbursementReportDto => {
    if (action.type === GET_MOST_RECENT_REIMBURSEMENT_REPORT_ACTION.success) {
        return action.data;
    }
    return state;
};
