/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
import AddPersonPage from 'pages/addPerson/AddPersonPage';
import AddTeamPage from 'pages/addTeam/AddTeamPage';
import BenefitsPage from 'pages/benefits/BenefitsPage';
import BulkEnrollmentManagementPage from 'pages/bulkEnrollmentManagement/BulkEnrollmentManagementPage';
import DashboardPage from 'pages/dashboard/DashboardPage';
import IchraEnrollmentTaskFlowContainer from 'pages/dashboard/ichraTaskFlow/IchraEnrollmentTaskFlowContainer';
import EditPersonPage from 'pages/editPerson/EditPersonPage';
import EditRolePage from 'pages/editRole/EditRolePage';
import EditRolePermissionsPage from 'pages/editRolePermissions/EditRolePermissionsPage';
import EnrollmentInfoPage from 'pages/enrollmentInfoPage/EnrollmentInfoPage';
import ForgotPasswordPage from 'pages/forgotPassword/ForgotPasswordPage';
import IntegrationsPage from 'pages/integrations/IntegrationsPage';
import LoginPage from 'pages/login/LoginPage';
import MedicaidDetailsPage from 'pages/medicaidDetails/MedicaidDetailsPage';
import MfaVerificationPage from 'pages/mfaVerification/MfaVerificationPage';
import PathwayBlueprintPage from 'pages/pathwayBlueprint/PathwayBlueprintPage';
import PathwayBlueprintCostComparisonPage from 'pages/pathwayBlueprintCostComparison/PathwayBlueprintCostComparisonPage';
import PathwayBlueprintsPage from 'pages/pathwayBlueprints/PathwayBlueprintsPage';
import PaymentsPage from 'pages/payments/PaymentsPage';
import PayrollPage from 'pages/payrollReports/PayrollReportsPage';
import PeoplePage from 'pages/people/PeoplePage';
import ProfilePage from 'pages/profile/ProfilePage';
import ReimbursementExpenseManagementPage from 'pages/reimbursementExpenseManagement/ReimbursementExpenseManagementPage';
import ReimbursementReportsPage from 'pages/reimbursementReports/ReimbursementReportsPage';
import ReimbursementYearlyReportPage from 'pages/reimbursementReports/ReimbursementYearlyReportPage';
import ResetPasswordPage from 'pages/resetPassword/ResetPasswordPage';
import ResourceManagementPage from 'pages/resourceManagement/ResourceManagementPage';
import ResourcesAndHelpPage from 'pages/resourcesAndHelp/ResourcesAndHelpPage';
import RolesPage from 'pages/roles/RolesPage';
import SelectionsPage from 'pages/selections/SelectionsPage';
import ShopPage from 'pages/shop/ShopPage';
import SignUpPage from 'pages/signUp/SignUpPage';
import BenefitsSurveyPage from 'pages/survey/BenefitsSurveyPage';
import MemberRenewalSurveyPage from 'pages/survey/MemberRenewalSurveyPage';
import MemberSurveyIntroPage from 'pages/survey/MemberSurveyIntroPage';
import MemberSurveyPage from 'pages/survey/MemberSurveyPage';
import SystemRolesPage from 'pages/systemRoles/SystemRolesPage';
import TeamBenefitMemberManagementPage from 'pages/teamBenefitMemberManagement/TeamBenefitMemberManagementPage';
import TeamBenefitsPage from 'pages/teamBenefits/TeamBenefitsPage';
import TeamBenefitsManagementPage from 'pages/teamBenefitsManagement/TeamBenefitsManagementPage';
import TeamHbaPage from 'pages/teamHba/TeamHbaPage';
import TeamNotesPage from 'pages/teamNotes/TeamNotesPage';
import TeamProfilePage from 'pages/teamProfile/TeamProfilePage';
import TeamRhFilesPage from 'pages/teamRhFiles/TeamRhFilesPage';
import TeamsPage from 'pages/teams/TeamsPage';
import UserNotesPage from 'pages/userNotes/UserNotesPage';
import UserQualitativePage from 'pages/userQualitative/UserQualitativePage';
import UserRhFilesPage from 'pages/userRhFiles/UserRhFilesPage';
import VerificationDocumentsPage from 'pages/verificationDocuments/VerificationDocumentsPage';
import WelcomePage from 'pages/welcome/WelcomePage';
import { RouteComponentProps } from 'react-router';

/**
 * The following structure is important for team/member nav based pages.
 * Team pages: `/teams/:teamId/<more-url>`
 * Member pages: `/people/:userId/<more-url>` or `/teams/:teamId/people/:userId/<more-url>`
 * If structure is not followed things will likely break
 * Refer to `useExternalUserProps` and `useExternalTeamProps` hooks
 */
// paths
export const LOGIN_PATH = '/login';
export const SIGN_UP_PATH = '/sign-up';
export const MFA_VERIFICATION_PATH = '/verification-required';
export const FORGOT_PASSWORD_PATH = '/forgot-password';
export const RESET_PASSWORD_PATH = '/reset-password';
export const MY_BENEFITS_PATH = '/benefits';
export const TEAMS_MY_BENEFITS_PATH = '/teams/:teamId/people/:userId/benefits';
export const DASHBOARD_PATH = '/dashboard';
export const ICHRA_ENROLLMENT_FLOW_PATH = '/dashboard/ichra-enrollment-flow';
export const TEAMS_DASHBOARD_PATH = '/teams/:teamId/dashboard';
export const PROFILE_PATH = '/profile';
export const PEOPLE_PROFILE_PATH = '/people/:userId/profile';
export const TEAMS_PEOPLE_PROFILE_PATH = '/teams/:teamId/people/:userId/profile';
export const TEAMS_PATH = '/teams';
export const TEAMS_ADD_PATH = '/teams/add';
export const TEAM_PROFILE_PATH = '/team-profile';
export const TEAMS_PROFILE_PATH = '/teams/:teamId/profile';
export const PEOPLE_PATH = '/people';
export const TEAMS_PEOPLE_PATH = '/teams/:teamId/people';
export const TEAM_BENEFITS_PATH = '/team-benefits';
export const TEAMS_BENEFITS_PATH = '/teams/:teamId/benefits';
export const TEAM_BENEFIT_MEMBER_MANAGEMENT_PATH =
    '/teams/:teamId/benefits/:teamBenefitId/term-detail/:teamBenefitTermDetailId/member-management';
export const TEAM_BENEFITS_MANAGEMENT_PATH = '/team-benefits-management';
export const PATHWAY_BLUEPRINTS_PATH = '/pathways';
export const TEAMS_PATHWAY_BLUEPRINTS_PATH = '/teams/:teamId/pathways';
export const PATHWAY_BLUEPRINT_PATH = '/pathways/:pathwayBlueprintId';
export const TEAMS_PATHWAY_BLUEPRINT_PATH = '/teams/:teamId/pathways/:pathwayBlueprintId';
export const TEAMS_PATHWAY_BLUEPRINT_COST_COMPARISON_PATH =
    '/teams/:teamId/pathways/:pathwayBlueprintId/cost-comparison';
export const PEOPLE_ADD_PATH = '/people/add';
export const TEAMS_PEOPLE_ADD_PATH = '/teams/:teamId/people/add';
export const USER_RH_FILES_PATH = '/user-files';
export const TEAMS_USER_RH_FILES_PATH = '/teams/:teamId/people/:userId/files';
export const VERIFICATION_DOCUMENTS_PATH = '/verification-documents';
export const TEAMS_VERIFICATION_DOCUMENTS_PATH =
    '/teams/:teamId/people/:userId/verification-documents';
export const TEAMS_MEDICAID_DETAILS_PATH = '/teams/:teamId/people/:userId/medicaid-details';
export const TEAMS_USER_NOTES_PATH = '/teams/:teamId/people/:userId/notes';
export const TEAMS_USER_QUALITATIVE_PATH = '/teams/:teamId/people/:userId/qualitative';
export const PROFILE_EDIT_PATH = '/profile/edit';
export const PEOPLE_EDIT_PATH = '/people/:userId/edit';
export const TEAMS_PEOPLE_EDIT_PATH = '/teams/:teamId/people/:userId/edit';
export const RESOURCE_MANAGEMENT_PATH = '/resource-management';
export const RESOURCES_AND_HELP_PATH = '/resources-and-help';
export const WELCOME_PATH = '/welcome';
export const GROUP_PLAN_PATH = '/group-plan';
export const RH_FILES_PATH = '/files';
export const TEAMS_RH_FILES_PATH = '/teams/:teamId/files';
export const TEAMS_NOTES_PATH = '/teams/:teamId/notes';
export const BENEFITS_SURVEY_PATH = '/benefits-survey';
export const MEMBER_SURVEY_PATH = '/member-survey';
export const MEMBER_RENEWAL_SURVEY_PATH = '/member-renewal-survey';
export const TEAMS_MEMBER_SURVEY_FOR_USER_PATH = '/teams/:teamId/people/:userId/member-survey';
export const MEMBER_SURVEY_INTRO_PATH = '/member-survey-intro';
export const ROLES_PATH = '/roles';
export const TEAMS_ROLES_PATH = '/teams/:teamId/roles';
export const ROLES_EDIT_PATH = '/roles/:roleId/edit';
export const TEAMS_ROLES_EDIT_PATH = '/teams/:teamId/roles/:roleId/edit';
export const SYS_ROLES_PATH = '/sysroles';
export const SYS_ROLES_EDIT_PATH = '/sysroles/:roleId/edit';
export const SHOP_PATH = '/shop';
export const TEAMS_SHOP_PATH = '/teams/:teamId/people/:userId/shop';
export const SELECTIONS_PATH = '/selections';
export const TEAMS_SELECTIONS_PATH = '/teams/:teamId/people/:userId/selections';
export const CCM_ENROLLMENT_INFO_PATH = '/enrollment-info';
export const TEAMS_HBA_PATH = '/teams/:teamId/hba';
export const PAYROLL_PATH = '/payroll';
export const TEAMS_PAYROLL_PATH = '/teams/:teamId/payroll';
export const REIMBURSEMENT_REPORTS_PATH = '/reimbursement-reports';
export const TEAMS_REIMBURSEMENT_REPORTS_PATH = '/teams/:teamId/reimbursement-reports';
export const GLOBAL_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH =
    '/global-reimbursement-expense-management';
export const BULK_ENROLLMENT_MANAGEMENT_PATH = '/bulk-enrollment';
export const REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH = '/reimbursement-expense-management';
export const TEAM_REIMBURSEMENT_EXPENSE_PATH = '/team-reimbursement-expense-management';
export const TEAMS_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH =
    '/teams/:teamId/reimbursement-expense-management';
export const TEAMS_PEOPLE_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH =
    '/teams/:teamId/people/:userId/reimbursement-expense-management';
export const PEOPLE_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH =
    '/people/:userId/reimbursement-expense-management';
export const REIMBURSEMENT_YEARLY_SUMMARY_PATH = '/reimbursement-yearly-summary';
export const TEAMS_REIMBURSEMENT_YEARLY_SUMMARY_PATH =
    '/teams/:teamId/reimbursement-yearly-summary';
export const TEAMS_USER_PAYMENTS_PATH = '/teams/:teamId/people/:userId/payments';
export const INTEGRATIONS_PATH = '/integrations';
export const TEAMS_INTEGRATIONS_PATH = '/teams/:teamId/integrations';

export type IPageComponent = React.FunctionComponent<
    RouteComponentProps & {
        isTeamManagementPage: boolean;
    }
>;
export type IPageData = {
    Component: IPageComponent;
    anonymousPaths?: string[];
    paths?: string[];
    teamManagementPaths?: string[];
};
// prettier-ignore
export const PAGE_DATA: IPageData[] = [
    {Component: AddPersonPage, paths: [PEOPLE_ADD_PATH], teamManagementPaths: [TEAMS_PEOPLE_ADD_PATH]},
    {Component: AddTeamPage, paths: [TEAMS_ADD_PATH]},
    {Component: BenefitsPage, paths: [MY_BENEFITS_PATH], teamManagementPaths: [TEAMS_MY_BENEFITS_PATH]},
    {Component: BenefitsSurveyPage, anonymousPaths: [BENEFITS_SURVEY_PATH], paths: [BENEFITS_SURVEY_PATH]},
    {Component: DashboardPage, paths: ['/', DASHBOARD_PATH], teamManagementPaths: [TEAMS_DASHBOARD_PATH]},
    {Component: IchraEnrollmentTaskFlowContainer, paths: [ICHRA_ENROLLMENT_FLOW_PATH]},
    {
        Component: EditPersonPage,
        paths: [PROFILE_EDIT_PATH, PEOPLE_EDIT_PATH],
        teamManagementPaths: [TEAMS_PEOPLE_EDIT_PATH]
    },
    {Component: EditRolePage, paths: [ROLES_EDIT_PATH], teamManagementPaths: [TEAMS_ROLES_EDIT_PATH]},
    {Component: EditRolePermissionsPage, paths: [SYS_ROLES_EDIT_PATH]},
    {Component: EnrollmentInfoPage, paths: [CCM_ENROLLMENT_INFO_PATH]},
    {Component: ForgotPasswordPage, anonymousPaths: [FORGOT_PASSWORD_PATH], paths: [FORGOT_PASSWORD_PATH]},
    {Component: LoginPage, anonymousPaths: [LOGIN_PATH], paths: [LOGIN_PATH]},
    {Component: MedicaidDetailsPage, teamManagementPaths: [TEAMS_MEDICAID_DETAILS_PATH]},
    {Component: MemberRenewalSurveyPage, paths: [MEMBER_RENEWAL_SURVEY_PATH]},
    {Component: MemberSurveyIntroPage, paths: [MEMBER_SURVEY_INTRO_PATH]},
    {
        Component: MemberSurveyPage,
        paths: [MEMBER_SURVEY_PATH],
        teamManagementPaths: [TEAMS_MEMBER_SURVEY_FOR_USER_PATH]
    },
    {
        Component: PathwayBlueprintPage,
        paths: [PATHWAY_BLUEPRINT_PATH],
        teamManagementPaths: [TEAMS_PATHWAY_BLUEPRINT_PATH]
    },
    {
        Component: PathwayBlueprintCostComparisonPage,
        teamManagementPaths: [TEAMS_PATHWAY_BLUEPRINT_COST_COMPARISON_PATH]
    },
    {
        Component: PathwayBlueprintsPage,
        paths: [PATHWAY_BLUEPRINTS_PATH],
        teamManagementPaths: [TEAMS_PATHWAY_BLUEPRINTS_PATH]
    },
    {Component: PaymentsPage, teamManagementPaths: [TEAMS_USER_PAYMENTS_PATH]},
    {Component: PayrollPage, paths: [PAYROLL_PATH], teamManagementPaths: [TEAMS_PAYROLL_PATH]},
    {Component: PeoplePage, paths: [PEOPLE_PATH], teamManagementPaths: [TEAMS_PEOPLE_PATH]},
    {
        Component: ProfilePage,
        paths: [PROFILE_PATH, PEOPLE_PROFILE_PATH],
        teamManagementPaths: [TEAMS_PEOPLE_PROFILE_PATH]
    },
    {
        Component: ReimbursementExpenseManagementPage,
        paths: [GLOBAL_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH, REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH, PEOPLE_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH, TEAM_REIMBURSEMENT_EXPENSE_PATH],
        teamManagementPaths: [TEAMS_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH, TEAMS_PEOPLE_REIMBURSEMENT_EXPENSE_MANAGEMENT_PATH]
    },
    {
        Component: BulkEnrollmentManagementPage,
        paths: [BULK_ENROLLMENT_MANAGEMENT_PATH]
    },
    {
        Component: ReimbursementReportsPage,
        paths: [REIMBURSEMENT_REPORTS_PATH],
        teamManagementPaths: [TEAMS_REIMBURSEMENT_REPORTS_PATH]
    },
    {Component: ResetPasswordPage, anonymousPaths: [RESET_PASSWORD_PATH], paths: [RESET_PASSWORD_PATH]},
    {Component: ResourceManagementPage, paths: [RESOURCE_MANAGEMENT_PATH]},
    {Component: ResourcesAndHelpPage, paths: [RESOURCES_AND_HELP_PATH]},
    {Component: RolesPage, paths: [ROLES_PATH], teamManagementPaths: [TEAMS_ROLES_PATH]},
    {Component: SelectionsPage, paths: [SELECTIONS_PATH], teamManagementPaths: [TEAMS_SELECTIONS_PATH]},
    {Component: ShopPage, paths: [SHOP_PATH], teamManagementPaths: [TEAMS_SHOP_PATH]},
    {Component: SignUpPage, anonymousPaths: [SIGN_UP_PATH], paths: [SIGN_UP_PATH]},
    {Component: MfaVerificationPage, anonymousPaths: [MFA_VERIFICATION_PATH], paths: [MFA_VERIFICATION_PATH] },
    {Component: SystemRolesPage, paths: [SYS_ROLES_PATH]},
    {Component: TeamBenefitMemberManagementPage, teamManagementPaths: [TEAM_BENEFIT_MEMBER_MANAGEMENT_PATH]},
    {Component: TeamBenefitsManagementPage, paths: [TEAM_BENEFITS_MANAGEMENT_PATH]},
    {Component: TeamBenefitsPage, paths: [TEAM_BENEFITS_PATH], teamManagementPaths: [TEAMS_BENEFITS_PATH]},
    {Component: TeamHbaPage, teamManagementPaths: [TEAMS_HBA_PATH]},
    {Component: TeamNotesPage, teamManagementPaths: [TEAMS_NOTES_PATH]},
    {Component: TeamProfilePage, paths: [TEAM_PROFILE_PATH], teamManagementPaths: [TEAMS_PROFILE_PATH]},
    {Component: TeamRhFilesPage, paths: [RH_FILES_PATH], teamManagementPaths: [TEAMS_RH_FILES_PATH]},
    {Component: TeamsPage, paths: [TEAMS_PATH]},
    {Component: UserNotesPage, teamManagementPaths: [TEAMS_USER_NOTES_PATH]},
    {Component: UserQualitativePage, teamManagementPaths: [TEAMS_USER_QUALITATIVE_PATH]},
    {Component: UserRhFilesPage, paths: [USER_RH_FILES_PATH], teamManagementPaths: [TEAMS_USER_RH_FILES_PATH]},
    {
        Component: VerificationDocumentsPage,
        paths: [VERIFICATION_DOCUMENTS_PATH],
        teamManagementPaths: [TEAMS_VERIFICATION_DOCUMENTS_PATH]
    },
    {Component: WelcomePage, paths: [WELCOME_PATH]},
    {
        Component: ReimbursementYearlyReportPage,
        paths: [REIMBURSEMENT_YEARLY_SUMMARY_PATH],
        teamManagementPaths: [TEAMS_REIMBURSEMENT_YEARLY_SUMMARY_PATH]
    },
    {Component: IntegrationsPage, paths: [INTEGRATIONS_PATH], teamManagementPaths: [TEAMS_INTEGRATIONS_PATH]},
];
