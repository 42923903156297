import { IAction } from 'actions/commonAction';
import { GET_PATHWAY_DATA_FOR_USER_ACTION } from 'actions/pathwayBlueprint/getPathwayDataForUser';
import { IPathwayDataForUserDto } from 'api/generated/models';

const initialState: IPathwayDataForUserDto = ({} as unknown) as IPathwayDataForUserDto;

export const pathwayDataForUser = (
    state = initialState,
    action: IAction<IPathwayDataForUserDto>
): IPathwayDataForUserDto => {
    if (action.type === GET_PATHWAY_DATA_FOR_USER_ACTION.success) {
        return action.data;
    }
    return state;
};
