import { IAction } from 'actions/commonAction';
import { LIST_DRUGS_FOR_HOUSEHOLD_ACTION } from 'actions/drug/listDrugsForHousehold';
import { SET_DRUGS_FOR_HOUSEHOLD_MEMBER_ACTION } from 'actions/drug/setDrugsForHouseholdMember';
import { SET_DRUGS_FOR_USER_ACTION } from 'actions/drug/setDrugsForUser';
import { Drug } from 'api/generated/models';

const initialState: Drug[] = [];

export const householdDrugs = (
    state = initialState,
    { data, entityId, type }: IAction<Drug[]> & { entityId: string }
) => {
    switch (type) {
        case LIST_DRUGS_FOR_HOUSEHOLD_ACTION.success:
            return data;
        case SET_DRUGS_FOR_HOUSEHOLD_MEMBER_ACTION.success:
        case SET_DRUGS_FOR_USER_ACTION.success:
            return [...state.filter((x) => x.entityId !== entityId), ...data];
    }
    return state;
};
