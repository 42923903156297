import { CLEAR_TEAM } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { ADD_TEAM_ACTION } from 'actions/team/addTeam';
import { EDIT_TEAM_ACTION } from 'actions/team/editTeam';
import { EDIT_TEAM_ADDRESS_ACTION } from 'actions/team/editTeamAddress';
import { GET_TEAM_PROFILE_ACTION } from 'actions/team/getTeamProfile';
import { PATCH_TEAM_ACTION } from 'actions/team/patchTeam';
import { TRANSITION_TO_MEMBER_LAUNCH_ACTION } from 'actions/team/transitionToMemberLaunch';
import { TRANSITION_TO_READY_FOR_MEMBER_LAUNCH_ACTION } from 'actions/team/transitionToReadyForMemberLaunch';
import { TRANSITION_TO_RENEWING_ACTION } from 'actions/team/transitionToRenewing';
import { TRANSITION_TO_RH_LAUNCH_ACTION } from 'actions/team/transitionToRhLaunch';
import { TRANSITION_TO_TEAM_ADMIN_LAUNCH_ACTION } from 'actions/team/transitionToTeamAdminLaunch';
import { TRANSITION_TO_CUSTOMER_ACTION } from 'actions/team/transitionToTeamCustomer';
import { Address, IAddress, ITeam, ITeamProfile, Team } from 'api/generated/models';

const initialState: ITeamProfile = {};

export const teamProfile = (
    state = initialState,
    action: IAction<IAddress | ITeam | ITeamProfile> & { isCurrent?: boolean }
): ITeamProfile => {
    if (action.type === CLEAR_TEAM) {
        return {};
    }

    if (!action.isCurrent) {
        switch (action.type) {
            case GET_TEAM_PROFILE_ACTION.success:
                return {
                    ...(action.data as ITeamProfile),
                };
            case EDIT_TEAM_ADDRESS_ACTION.success:
                return {
                    ...state,
                    address: action.data as Address,
                };
            case PATCH_TEAM_ACTION.success:
            case TRANSITION_TO_RH_LAUNCH_ACTION.success:
            case TRANSITION_TO_CUSTOMER_ACTION.success:
            case TRANSITION_TO_TEAM_ADMIN_LAUNCH_ACTION.success:
            case TRANSITION_TO_READY_FOR_MEMBER_LAUNCH_ACTION.success:
            case TRANSITION_TO_MEMBER_LAUNCH_ACTION.success:
                return {
                    ...state,
                    team: action.data as Team,
                };
            case TRANSITION_TO_RENEWING_ACTION.success:
                return {
                    ...state,
                    team: (action.data as ITeamProfile).team,
                };
            default:
                return state;
        }
    } else {
        switch (action.type) {
            case ADD_TEAM_ACTION.success:
            case EDIT_TEAM_ACTION.success:
                return {
                    ...(action.data as ITeamProfile),
                };
            default:
                return state;
        }
    }
};
