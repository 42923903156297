import { IAction } from 'actions/commonAction';
import { CLEAR_QUALITATIVE_ANSWERS } from 'actions/user/clearQualitativeAnswers';
import { GET_QUALITATIVE_ANSWERS_ACTION } from 'actions/user/getQualitativeAnswers';
import { IQualitativeAnswers } from 'api/generated/models';

const initialState: IQualitativeAnswers = ({} as unknown) as IQualitativeAnswers;

export const qualitativeAnswers = (
    state = initialState,
    action: IAction<IQualitativeAnswers>
): IQualitativeAnswers => {
    if (action.type === GET_QUALITATIVE_ANSWERS_ACTION.success) {
        return action.data;
    } else if (action.type === CLEAR_QUALITATIVE_ANSWERS) {
        return initialState;
    }
    return state;
};
