import { PlanTypeIds } from 'api/generated/enums';
import { ISelectedPlan, ISelectedPlanDto } from 'api/generated/models';
import { FEDERAL_EXCHANGE, MONTHLY_SHARE_AMOUNT } from 'constants/selectedPlans';
import { hasValue } from 'utilities';

export const getTitle = (
    { issuerName, planName, planTypeId }: ISelectedPlan,
    includeIssuerName = true
) => {
    switch (planTypeId) {
        case PlanTypeIds.ParentEmployer:
        case PlanTypeIds.SpouseEmployer:
            return `${issuerName} Plan`;
        default:
            if (includeIssuerName) {
                return `${issuerName} - ${planName}`;
            }
            return planName;
    }
};

export const planHasPremiumWithCredit = (x: ISelectedPlan): boolean =>
    hasValue(x.planPremiumWithCredits);

export const isMediShare = (selectedPlan: ISelectedPlan | ISelectedPlanDto) =>
    selectedPlan.planTypeId === PlanTypeIds.MediShare;

export const getCostLabel = (isMediSharePlanType: boolean | undefined) =>
    isMediSharePlanType ? MONTHLY_SHARE_AMOUNT : 'Plan Cost';

export const getPlanOrProgram = (isMediSharePlanType: boolean) =>
    isMediSharePlanType ? 'Program' : 'Plan';

export const getTaxedUpValue = (value: number | undefined, totalTaxRate: number | undefined) =>
    (value ?? 0) / (1 - (totalTaxRate ?? 0));

/**
 * Will round `first` and `second` and then subtract them, then return max of the result compared to 0
 * @param first
 * @param second
 * @returns Max of rounded difference vs 0
 */
export const getRoundedDifference = (first: number, second: number | undefined) =>
    Math.max(Math.round(first) - Math.round(second ?? NaN), 0);

export const getIsStateBasedExchange = (
    plan: { exchange?: string; planTypeId?: PlanTypeIds },
    isAllMarketPlace = false
) =>
    (plan?.planTypeId === PlanTypeIds.Marketplace || isAllMarketPlace) &&
    plan?.exchange?.toLowerCase() !== FEDERAL_EXCHANGE.toLowerCase() &&
    hasValue(plan?.exchange);
