import { CLEAR_MULTI_SELECT } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { SEARCH_IDEON_PROVIDERS_ACTION } from 'actions/providerSearch/searchIdeonProviders';
import { SEARCH_IDEON_PROVIDERS_WITH_TOKEN_ACTION } from 'actions/providerSearch/searchIdeonProvidersWithToken';
import { ProviderSource } from 'api/generated/enums';
import { IIdeonProvider } from 'api/generated/models';
import { IListItem } from 'pages/survey/coverageSteps/HouseholdListCards';
import { hasValue } from 'utilities/index';

export type ISurveyIdeonProvider = IIdeonProvider &
    IListItem & { nationalProviderId: string | undefined; source: ProviderSource };

const initialState: ISurveyIdeonProvider[] = [];

export const surveyIdeonProviders = (
    state = initialState,
    action: IAction<IIdeonProvider[]>
): ISurveyIdeonProvider[] => {
    switch (action.type) {
        case SEARCH_IDEON_PROVIDERS_ACTION.success:
        case SEARCH_IDEON_PROVIDERS_WITH_TOKEN_ACTION.success:
            return action.data.map((x) => {
                let name = x.presentationName;
                if (hasValue(x.doingBusinessAsName)) {
                    name += `; DBA (${x.doingBusinessAsName})`;
                }
                return {
                    ...x,
                    name,
                    entityIds: [],
                    nationalProviderId: hasValue(x.id) ? `${x.id}` : undefined,
                    source: ProviderSource.Ideon,
                };
            });
        case CLEAR_MULTI_SELECT:
            return [];
        default:
            return state;
    }
};
