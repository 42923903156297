import { IAction } from 'actions/commonAction';
import { CREATE_OR_PATCH_MEDICAID_DETAILS_ACTION } from 'actions/medicaid/createOrPatchMedicaidDetails.ts';
import { CREATE_OR_PATCH_MEDICAID_RENEWAL_DETAILS_ACTION } from 'actions/medicaid/createOrPatchRenewalDetails';
import { GET_MEDICAID_DETAILS_ACTION } from 'actions/medicaid/getMedicaidDetails';
import { IMedicaidDetails } from 'api/generated/models';

const initialState: IMedicaidDetails = ({} as unknown) as IMedicaidDetails;

export const medicaid = (
    state = initialState,
    action: IAction<IMedicaidDetails>
): IMedicaidDetails => {
    switch (action.type) {
        case CREATE_OR_PATCH_MEDICAID_RENEWAL_DETAILS_ACTION.success:
        case CREATE_OR_PATCH_MEDICAID_DETAILS_ACTION.success:
        case GET_MEDICAID_DETAILS_ACTION.success:
            return action.data;
        default:
            return state;
    }
};
