import { IAction } from 'actions/commonAction';
import { CLEAR_TAX_DATA } from 'actions/taxData/clearTaxData';
import { EDIT_FICA_RATE } from 'actions/taxData/editFicaRate';
import { GET_TAX_DATA_ACTION } from 'actions/taxData/getTaxData';
import { ITaxDataDto } from 'api/generated/models';

const initialState: ITaxDataDto = ({} as unknown) as ITaxDataDto;

export const taxData = (state = initialState, action: IAction<ITaxDataDto>): ITaxDataDto => {
    switch (action.type) {
        case GET_TAX_DATA_ACTION.success:
        case EDIT_FICA_RATE.success:
            return action.data;
        case CLEAR_TAX_DATA:
            return initialState;
    }
    return state;
};
