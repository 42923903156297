import { IAction } from 'actions/commonAction';
import { ARCHIVE_TEAM_ACTION } from 'actions/team/archiveTeam';
import { DELETE_TEAM_ACTION } from 'actions/team/deleteTeam';
import { GET_TEAM_PROFILES_ACTION } from 'actions/team/getTeamProfiles';
import { ITeam, ITeamProfile, Team } from 'api/generated/models';

const initialState: ITeamProfile[] = [];

export const teamProfiles = (
    state = initialState,
    action: IAction<ITeam | ITeamProfile[]>
): ITeamProfile[] => {
    switch (action.type) {
        case GET_TEAM_PROFILES_ACTION.success:
            return [...(action.data as ITeamProfile[])];
        case DELETE_TEAM_ACTION.success:
            return state.filter(
                (teamProfile) => teamProfile.team?.teamId !== (action.data as ITeam).teamId
            );
        case ARCHIVE_TEAM_ACTION.success:
            return state.map(
                (teamProfile): ITeamProfile => {
                    const team = action.data as Team;
                    if (teamProfile.team?.teamId === team.teamId) {
                        return {
                            ...teamProfile,
                            team,
                        };
                    }
                    return teamProfile;
                }
            );
        default:
            return state;
    }
};
