import { GroupLevels, SortOrder, UserTeamBenefitTermDetailStatuses } from 'api/generated/enums';
import { IUserTermDetailManageMemberDto, Ordering } from 'api/generated/models';
import { Filters, SortingRule } from 'react-table';
import { hasValue } from 'utilities/index';

export const transformFiltersToEnumArray = (
    filters: Filters<IUserTermDetailManageMemberDto> | undefined
) => {
    const coverageLevelFilters = [] as GroupLevels[];
    const userTermDetailStatusFilters = [] as UserTeamBenefitTermDetailStatuses[];
    if (!hasValue(filters)) {
        return {
            coverageLevelFilters,
            userTermDetailStatusFilters,
        };
    }
    const coverageLevelFilterObjectValue = filters.find((filter) => filter.id === 'coverageLevel')
        ?.value;
    const userTermDetailStatusFilterObjectValue = filters.find(
        (filter) => filter.id === 'userTeamBenefitStatus'
    )?.value;
    coverageLevelFilterObjectValue.map((x: string) =>
        coverageLevelFilters.push(GroupLevels[x as keyof typeof GroupLevels])
    );
    userTermDetailStatusFilterObjectValue.map((x: string) =>
        userTermDetailStatusFilters.push(
            UserTeamBenefitTermDetailStatuses[x as keyof typeof UserTeamBenefitTermDetailStatuses]
        )
    );

    return {
        coverageLevelFilters,
        userTermDetailStatusFilters,
    };
};

export const transformSortByToOrderingArray = <T>(sortBy: SortingRule<T>[]) => {
    const orderingArray = [] as Ordering[];
    sortBy.forEach((sort: SortingRule<T>) => {
        orderingArray.push({
            property: sort.id,
            sortOrder: sort.desc === false ? SortOrder.Ascending : SortOrder.Descending,
        } as Ordering);
    });

    return orderingArray;
};
