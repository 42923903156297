import { CLEAR_LOGIN } from 'actions/clear';
import { INVALIDATE_TOKEN_ACTION } from 'actions/token/invalidateToken';
import { INTERMEDIATE_TOKEN_ACTION, TOKEN_ACTION } from 'actions/token/tokenActionUtility';
import { SIGNUP_ACTION } from 'actions/user/signUp';
import AuthenticatedPageWrapper from 'components/authenticatedPageWrapper/AuthenticatedPageWrapper';
import UnauthenticatedPageWrapper from 'components/UnauthenticatedPageWrapper';

const initialState = {
    allowRememberDevice: false,
    email: '',
    hasMfa: false,
    isAuthenticated: false,
    PageWrapper: UnauthenticatedPageWrapper,
};

export const appRouterReducer = (
    state = initialState,
    action: {
        accessToken?: string;
        allowRememberDevice?: boolean;
        email?: string;
        intermediateToken?: string;
        refreshToken?: string;
        type: string;
    }
): typeof initialState => {
    switch (action.type) {
        case TOKEN_ACTION.success:
        case SIGNUP_ACTION.success: {
            const isAuthenticated = !!action.accessToken && !!action.refreshToken;
            return {
                ...state,
                isAuthenticated,
                PageWrapper: isAuthenticated
                    ? AuthenticatedPageWrapper
                    : UnauthenticatedPageWrapper,
            };
        }
        case INTERMEDIATE_TOKEN_ACTION.success: {
            const hasMfa = !!action.intermediateToken;
            return {
                ...state,
                hasMfa,
                allowRememberDevice: !!action.allowRememberDevice,
                email: action.email as string,
                PageWrapper: UnauthenticatedPageWrapper,
            };
        }
        case CLEAR_LOGIN:
        case INVALIDATE_TOKEN_ACTION.success:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
