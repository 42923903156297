import every from 'lodash/every';
import has from 'lodash/has';
import some from 'lodash/some';
import { AppStore } from 'reducers/appReducer';

export const hasApiActivityWithParams = (
    state: AppStore,
    actionType: string,
    params: Record<string, string>
) => {
    const activityWithParams = state.apiActivity[actionType.withParams];
    if (activityWithParams) {
        const apiActivityKeys = Object.keys(activityWithParams);
        return apiActivityKeys.some((key) => {
            if (!(activityWithParams as Record<string, boolean>)?.[key]) {
                return false;
            }
            const urlSearchParams = new URLSearchParams(key);
            return every(
                params,
                (paramValue, paramKey) => urlSearchParams.get(paramKey) === paramValue
            );
        });
    }
    return false;
};

export const hasApiActivity = (state: AppStore, ...actionTypes: string[]): boolean =>
    some(actionTypes.map((x) => !!state.apiActivity[x]));

export const hasCompletedRequest = (state: AppStore, requestName: string) =>
    has(state.apiActivity, requestName) && !state.apiActivity[requestName];

export const hasCompletedRequests = (state: AppStore, ...requestNames: string[]) =>
    every(requestNames.map((x) => hasCompletedRequest(state, x)));
