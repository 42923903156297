import { IAction } from 'actions/commonAction';
import { PAGE_USER_TERM_DETAILS_ACTION } from 'actions/userTeamBenefitTermDetail/pageUserTermDetails';
import {
    IPaginatedResultsDtoOfUserTermDetailManageMemberDto,
    IUserTermDetailManageMemberPaginatedResults,
} from 'api/generated/models';
import { CLEAR_USER_TERM_DETAILS_ACTION } from '../actions/clear';

const initialState: IUserTermDetailManageMemberPaginatedResults = ({} as unknown) as IUserTermDetailManageMemberPaginatedResults;

export const pagedUserTermDetails = (
    state = initialState,
    action: IAction<IUserTermDetailManageMemberPaginatedResults>
): IUserTermDetailManageMemberPaginatedResults => {
    switch (action.type) {
        case PAGE_USER_TERM_DETAILS_ACTION.success:
            return action.data as IPaginatedResultsDtoOfUserTermDetailManageMemberDto;
        case CLEAR_USER_TERM_DETAILS_ACTION: {
            return initialState;
        }
        default:
            return state;
    }
};
