import { ExpenseInputTypes } from 'api/generated/enums';
import { ITeamBenefitTermDetail, ITeamBenefitTermDetailDto } from 'api/generated/models';

export const getExpenseInput = <T>(
    teamBenefitTermDetail: ITeamBenefitTermDetail | ITeamBenefitTermDetailDto | undefined,
    expenseTypeId: number,
    expenseInputType: ExpenseInputTypes,
    defaultValue = undefined
): T =>
    ((teamBenefitTermDetail?.expenseInputs?.find(
        (x) => x.expenseType?.id === expenseTypeId && x.expenseInputTypeId === expenseInputType
    )?.value ?? defaultValue) as unknown) as T;
