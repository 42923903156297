import { CLEAR_LOGIN } from 'actions/clear';
import { INVALIDATE_TOKEN_ACTION } from 'actions/token/invalidateToken';
import { TOKEN_ACTION } from 'actions/token/tokenActionUtility';
import { SIGNUP_ACTION } from 'actions/user/signUp';

type ILoginState = {
    accessToken?: string;
    isAuthenticated: boolean;
    refreshToken?: string;
    teamId?: string;
    type?: string;
    up: string[];
    ur: string[];
    userId?: string;
};

const initialState: ILoginState = {
    accessToken: undefined,
    isAuthenticated: false,
    refreshToken: undefined,
    teamId: undefined,
    up: [],
    ur: [],
    userId: undefined,
};

export const login = (state = initialState, action: ILoginState): ILoginState => {
    switch (action.type) {
        case TOKEN_ACTION.success:
        case SIGNUP_ACTION.success:
            return {
                ...state,
                accessToken: action.accessToken,
                isAuthenticated: !!action.accessToken && !!action.refreshToken,
                refreshToken: action.refreshToken,
                teamId: action.teamId,
                up: action.up,
                ur: action.ur,
                userId: action.userId,
            };
        case CLEAR_LOGIN:
        case INVALIDATE_TOKEN_ACTION.success:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
