import { SortOrder, TeamStateIds } from 'api/generated/enums';
import { ITeamProfile, Ordering } from 'api/generated/models';
import { teamStateIds } from 'constants/teamStateIds';
import { Filters, SortingRule } from 'react-table';
import { hasValue } from 'utilities/index';

const teamStateMap: Record<string, TeamStateIds> = {
    Analysis: teamStateIds.Analysis,
    Customer: teamStateIds.Customer,
    Handoff: teamStateIds.Handoff,
    Inactive: teamStateIds.Inactive,
    Lead: teamStateIds.Lead,
    MemberLaunch: teamStateIds.MemberLaunch,
    OffBoarding: teamStateIds.Offboarding,
    Proposal: teamStateIds.Proposal,
    Prospect: teamStateIds.Prospect,
    ReadyforMemberLaunch: teamStateIds.ReadyForMemberLaunch,
    Renewing: teamStateIds.Renewing,
    RHLaunch: teamStateIds.RhLaunch,
    TeamAdminLaunch: teamStateIds.TeamAdminLaunch,
};

export const transformFiltersToEnumArray = (filters: Filters<ITeamProfile> | undefined) => {
    const teamStateFilters = [] as TeamStateIds[];

    if (!hasValue(filters)) {
        return { teamStateFilters };
    }

    const teamStateFilterObjectValue = filters.find((filter) => filter.id === 'teamStatus')?.value;
    const includeArchived = hasValue(
        teamStateFilterObjectValue.find((x: string) => x === 'Archived')
    );

    teamStateFilterObjectValue.map((x: string) => {
        if (hasValue(x) && x !== 'Archived') {
            teamStateFilters.push(teamStateMap[x.replace(/ /g, '')] as TeamStateIds);
        }
    });

    return {
        includeArchived,
        teamStateFilters,
    };
};

export const transformSortByToOrderingArray = (sortBy: SortingRule<ITeamProfile>[]) => {
    const orderingArray = [] as Ordering[];
    sortBy.forEach((sort: SortingRule<ITeamProfile>) => {
        orderingArray.push({
            property: sort.id,
            sortOrder: sort.desc === false ? SortOrder.Ascending : SortOrder.Descending,
        } as Ordering);
    });

    return orderingArray;
};
