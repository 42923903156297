import TeamManagementContext from 'contexts/TeamManagementContext';
import useGhosts from 'hooks/useGhosts';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { AppStore } from 'reducers/appReducer';
import AnonymousRoutes from 'routers/AnonymousRoutes';
import AuthorizedRoutes from 'routers/AuthorizedRoutes';

const RouterContents = () => {
    useGhosts();
    const { isAuthenticated, PageWrapper } = useSelector((state: AppStore) => ({
        isAuthenticated: state.appRouterReducer.isAuthenticated,
        PageWrapper: state.appRouterReducer.PageWrapper,
    }));
    const [isTeamManagementPage, setIsTeamManagementPage] = useState<boolean | undefined>();
    return (
        <Switch>
            <TeamManagementContext.Provider
                value={{ isTeamManagementPage, setIsTeamManagementPage }}
            >
                <PageWrapper>
                    {isAuthenticated ? <AuthorizedRoutes /> : <AnonymousRoutes />}
                </PageWrapper>
            </TeamManagementContext.Provider>
        </Switch>
    );
};

export default hot(module)(RouterContents);
