import { ShoppingConfigurationIds } from 'api/generated/enums';
import { startCase } from 'lodash';
import { enumToNameValueArray } from 'utilities';

export const shoppingConfigurationIdNames = {
    [ShoppingConfigurationIds.Marketplace]: 'Marketplace',
    [ShoppingConfigurationIds.MediShare]: 'Medi-Share',
    [ShoppingConfigurationIds.OffExchange]: 'Off-Exchange',
};

export const WAGE_PLUS_SHOPPING_CONFIGURATION_ITEMS = enumToNameValueArray(
    ShoppingConfigurationIds,
    {
        formatName: startCase,
        nameKey: 'text',
        nameMap: {
            MediShare: shoppingConfigurationIdNames[ShoppingConfigurationIds.MediShare],
            OffExchange: shoppingConfigurationIdNames[ShoppingConfigurationIds.OffExchange],
        },
    }
);

export const ICHRA_SHOPPING_CONFIGURATION_ITEMS = WAGE_PLUS_SHOPPING_CONFIGURATION_ITEMS.filter(
    (x) => x.value !== ShoppingConfigurationIds.MediShare
);
