export const COLONIAL_LIFE = 'Colonial Life';
export const NATIONAL_GENERAL = 'National General';
export const SAFEGUARD_HEALTH = 'SafeGuard Health';
export const HEALTH_INSURANCE_INNOVATIONS = 'Health Insurance Innovations';
export const UNITED_HEALTHCARE = 'United Healthcare';
export const APEX = 'Apex';
export const HEALTHVALUES = 'HealthValues';
export const DELTADENTAL = 'Delta Dental';
export const MANNA = 'Manna';
export const CHRISTIAN_HEALTHCARE_MINISTRIES = 'Christian Healthcare Ministries';
export const ASI = 'ASI';

export const ANCILLARY_CARRIERS = [
    { name: COLONIAL_LIFE },
    { name: NATIONAL_GENERAL },
    { name: SAFEGUARD_HEALTH },
    { name: HEALTH_INSURANCE_INNOVATIONS },
    { name: UNITED_HEALTHCARE },
    { name: APEX },
    { name: HEALTHVALUES },
    { name: DELTADENTAL },
    { name: MANNA },
    { name: CHRISTIAN_HEALTHCARE_MINISTRIES },
    { name: ASI },
];
