import { IAction } from 'actions/commonAction';
import { COMPARE_VERIFIED_INFO } from 'actions/user/compareVerifiedInfo';
import { IVerifiedInfoComparison } from 'api/generated/models';

const initialState: IVerifiedInfoComparison = ({} as unknown) as IVerifiedInfoComparison;

export const verifiedInfoComparison = (
    state = initialState,
    action: IAction<IVerifiedInfoComparison>
): IVerifiedInfoComparison => {
    let newState = state;
    if (action.type === COMPARE_VERIFIED_INFO.success) {
        newState = action.data;
    }
    return newState;
};
