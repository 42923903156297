/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
import { HealthCareTypeIds } from 'api/generated/enums';

export const HEALTHCARE_TYPES = {
    Medical: { name: 'Medical', value: HealthCareTypeIds.Medical },
    Dental: { name: 'Dental', value: HealthCareTypeIds.Dental },
    Vision: { name: 'Vision', value: HealthCareTypeIds.Vision },
    HealthCareSharing: { name: 'Health Care Sharing', value: HealthCareTypeIds.HealthCareSharing },
    Accident: { name: 'Accident', value: HealthCareTypeIds.Accident },
    Cancer: { name: 'Cancer', value: HealthCareTypeIds.Cancer },
    CriticalIllness: { name: 'Critical Illness', value: HealthCareTypeIds.CriticalIllness },
    Disability: { name: 'Disability', value: HealthCareTypeIds.Disability },
    Foundation: { name: 'Foundation', value: HealthCareTypeIds.Foundation },
    GAP: { name: 'GAP', value: HealthCareTypeIds.GAP },
    HospitalIndemnity: { name: 'Hospital Indemnity', value: HealthCareTypeIds.HospitalIndemnity },
    LifeMembership: { name: 'Life Membership', value: HealthCareTypeIds.LifeMembership },
    MEC: { name: 'MEC', value: HealthCareTypeIds.MEC },
    MedicalBridge: { name: 'Medical Bridge', value: HealthCareTypeIds.MedicalBridge },
    PlanEnhancer: { name: 'Plan Enhancer', value: HealthCareTypeIds.PlanEnhancer },
    ShareFee: { name: 'Share Fee', value: HealthCareTypeIds.ShareFee },
    TermLife: { name: 'Term Life', value: HealthCareTypeIds.TermLife },
    TrioMed: { name: 'TrioMed', value: HealthCareTypeIds.TrioMed },
    WholeLife: { name: 'Whole Life', value: HealthCareTypeIds.WholeLife },
};

export const HEALTHCARE_TYPE_NAMES = Object.keys(HEALTHCARE_TYPES).map(
    (x) => HEALTHCARE_TYPES[x as keyof typeof HEALTHCARE_TYPES].name
);
