// Ghosts taken from this codepen: https://codepen.io/ste-vg/pen/gwyGpm
// Toggle button taken from this codepen: https://codepen.io/alvarotrigo/pen/abVPyaJ
// Modified to use updated dependencies and fix some eslint issues

import { RH_TEAM_ID } from 'constants/teams';
import gsap from 'gsap';
import { SlowMo } from 'gsap/EasePack';
import useTeamProps from 'hooks/useTeamProps';
import React, { useState } from 'react';
import Snap from 'snapsvg';

function randomIntFromInterval(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

type Point = {
    x: number;
    y: number;
};

type PathPoint = {
    points: number[];
    type: string;
};

class Ghost {
    id: string;

    private readonly group: Snap.Paper;
    private readonly startPoint: Point;
    private readonly endPoint: Point;
    private readonly startThickness: number;
    private readonly endThickness: number;
    private readonly guidePosition: number;
    private readonly frequency: number;
    private readonly amplitude: number;
    private readonly height: number;
    private readonly endHeight: number;
    private readonly y: number;

    private readonly body: Snap.Element;
    private readonly eyeLeft: Snap.Element;
    private readonly eyeRight: Snap.Element;
    private readonly mouth: Snap.Element;
    private guide: Point[] = [];

    constructor(svg: Snap.Paper, start: Point, end: Point) {
        this.id = String(Math.round(Math.random() * 999999999999999)); // NOSONAR
        this.group = svg.group();
        this.startPoint = start;
        this.endPoint = end;
        this.startThickness = 0;
        this.endThickness = 150 + Math.round(Math.random() * 50); // NOSONAR
        this.guidePosition = Math.random() * 1000; // NOSONAR
        this.frequency = 0.01 + Math.random() * 0.01; // NOSONAR
        this.amplitude = 20 + Math.random() * 40; // NOSONAR
        this.height = 0;
        this.endHeight = 150 + Math.round(Math.random() * 100); // NOSONAR
        this.y = 0;

        const faceAttr = {
            fill: '#111111',
            opacity: 0.9,
            stroke: 'none',
        };

        this.body = this.group.path([]).attr({
            fill: '#eeeeee',
            opacity: 0.8,
            stroke: 'none',
        });

        this.eyeLeft = this.group.path([]).attr(faceAttr);
        this.eyeRight = this.group.path([]).attr(faceAttr);
        this.mouth = this.group.path([]).attr(faceAttr);

        this.updateGuide();
    }

    remove() {
        this.group.remove();
    }

    updateGuide() {
        this.guide = [];
        const height: number = this.startPoint.y - this.endPoint.y;
        const widthChange: number = this.startPoint.x - this.endPoint.x;
        let y = this.startPoint.y;
        y--;
        while (y >= this.endPoint.y) {
            const x = this.startPoint.x + (widthChange - (widthChange / height) * y);
            const wave = Math.sin(y * this.frequency + this.guidePosition);
            this.guide.push({ y, x: x + ((wave * this.amplitude) / 2 + this.amplitude / 2) }); // NOSONAR
            y--;
        }
    }

    start(onComplete: gsap.Callback) {
        gsap.to(this, {
            onComplete,
            duration: 2,
            ease: SlowMo.ease.config(0.3, 0.3, false), // NOSONAR
            height: this.endHeight,
            onCompleteParams: [this],
            y: this.guide.length,
        });
    }

    getPointAlongGuide(y: number, offsetXPercentage: number): Point {
        if (this.guide.length) {
            if (y >= this.guide.length) {
                y = this.guide.length - 1;
            }
            if (y < 0) {
                y = 0;
            }

            const thicknessDifference = this.endThickness - this.startThickness;
            const percentageAlongGuide = (y / this.guide.length) * 100; // NOSONAR
            const thickness =
                this.startThickness + (thicknessDifference / 100) * percentageAlongGuide; // NOSONAR
            const xOffset = (thickness / 2 / 100) * offsetXPercentage; // NOSONAR
            return { x: Number(this.guide[y]?.x) + xOffset, y: Number(this.guide[y]?.y) };
        }
        return { x: 0, y: 0 };
    }

    draw() {
        if (this.height > 0) {
            const y = Math.round(this.y);
            const height = Math.round(this.height);
            const heightChunks = height / 6; // NOSONAR

            const body = [
                { points: [10, y], type: 'M' }, // NOSONAR
                { points: [75, y, 80, y - heightChunks * 2], type: 'Q' }, // NOSONAR
                {
                    points: [
                        85, // NOSONAR
                        y - heightChunks * 3, // NOSONAR
                        90, // NOSONAR
                        y - heightChunks * 4, // NOSONAR
                        95, // NOSONAR
                        y - heightChunks * 5, // NOSONAR
                        100, // NOSONAR
                        y - heightChunks * 6, // NOSONAR
                        75, // NOSONAR
                        y - heightChunks * 5, // NOSONAR
                        50, // NOSONAR
                        y - heightChunks * 6, // NOSONAR
                        25, // NOSONAR
                        y - heightChunks * 5, // NOSONAR
                        0, // NOSONAR
                        y - heightChunks * 6, // NOSONAR
                        -25, // NOSONAR
                        y - heightChunks * 5, // NOSONAR
                        -50, // NOSONAR
                        y - heightChunks * 6, // NOSONAR
                        -75, // NOSONAR
                        y - heightChunks * 5, // NOSONAR
                        -100, // NOSONAR
                        y - heightChunks * 6, // NOSONAR
                        -95, // NOSONAR
                        y - heightChunks * 5, // NOSONAR
                        -90, // NOSONAR
                        y - heightChunks * 4, // NOSONAR
                        -85, // NOSONAR
                        y - heightChunks * 3, // NOSONAR
                        -80, // NOSONAR
                        y - heightChunks * 2, // NOSONAR
                    ],
                    type: 'L',
                },
                { points: [-75, y, 10, y], type: 'Q' }, // NOSONAR
            ];

            this.body.attr({ d: this.drawPath(body) });

            const leftEye = [
                { points: [-40, y - heightChunks * 2], type: 'M' }, // NOSONAR
                { points: [-50, y - heightChunks * 2, -50, y - heightChunks * 2.5], type: 'Q' }, // NOSONAR
                { points: [-50, y - heightChunks * 3, -40, y - heightChunks * 3], type: 'Q' }, // NOSONAR
                { points: [-30, y - heightChunks * 3, -30, y - heightChunks * 2.5], type: 'Q' }, // NOSONAR
                { points: [-30, y - heightChunks * 2, -40, y - heightChunks * 2], type: 'Q' }, // NOSONAR
            ];

            this.eyeLeft.attr({ d: this.drawPath(leftEye) });

            const rightEye = [
                { points: [40, y - heightChunks * 2], type: 'M' }, // NOSONAR
                { points: [50, y - heightChunks * 2, 50, y - heightChunks * 2.5], type: 'Q' }, // NOSONAR
                { points: [50, y - heightChunks * 3, 40, y - heightChunks * 3], type: 'Q' }, // NOSONAR
                { points: [30, y - heightChunks * 3, 30, y - heightChunks * 2.5], type: 'Q' }, // NOSONAR
                { points: [30, y - heightChunks * 2, 40, y - heightChunks * 2], type: 'Q' }, // NOSONAR
            ];

            this.eyeRight.attr({ d: this.drawPath(rightEye) });

            const mouth = [
                { points: [0, y - heightChunks * 3], type: 'M' }, // NOSONAR
                { points: [20, y - heightChunks * 3, 20, y - heightChunks * 3.5], type: 'Q' }, // NOSONAR
                { points: [20, y - heightChunks * 4.5, 0, y - heightChunks * 4.5], type: 'Q' }, // NOSONAR
                { points: [-20, y - heightChunks * 4.5, -20, y - heightChunks * 3.5], type: 'Q' }, // NOSONAR
                { points: [-20, y - heightChunks * 3, 0, y - heightChunks * 3], type: 'Q' }, // NOSONAR
            ];

            this.mouth.attr({ d: this.drawPath(mouth) });
        }
    }

    private drawPath(pathPoints: PathPoint[]) {
        const points = [];

        // prettier-ignore
        for (const pathPoint of pathPoints) {
            const subPoints = [];
            for (let j = 0; j < pathPoint.points.length / 2; j++) { // NOSONAR
                const p = pathPoint.points.slice(j * 2, j * 2 + 2); // NOSONAR
                const point = this.getPointAlongGuide(Math.round(Number(p[1])), Number(p[0]));
                subPoints.push(point.x);
                subPoints.push(point.y);
            }
            points.push(pathPoint.type + subPoints.join(' '));
        }

        return points.join(' ') + 'Z';
    }
}

class StageManager {
    svg: Snap.Paper;
    size: { height: number; width: number };
    ghosts: Map<string, Ghost>;

    constructor(svg: Snap.Paper) {
        this.svg = svg;
        this.ghosts = new Map();
        this.size = { height: 0, width: 0 };
    }

    init() {
        window.addEventListener('resize', () => this.onResize(), true);
        this.onResize();
        this.tick();
    }

    onResize() {
        this.size.width = window.innerWidth;
        this.size.height = window.innerHeight;

        this.svg.attr({ height: this.size.height, width: this.size.width });
    }

    addGhost(x: number, y: number) {
        const range = this.size.width / 8; // NOSONAR
        const start: Point = { x, y };
        const end: Point = {
            x: x + randomIntFromInterval(range * -1, range),
            y: -300,
        };

        const ghost = new Ghost(this.svg, start, end);
        ghost.start((g: Ghost) => this.removeGhost(g));
        this.ghosts.set(ghost.id, ghost);
    }

    removeGhost(ghost: Ghost) {
        this.ghosts.delete(ghost.id);
        ghost.remove();
    }

    tick() {
        for (const ghost of this.ghosts.values()) {
            ghost.draw();
        }
        requestAnimationFrame(() => this.tick());
    }
}

const SPOOKY_MODE_KEY = 'spookyMode';
const initialToggledOn = (localStorage.getItem(SPOOKY_MODE_KEY) ?? 'true') === 'true';

const today = new Date();
const isHalloween = today.getMonth() === 9 && today.getDate() === 31; // NOSONAR

const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
svgElement.setAttribute('id', 'ghosts');

const toggleInput = document.createElement('input');
toggleInput.setAttribute('id', 'ghosts-input');
toggleInput.setAttribute('type', 'checkbox');
if (initialToggledOn) {
    toggleInput.checked = true;
}

const toggleLabel = document.createElement('label');
toggleLabel.setAttribute('id', 'ghosts-label');
toggleLabel.setAttribute('for', 'ghosts-input');

const styleSheet = document.createElement('style');
styleSheet.textContent = `
#ghosts {
    height: 100%;
    pointer-events: none;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 999999999;
}

#ghosts-input[type=checkbox]{
	height: 0;
	width: 0;
	visibility: hidden;
}

#ghosts-label {
	cursor: pointer;
	width: 100px;
	height: 50px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: absolute;
    right: 145px;
    bottom: 0px;
    z-index: 999999999;
}

#ghosts-label:after {
	content: '👻';
	position: absolute;
	top: 3px;
	left: 3px;
	width: 44px;
	height: 44px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
	text-align: center;
	vertical-align: center;
	font-size: 2rem;
}

#ghosts-input:checked + #ghosts-label {
	background: rgb(92, 194, 167);
}

#ghosts-input:checked + #ghosts-label:after {
	left: calc(100% - 3px);
	transform: translateX(-100%);
}

#ghosts-label:active:after {
	width: 60px;
}
`;

const useGhosts = () => {
    const { teamId } = useTeamProps();
    const [isManuallyEnabled, setIsManuallyEnabled] = useState(false);

    React.useEffect(() => {
        Object.defineProperty(window, 'spooky', {
            set: (v) => setIsManuallyEnabled(!!v),
        });
    }, []);

    React.useEffect(() => {
        if ((isHalloween || isManuallyEnabled) && teamId === RH_TEAM_ID) {
            document.body.appendChild(svgElement);
            document.body.appendChild(toggleInput);
            document.body.appendChild(toggleLabel);
            document.head.appendChild(styleSheet);
            const stageManager = new StageManager(Snap(svgElement));
            stageManager.init();

            const onToggleClick = () => {
                localStorage.setItem(SPOOKY_MODE_KEY, toggleInput.checked.toString());
            };

            const onClick = (ev: MouseEvent) => {
                if (toggleInput.checked) {
                    stageManager.addGhost(ev.clientX, ev.clientY);
                }
            };

            window.addEventListener('click', onClick);
            toggleInput.addEventListener('change', onToggleClick);

            return () => {
                window.removeEventListener('click', onClick);
                toggleInput.removeEventListener('change', onToggleClick);
                document.body.removeChild(svgElement);
                document.body.removeChild(toggleInput);
                document.body.removeChild(toggleLabel);
                document.head.removeChild(styleSheet);
            };
        }
        return () => ({});
    }, [teamId, isManuallyEnabled]);
};
export default useGhosts;
