import { FileResponse } from 'api/generated/models';
import { AxiosResponse } from 'axios';
import Toast from 'components/Toast';

export const downloadFileFromApi = async (
    downloadFileApi: () => Promise<AxiosResponse<FileResponse>>,
    toastErrorMessage?: string
) => {
    try {
        const {
            data: { data: blob, fileName },
        } = await downloadFileApi();
        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fileName ?? 'file';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        Toast.error(toastErrorMessage ?? 'Download failed');
    }
};
