import { IAction } from 'actions/commonAction';
import { GET_LATEST_YEAR_FOR_RATES_ACTION } from 'actions/marketplacePlan/getLatestYearForRates';
import { GET_STATE_BASED_EXCHANGE_STATES_SHOULD_FORCE_ADVISEMENT_ACTION } from 'actions/marketplacePlan/getStateBasedExchangeStatesShouldForceAdvisement';

type IMarketplaceConfig = {
    latestYearForRates?: number;
    shouldForceAdvisementForSbe?: boolean;
};

const initialState: IMarketplaceConfig = {
    latestYearForRates: 0,
    shouldForceAdvisementForSbe: true,
};

export const marketplaceConfig = (
    state = initialState,
    action: IAction<boolean | number>
): IMarketplaceConfig => {
    if (action.type === GET_LATEST_YEAR_FOR_RATES_ACTION.success) {
        return { ...state, latestYearForRates: action.data as number };
    } else if (
        action.type === GET_STATE_BASED_EXCHANGE_STATES_SHOULD_FORCE_ADVISEMENT_ACTION.success
    ) {
        return { ...state, shouldForceAdvisementForSbe: action.data as boolean };
    }
    return state;
};
