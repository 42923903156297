/* eslint-disable sort-keys-shorthand/sort-keys-shorthand */
import { OtherCoverageEligibilities } from 'api/generated/enums';
import { flagsToValuesArray } from 'utilities';

export const PARENT_OTHER_COVERAGE_AGE_THRESHOLD = 26 as const;
export const doesMeetParentOtherCoverageAgeThreshold = (age: number | undefined) =>
    (age ?? NaN) < PARENT_OTHER_COVERAGE_AGE_THRESHOLD;
export const DEPENDENT_OTHER_COVERAGE_AGE_MINIMUM = 16 as const;
export const doesMeetDependentOtherCoverageAgeMinimum = (age: number | undefined) =>
    (age ?? NaN) >= DEPENDENT_OTHER_COVERAGE_AGE_MINIMUM;

export const otherCoverageEligibilitiesNames = [
    'None',
    'Spouse Employer',
    'Parent',
    'Dependent Employer',
];

const otherCoverageEligibilities: {
    [key in keyof typeof OtherCoverageEligibilities]: OtherCoverageEligibilities | string;
} = {
    None: 0,
    SpouseEmployer: 1,
    Parent: 2,
    DependentEmployer: 4,
};
Object.keys(OtherCoverageEligibilities).forEach((key, index) => {
    otherCoverageEligibilities[
        otherCoverageEligibilities[key as keyof typeof otherCoverageEligibilities] as number
    ] = otherCoverageEligibilitiesNames[index] as string;
});
export { otherCoverageEligibilities };

export const getOtherCoverageData = (otherCoverage: OtherCoverageEligibilities | undefined) =>
    flagsToValuesArray(otherCoverage, OtherCoverageEligibilities);
