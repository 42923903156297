import { getTeamProfile } from 'actions/team/getTeamProfile';
import { TOKEN_ACTION } from 'actions/token/tokenActionUtility';
import { getUserProfile } from 'actions/user/getUserProfile';
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';

const tokenSuccessMiddleware: Middleware = ({ dispatch }: MiddlewareAPI) => (next: Dispatch) => (
    action
) => {
    next(action);
    if (action.type === TOKEN_ACTION.success) {
        dispatch((getUserProfile(action.userId, true) as unknown) as AnyAction);
        dispatch((getTeamProfile(action.teamId, true) as unknown) as AnyAction);
    }
};
export default tokenSuccessMiddleware;
