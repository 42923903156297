import { IAction } from 'actions/commonAction';
import { ADD_EXPENSE_TYPE_ACTION } from 'actions/expenseTypes/addExpenseTypes';
import { CLEAR_EXPENSE_TYPES_ACTION } from 'actions/expenseTypes/clearExpenseTypes';
import { GET_EXPENSE_TYPES_ACTION } from 'actions/expenseTypes/getExpenseTypes';
import { IExpenseType } from 'api/generated/models';

const initialState: IExpenseType[] = [];

export const expenseTypes = (
    state = initialState,
    action: IAction<IExpenseType | IExpenseType[]>
): IExpenseType[] => {
    if (action.type === GET_EXPENSE_TYPES_ACTION.success) {
        return action.data as IExpenseType[];
    }
    if (action.type === ADD_EXPENSE_TYPE_ACTION.success) {
        return [...state, action.data as IExpenseType];
    }
    if (action.type === CLEAR_EXPENSE_TYPES_ACTION) {
        return initialState;
    }
    return state;
};
