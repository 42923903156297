import { EnrollmentStatuses, SortOrder, UserInfoStatus, UserStatus } from 'api/generated/enums';
import { IUserProfile } from 'api/generated/models';
import { Filters, SortingRule } from 'react-table';
import { hasValue } from 'utilities/index';

const isStatus = (profile: IUserProfile, status: UserInfoStatus) =>
    profile.user?.userInfoStatus === status;

const isOffBoardingOrInactive = (profile: IUserProfile) =>
    profile.user?.status !== undefined &&
    [UserStatus.OffBoarding, UserStatus.Inactive].includes(profile.user.status);

export const isBasicInfoStatus = (userProfile: IUserProfile) =>
    isStatus(userProfile, UserInfoStatus.Basic);

export const userNeedsSurvey = (userProfile: IUserProfile) =>
    !isVerifiedInfoStatus(userProfile) && !isOffBoardingOrInactive(userProfile);

export const isVerifiedInfoStatus = (userProfile: IUserProfile) =>
    isStatus(userProfile, UserInfoStatus.MemberVerifiedInfo);

const userStatusMap: Record<string, UserStatus> = {
    Active: UserStatus.Active,
    Inactive: UserStatus.Inactive,
    Launching: UserStatus.Launching,
    None: ('' as unknown) as UserStatus,
    OffBoarding: UserStatus.OffBoarding,
    Renewing: UserStatus.Renewing,
    Waived: UserStatus.Waived,
};
const enrollmentStatusMap: Record<string, EnrollmentStatuses> = {
    AdvisementNeeded: EnrollmentStatuses.AdvisementNeeded,
    AdvisementScheduled: EnrollmentStatuses.AdvisementScheduled,
    AwaitingTaskList: EnrollmentStatuses.AwaitingTaskList,
    BenefitsSelection: EnrollmentStatuses.BenefitsSelection,
    Enrolled: EnrollmentStatuses.Enrolled,
    InvitedToLaunch: EnrollmentStatuses.InvitedToLaunch,
    InvitedToRenewal: EnrollmentStatuses.InvitedToRenewal,
    None: ('' as unknown) as EnrollmentStatuses,
    PendingApplication: EnrollmentStatuses.PendingApplication,
    PendingDecision: EnrollmentStatuses.PendingDecision,
    PendingWageUpRequest: EnrollmentStatuses.PendingWageUpRequest,
    ReadyForLaunch: EnrollmentStatuses.ReadyForLaunch,
    ReadyForRenewal: EnrollmentStatuses.ReadyForRenewal,
    Waived: EnrollmentStatuses.Waived,
};
const userInfoStatusMap: Record<string, UserInfoStatus> = {
    Basic: UserInfoStatus.Basic,
    Quote: UserInfoStatus.MemberQuoteInfo,
    Verified: UserInfoStatus.MemberVerifiedInfo,
};

export const transformFiltersToEnumArray = (filters: Filters<IUserProfile> | undefined) => {
    const enrollmentStatusFilters = [] as EnrollmentStatuses[];
    const userInfoStatusFilters = [] as UserInfoStatus[];
    const userStatusFilters = [] as UserStatus[];
    if (!hasValue(filters)) {
        return {
            enrollmentStatusFilters,
            userInfoStatusFilters,
            userStatusFilters,
        };
    }
    const enrollmentStatusFilterObjectValue = filters.find(
        (filter) => filter.id === 'enrollmentStatus'
    )?.value;
    const userInfoStatusFilterObjectValue = filters.find((filter) => filter.id === 'userInfoStatus')
        ?.value;
    const userStatusFilterObjectValue = filters.find((filter) => filter.id === 'memberStatus')
        ?.value;

    userStatusFilterObjectValue.map((x: string) =>
        userStatusFilters.push(userStatusMap[x.removeAll(' ')] as UserStatus)
    );
    enrollmentStatusFilterObjectValue.map((x: string) => {
        enrollmentStatusFilters.push(enrollmentStatusMap[x.removeAll(' ')] as EnrollmentStatuses);
    });
    userInfoStatusFilterObjectValue.map((x: string) =>
        userInfoStatusFilters.push(userInfoStatusMap[x.removeAll(' ')] as UserInfoStatus)
    );
    return {
        enrollmentStatusFilters,
        userInfoStatusFilters,
        userStatusFilters,
    };
};

export const transformSortByToOrderingArray = (sortBy: SortingRule<IUserProfile>[]) =>
    sortBy.map((sort: SortingRule<IUserProfile>) => ({
        property: sort.id,
        sortOrder: sort.desc === false ? SortOrder.Ascending : SortOrder.Descending,
    }));
