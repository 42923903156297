import { IAction } from 'actions/commonAction';
import { GET_VIDEOS_ACTION } from 'actions/getVideos';
import { IWistiaVideo } from 'api/generated/models';

const initialState: IWistiaVideo[] = [];

export const videos = (state = initialState, action: IAction<IWistiaVideo[]>) => {
    if (action.type === GET_VIDEOS_ACTION.success) {
        return [...action.data];
    }
    return state;
};
