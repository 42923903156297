import { CLEAR_ALL_MEDISHARE_PLAN_DETAILS_AND_RATES } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_MEDISHARE_PLANS_FOR_USER_ACTION } from 'actions/medishare/getMediSharePlansAndRatesForUser';
import { IMediSharePlan } from 'api/generated/models';

const initialState: IMediSharePlan[] = [];

export const mediSharePlans = (
    state = initialState,
    action: IAction<IMediSharePlan[]>
): IMediSharePlan[] => {
    switch (action.type) {
        case GET_MEDISHARE_PLANS_FOR_USER_ACTION.success:
            return [...action.data];
        case CLEAR_ALL_MEDISHARE_PLAN_DETAILS_AND_RATES:
            return [];
        default:
            return state;
    }
};
