import moment, { Moment, MomentInput } from 'moment';
import { hasValue } from 'utilities';

export const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY';
export const API_DATE_FORMAT = 'YYYY-MM-DD';

const invalidDate = (date: unknown, format = DISPLAY_DATE_FORMAT) => {
    const d = moment(date as MomentInput | undefined, format, true);
    return !d.isValid() || d.isBefore(moment('01/01/1753', DISPLAY_DATE_FORMAT));
};

const LENGTH_OF_DATE = 10;
/**
 * @deprecated Use `yup` validation instead of using this directly
 * @switch isRequired
 * @case `true (default)`: If date is required will check invalid length with mask or invalid date using display format or whitespace, null or undefined
 * @case `false`: If date is not required will check invalid length with mask or invalid date using display format unless whitespace, null or undefined
 * @param  {string} date
 * @param  {boolean} isRequired=true
 */
export const isInvalidDate = (date: unknown, isRequired = true) => {
    const isInvalid =
        (typeof date === 'string' && date.replace('_', '').length !== LENGTH_OF_DATE) ||
        invalidDate(date);
    const isNotEmpty = hasValue(date) && date !== '//';
    return isRequired ? !hasValue(date) || isInvalid : isNotEmpty && isInvalid;
};
export const isValidDate = (date: unknown, isRequired = true) => !isInvalidDate(date, isRequired);

declare global {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface String {
        getAge: (this: string, targetDate?: string) => number;
        getMonth: (this: string, format?: string, strict?: boolean) => number;
        getYear: (this: string, format?: string, strict?: boolean) => number;
        /**
         * Extension method to convert a string to moment date
         * @param this Date string in api format
         * @param format Date format string (default is API Format = YYYY-MM-DD)
         * @param strict Boolean indicating whether format should be strict (default = false)
         */
        toMomentDate: (this: string, format?: string, strict?: boolean) => moment.Moment;
    }
}

String.prototype.getAge = function(targetDate?: string) {
    const age = moment(targetDate).diff(this, 'years');
    return age > 0 ? age : 1;
};
String.prototype.getYear = function(format, strict) {
    return this.toMomentDate(format, strict).year();
};
String.prototype.getMonth = function(format, strict) {
    return this.toMomentDate(format, strict).month() + 1;
};
String.prototype.toMomentDate = function(format = API_DATE_FORMAT, strict = false) {
    return moment(this, format, strict);
};

export const maxDateString = '9999/1/1';

type IDate = Moment | string | undefined;
export const isTodayAfter = (date: IDate, granularity?: moment.unitOfTime.StartOf) => {
    const today = moment();
    return today.isAfter(typeof date === 'string' ? date.toMomentDate() : date, granularity);
};

export const isTodaySameOrAfter = (date: IDate, granularity?: moment.unitOfTime.StartOf) => {
    const today = moment();
    return today.isSameOrAfter(typeof date === 'string' ? date.toMomentDate() : date, granularity);
};

export const isTodayBefore = (date: Moment | string) => {
    const today = moment();
    return today.isBefore(typeof date === 'string' ? date.toMomentDate() : date);
};

export const isTodaySameOrBefore = (date: Moment | string) => {
    const today = moment();
    return today.isSameOrBefore(typeof date === 'string' ? date.toMomentDate() : date);
};

const INCLUSIVE = '[]';
export const isTodayBetween = (startDate: string, endDate: string) => {
    const today = moment();
    return today.isBetween(startDate.toMomentDate(), endDate.toMomentDate(), undefined, INCLUSIVE);
};

export const stripTimeFromDate = (dateTime: IDate) =>
    moment(dateTime)
        .utc()
        .startOf('day');

export const getEndDateFromYear = (year: number | undefined) => `${year}-12-31T23:59:59`;

/**
 * Converts (display formatted) date string to moment object or undefined if null, undefined, or invalid date
 * @param  {string} dateString
 * @warning ___This expects a string that will be in the display format (mm/dd/yyyy); if you give an api formatted string this function will not work as expected___
 * @uses {@link hasValue}
 * @uses {@link isValidDate}
 */
export const toMomentOrUndefined = (dateString: unknown) =>
    hasValue(dateString) && isValidDate(dateString)
        ? moment(dateString as MomentInput, DISPLAY_DATE_FORMAT)
        : undefined;

export const getDateOrNull = (date: IDate, getDate: (date: Moment | string) => Moment = moment) =>
    hasValue(date) ? getDate(date) : null;
