import { Store } from 'redux';

let store: Store | undefined;

export function getStore<T>(): Store<T> | undefined {
    return store;
}

export function setStore<T>(newStore: Store<T>) {
    store = newStore;
}
