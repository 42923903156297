import { IAction } from 'actions/commonAction';
import { GET_TEAM_MEMBERS_STATS_ACTION } from 'actions/stats/getTeamMembersStats';
import { ITeamMembersStats } from 'api/generated/models';

const initialState: ITeamMembersStats = ({} as unknown) as ITeamMembersStats;

export const stats = (state = initialState, action: IAction<ITeamMembersStats>) => {
    if (action.type === GET_TEAM_MEMBERS_STATS_ACTION.success) {
        return action.data;
    }
    return state;
};
