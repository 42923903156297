import { IAction } from 'actions/commonAction';
import { GET_ADVISEMENT_TEAMS_ACTION } from 'actions/team/getAdvisementTeams';
import { ITeam } from 'api/generated/models';

const initialState: ITeam[] = [];

export const advisementTeams = (state = initialState, action: IAction<ITeam[]>) => {
    switch (action.type) {
        case GET_ADVISEMENT_TEAMS_ACTION.success:
            return [...action.data];
        case GET_ADVISEMENT_TEAMS_ACTION.failed:
            return [];
        default:
            return state;
    }
};
