import { IAction } from 'actions/commonAction';
import { ADD_TEAM_RH_FILES_ACTION } from 'actions/rhFile/addTeamRhFiles';
import { ADD_USER_RH_FILES_ACTION } from 'actions/rhFile/addUserRhFiles';
import { DELETE_TEAM_RH_FILE_ACTION } from 'actions/rhFile/deleteTeamRhFile';
import { DELETE_USER_RH_FILE_ACTION } from 'actions/rhFile/deleteUserRhFile';
import { GET_RH_FILES_FOR_TEAM_ACTION } from 'actions/rhFile/getRhFilesForTeam';
import { GET_RH_FILES_FOR_USER_ACTION } from 'actions/rhFile/getRhFilesForUser';
import { RENAME_TEAM_RH_FILES_ACTION } from 'actions/rhFile/renameTeamRhFile';
import { RENAME_USER_RH_FILES_ACTION } from 'actions/rhFile/renameUserRhFile';
import { TOGGLE_TEAM_RH_FILE_ARCHIVE_ACTION } from 'actions/rhFile/toggleTeamRhFileArchived';
import { TOGGLE_TEAM_RH_FILE_SHARED_ACTION } from 'actions/rhFile/toggleTeamRhFileShared';
import { TOGGLE_USER_RH_FILE_ARCHIVE_ACTION } from 'actions/rhFile/toggleUserRhFileArchived';
import { TOGGLE_USER_RH_FILE_SHARED_ACTION } from 'actions/rhFile/toggleUserRhFileShared';
import { IRhFile } from 'api/generated/models';

const initialState: IRhFile[] = [];

export const rhFiles = (state = initialState, action: IAction<IRhFile | IRhFile[]>): IRhFile[] => {
    switch (action.type) {
        case GET_RH_FILES_FOR_USER_ACTION.success:
        case GET_RH_FILES_FOR_TEAM_ACTION.success:
            return [...(action.data as IRhFile[])];
        case DELETE_TEAM_RH_FILE_ACTION.success:
        case DELETE_USER_RH_FILE_ACTION.success:
            return [...state.filter((x) => x.id !== (action.data as IRhFile).id)];
        case RENAME_TEAM_RH_FILES_ACTION.success:
        case RENAME_USER_RH_FILES_ACTION.success:
        case TOGGLE_TEAM_RH_FILE_ARCHIVE_ACTION.success:
        case TOGGLE_USER_RH_FILE_ARCHIVE_ACTION.success:
        case TOGGLE_TEAM_RH_FILE_SHARED_ACTION.success:
        case TOGGLE_USER_RH_FILE_SHARED_ACTION.success: {
            const data = action.data as IRhFile;
            return [...state.filter((x) => x.id !== data.id), data];
        }
        case ADD_TEAM_RH_FILES_ACTION.success:
        case ADD_USER_RH_FILES_ACTION.success:
            return [...state, ...(action.data as IRhFile[])];
        default:
            return state;
    }
};
