import { IAction } from 'actions/commonAction';
import { GET_COMPLETED_TEAM_TASKS_ACTION } from 'actions/task/getCompletedTeamTasks';
import { SAVE_COMPLETED_TEAM_TASK_ACTION } from 'actions/task/saveCompletedTeamTask';
import { ITaskItem } from 'api/generated/models';

const initialState = {
    completedTasks: [] as ITaskItem[],
};

export const teamTasks = (
    state = initialState,
    action: IAction<ITaskItem | ITaskItem[]>
): typeof initialState => {
    switch (action.type) {
        case SAVE_COMPLETED_TEAM_TASK_ACTION.success: {
            const completedTasks = [...state.completedTasks];
            const data = action.data as ITaskItem;
            if (!completedTasks.some((x) => x.taskItemId === data.taskItemId)) {
                completedTasks.push(data);
            }
            return { completedTasks };
        }
        case GET_COMPLETED_TEAM_TASKS_ACTION.success:
            return { completedTasks: action.data as ITaskItem[] };
        default:
            return state;
    }
};
