import { CLEAR_REIMBURSEMENT_REPORTS_ACTION } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { LIST_REIMBURSEMENT_REPORTS_ACTION } from 'actions/reimbursementReport/listReimbursementReports';
import { TOGGLE_REIMBURSEMENT_REPORT_SHARED_ACTION } from 'actions/reimbursementReport/toggleReimbursementReportShared';
import {
    IBasicReimbursementReportDto,
    IListReimbursementReportsResponseDto,
} from 'api/generated/models';

const initialState: IBasicReimbursementReportDto[] = [];

export const reimbursementReports = (
    state = initialState,
    action: IAction<IBasicReimbursementReportDto | IListReimbursementReportsResponseDto>
): IBasicReimbursementReportDto[] => {
    switch (action.type) {
        case TOGGLE_REIMBURSEMENT_REPORT_SHARED_ACTION.success: {
            const data = action.data as IBasicReimbursementReportDto;
            return state.map((report) =>
                report.globalId === data.globalId ? { ...report, isShared: data.isShared } : report
            );
        }
        case LIST_REIMBURSEMENT_REPORTS_ACTION.success:
            return (action.data as IListReimbursementReportsResponseDto)
                .reimbursementReports as IBasicReimbursementReportDto[];
        case CLEAR_REIMBURSEMENT_REPORTS_ACTION:
            return initialState;
        default:
            return state;
    }
};
