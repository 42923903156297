import { PlanTypeIds } from 'api/generated/enums';
import {
    ACA_MARKETPLACE_PLAN_TYPE_NAME,
    ACA_PLAN_TYPE_IDS,
    OFF_EXCHANGE_PLAN_TYPE_IDS,
    OFF_EXCHANGE_PLAN_TYPE_NAME,
    PLAN_TYPE_ITEMS,
} from 'constants/selectedPlans';

export const shouldIncludePremiumWithCredits = (planType: PlanTypeIds) =>
    planType === PlanTypeIds.Marketplace || planType === PlanTypeIds.CustomStateBasedExchange;

export const isSpouseOrParentPlanType = (planType: PlanTypeIds | undefined) =>
    planType !== undefined &&
    [PlanTypeIds.SpouseEmployer, PlanTypeIds.ParentEmployer].includes(planType);

export const getPlanTypeName = (planType: PlanTypeIds | undefined): string => {
    if (ACA_PLAN_TYPE_IDS.contains(planType)) {
        return ACA_MARKETPLACE_PLAN_TYPE_NAME;
    }
    if (OFF_EXCHANGE_PLAN_TYPE_IDS.contains(planType)) {
        return OFF_EXCHANGE_PLAN_TYPE_NAME;
    }
    return PLAN_TYPE_ITEMS.find((p) => p.value === planType)?.['name']?.toString() ?? '';
};
