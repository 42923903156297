export const SURVEY_VIDEO_URL = 'https://remodelhealth.wistia.com/medias/5oyp9il82i';
export const MEMBER_WELCOME_VIDEO_URL = 'https://remodelhealth.wistia.com/medias/zq3nwmggmx';
export const MEDISHARE_GUIDELINES_URL =
    'https://mychristiancare.org/medi-share/what-is-medishare/how-medi-share-works/medi-share-guidelines/';
export const DEBIT_CARD_AGREEMENT_PDF_URL =
    process.env['API_URL'] + 'api/disclosures/debitCardAgreement';
export const DEPOSIT_ACCOUNT_AGREEMENT_PDF_URL =
    process.env['API_URL'] + 'api/disclosures/depositAccountAgreement';
export const TISA_DISCLOSURE_PDF_URL =
    process.env['API_URL'] + 'api/disclosures/tisaAccountDisclosureAgreement';
