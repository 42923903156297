import { IAction } from 'actions/commonAction';
import { CLEAR_CUSTOM_QUALITATIVE_ANSWERS } from 'actions/user/clearCustomQualitativeAnswers';
import { GET_CUSTOM_QUESTION_ANSWERS_ACTION } from 'actions/user/getCustomQuestionAnswers';
import { ICustomQuestionAnswer } from 'api/generated/models';

const initialState: ICustomQuestionAnswer[] = [];

export const customQuestionAnswers = (
    state = initialState,
    action: IAction<ICustomQuestionAnswer[]>
): ICustomQuestionAnswer[] => {
    if (action.type === GET_CUSTOM_QUESTION_ANSWERS_ACTION.success) {
        return action.data;
    } else if (action.type === CLEAR_CUSTOM_QUALITATIVE_ANSWERS) {
        return initialState;
    }
    return state;
};
