import { IAction } from 'actions/commonAction';
import { CALCULATE_PATHWAY_USER_ACTION } from 'actions/pathwayBlueprint/calculatePathwayUser';
import { CLEAR_PATHWAY_USER } from 'actions/pathwayBlueprint/clearPathwayUser';
import { GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION } from 'actions/pathwayBlueprint/getPathwayBlueprintDataForUser';
import { GET_PATHWAY_USER_ACTION } from 'actions/pathwayBlueprint/getPathwayUser';
import { SET_CUSTOM_PROJECTED_REIMBURSEMENT_ACTION } from 'actions/pathwayBlueprint/setCustomProjectedReimbursement';
import { SET_CUSTOM_PROJECTED_WAGE_UP_ACTION } from 'actions/pathwayBlueprint/setCustomProjectedWageUp';
import {
    IPathwayBlueprint,
    IPathwayUser,
    IPathwayUserDto,
    IUserPathwayBlueprintDto,
    UserPathwayBlueprintDto,
} from 'api/generated/models';

const initialState: IPathwayUser | IPathwayUserDto = ({} as unknown) as IPathwayUserDto;

export const pathwayUser = (
    state = initialState,
    action: IAction<IPathwayUserDto | IUserPathwayBlueprintDto>
): IPathwayUserDto => {
    switch (action.type) {
        case SET_CUSTOM_PROJECTED_REIMBURSEMENT_ACTION.success: {
            const data = ((action.data as IPathwayBlueprint).pathwayUsers?.find(
                (pu) => pu.id === state.id
            ) ?? {}) as IPathwayUser;
            return {
                ...state,
                customProjectedReimbursement: data.customProjectedReimbursement,
                customProjectedReimbursementNote: data.customProjectedReimbursementNote,
                isStale: data.isStale,
                lastWageUpAffectedDate: data.lastWageUpAffectedDate,
            };
        }
        case SET_CUSTOM_PROJECTED_WAGE_UP_ACTION.success: {
            const data = ((action.data as IPathwayBlueprint).pathwayUsers?.find(
                (pu) => pu.id === state.id
            ) ?? {}) as IPathwayUser;
            return {
                ...state,
                customProjectedWageUp: data.customProjectedWageUp,
                customProjectedWageUpNote: data.customProjectedWageUpNote,
                isStale: data.isStale,
                lastWageUpAffectedDate: data.lastWageUpAffectedDate,
            };
        }
        case GET_PATHWAY_USER_ACTION.success:
        case CALCULATE_PATHWAY_USER_ACTION.success:
            return { ...state, ...action.data };
        case GET_PATHWAY_BLUEPRINT_DATA_FOR_USER_ACTION.success: {
            const data = action.data as UserPathwayBlueprintDto;
            if (data?.pathwayUserDto) {
                return data.pathwayUserDto;
            } else {
                return state;
            }
        }
        case CLEAR_PATHWAY_USER:
            return initialState;
    }
    return state;
};
