import { IAction } from 'actions/commonAction';
import { GET_SINGLE_FILER_STANDARD_DEDUCTION_ACTION } from 'actions/taxData/getSingleFilerStandardDeduction';
import { GET_SURVEY_INFO_FOR_USER_ACTION } from 'actions/survey/getSurveyInfoForUser';
import { GET_SURVEY_USER_PROFILE_ACTION } from 'actions/survey/getSurveyUserProfile';
import { ISurveyInfo } from 'api/generated/models';

const initialState = 0;

export const singleFilerStandardDeduction = (
    state = initialState,
    action: IAction<ISurveyInfo | number>
): number => {
    switch (action.type) {
        case GET_SINGLE_FILER_STANDARD_DEDUCTION_ACTION.success:
            return action.data as number;
        case GET_SURVEY_INFO_FOR_USER_ACTION.success:
        case GET_SURVEY_USER_PROFILE_ACTION.success:
            return (action.data as ISurveyInfo).singleFilerStandardDeduction;
        default:
            return state;
    }
};
