import { CLEAR_GET_INFO_TO_SUBMIT_EXPENSE } from 'actions/clear';
import { IAction } from 'actions/commonAction';
import { GET_INFO_TO_SUBMIT_EXPENSE_ACTION } from 'actions/submittedExpenses/getUserInfoToSubmitExpense';
import { IUserInfoToSubmitExpenseDto } from 'api/generated/models';

const initialState: IUserInfoToSubmitExpenseDto = ({} as unknown) as IUserInfoToSubmitExpenseDto;

export const userInfoToSubmitExpense = (
    state = initialState,
    action: IAction<IUserInfoToSubmitExpenseDto>
): IUserInfoToSubmitExpenseDto => {
    if (action.type === GET_INFO_TO_SUBMIT_EXPENSE_ACTION.success) {
        return action.data;
    }
    if (action.type === CLEAR_GET_INFO_TO_SUBMIT_EXPENSE) {
        return initialState;
    }
    return state;
};
