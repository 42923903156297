import { IAction } from 'actions/commonAction';
import { GET_IDEON_PROVIDER_PLANS_ACTION } from 'actions/shopping/getIdeonProviderPlans';
import { IProviderPlanDto } from 'api/generated/models';
import { hasValue } from 'utilities/index';

export type IIdeonProviderPlans = {
    hasError?: boolean;
    providerPlans?: IProviderPlanDto[];
};

const initialState: Record<string, IIdeonProviderPlans> = {};

export const ideonProviderPlans = (
    state = initialState,
    action: IAction<IIdeonProviderPlans> & { npi: string }
): Record<string, IIdeonProviderPlans> => {
    switch (action.type) {
        case GET_IDEON_PROVIDER_PLANS_ACTION.success:
            return {
                ...state,
                [action.npi]: action.data,
            };
        case GET_IDEON_PROVIDER_PLANS_ACTION.clear: {
            if (hasValue(action.npi)) {
                return { ...state, [action.npi]: { hasError: false, providerPlans: [] } };
            }
            return {};
        }
        default:
            return state;
    }
};
